import { ERROR_COULD_NOT_PERFORM_ACTION, LOADING } from 'mk/autogenerated/translations/ArticleEdit.helpers.459ec9018d847fb31b465273f9c977c8'
import { ArticleFormData, ARTICLE_CREATE_EDIT_FORM_NAME } from 'mk2/apps/blogs/components/ArticleCreateEditForm';
import { constructImage, RenderSnippetAs } from 'mk2/apps/wiki/utils';
import { SmartTextareaEditorType } from 'mk2/components/forms/SmartTextareaField';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import {
    PhotoUploadFailureAction,
    PhotoUploadSuccessAction,
    PHOTO_UPLOAD_FAILURE,
    PHOTO_UPLOAD_SUCCESS,
} from 'mk2/containers/PhotosUpload/PhotosUpload.actions';
import { astToHtml, astToMarkdown, defaultFilterAdditionalData } from 'mk2/helpers/article_ast/filters';
import { decodeHtmlEntities, encodeHtmlEntities } from 'mk2/helpers/article_ast/utils';
import { PhotoUploadStatus, SuccessfulPhotoUpload } from 'mk2/helpers/form.reducers';
import { scrollDocumentTo } from 'mk2/helpers/scrolling';
import { showErrorToast } from 'mk2/helpers/toasts';
import { getEntitiesEntity } from 'mk2/reducers';
import { ASTAdditionalDataFromServer, PhotoEntity, PhotoSchema } from 'mk2/schemas';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import { getFormValues } from 'redux-form';
import { select, take } from 'redux-saga/effects';

export const prepareHTMLArticleForFroalaJSX = (element: ReactElement) => {
    const s = ReactDOMServer.renderToString(element);
    return React.createElement('span', { dangerouslySetInnerHTML: { __html: prepareHTMLArticleForFroala(s) } });
};

export const prepareHTMLArticleForFroala = (html: string) => {
    html = html.replace(/<!--.*?-->/g, '');
    html = html.replace(/\sclass=""/g, '');
    html = html.replace(/(\ssrc=")((?:(?!"|image\/png;base64).)*)(image\/png;base64)((?:(?!").)*)"/g, '');
    html = html.replace(/(<img\s)((?:(?!>|data-src).)*)(data-src=)((?:(?!>).)*>)/g, '$1$2src=$4');
    return html;
};

export const prepareMarkdownArticleForFroala = (articleBody: string) => {
    articleBody = encodeHtmlEntities(articleBody);
    articleBody = articleBody.replace(/\n/g, '<br>');
    return articleBody;
};

export const cleanMarkdownArticleFromFroala = (articleBody: string) => {
    articleBody = articleBody.replace(/(\n\r|\r\n|\n|\r)/g, '');
    articleBody = articleBody.replace(/<\/? *br>/g, '\n');
    articleBody = decodeHtmlEntities(articleBody);
    return articleBody;
};

export const revertReplacedTagsOfArticleScripts = (scripts: string) => {
    return scripts.replace(/(<\/?)(replaced_script_tag_of_a_safe_mk_script>)/ig, '$1script>');
};

export const blogArticleAstToMarkdown = (ast, photos, astAdditionalData: ASTAdditionalDataFromServer) => {
    const additionalData = {
        ...defaultFilterAdditionalData,
        photos,
        linkedObjects: astAdditionalData ? astAdditionalData.linkedObjects : {},
        renderForEditor: true,
        renderSnippetObjectsAs: RenderSnippetAs.MARKDOWN,
    };
    return astToMarkdown(ast, additionalData);
};

export const blogArticleAstToHTML = (ast, photos, countTopicContributors, astAdditionalData: ASTAdditionalDataFromServer) => {
    const additionalData = {
        ...defaultFilterAdditionalData,
        photos,
        countTopicContributors,
        linkedObjects: astAdditionalData ? astAdditionalData.linkedObjects : {},
        renderForEditor: true,
        hasResponsiveImages: true,

        // render just plain #hashtag string, do not use hashize(), it generates <Link> and renderToStaticMarkup has no
        // valid context, react-router setup, etc.
        hashizeFn: (bodyChunk) => bodyChunk,
    };
    return renderToStaticMarkup(<React.Fragment>{astToHtml(ast, additionalData)}</React.Fragment>);
};

const isDescendant = (parent, child) => {
     let node = child.parentNode;
     while (node != null) {
         if (node === parent) {
             return true;
         }
         node = node.parentNode;
     }
     return false;
};

export function* insertUploadingImagesToTheEditor(editor, editorType, pendingUploads, photosBefore, photoPreviewErrors) {
    // Finding a reference node (<p>, <div>, <table> ... a top level html node) based on cursor's position, after this
    // node will be the placeholder/image will be appended
    const selection = editor.selection.get();
    const caretOnTheBeginningOfTheParagraph = selection.focusOffset === 0;
    let referenceNode;

    if (editorType === SmartTextareaEditorType.RICHTEXT) {
        referenceNode = selection.anchorNode;
        do {
            // in edge cases, set referenceNode to be the first child of the document
            if (
                !referenceNode ||                           // if the referenceNode in undefined
                !isDescendant(editor.el, referenceNode) ||  // if the referenceNode is outside the editor
                referenceNode === editor.el                 // if the referenceNode is the editor itself
            ) {
                referenceNode = editor.el.childNodes[0]; // select first child of the html in the editor
                if (referenceNode.localName === 'div') {
                    referenceNode = referenceNode.childNodes[0];
                }
                break;
            }

            if (
                referenceNode &&
                referenceNode.parentNode &&
                isDescendant(editor.el, referenceNode)
            ) {
                if (
                    // if parent of the current reference node is the editor itself (or the root wrapper <div>)
                    (referenceNode.parentNode === editor.el) ||
                    (referenceNode.parentNode.localName === 'div' && referenceNode.parentNode.parentNode === editor.el)
                ) {
                    // then consider the last referenceNode the final
                    break;
                } else {
                    // then set referenceNode the parent of current referenceNode
                    referenceNode = referenceNode.parentNode;
                }
            } else {
                referenceNode = null;
            }

        } while (true);

        // if the referenceNode is not an empty paragraph, and the focusOffset is not 0 (the caret is not on th beginning
        // of the paragraph), move the referenceNode the to it's nextSibling (if not exists, create empty line)
        if (referenceNode.innerHTML !== '' && referenceNode.innerHTML !== '<br>' && !caretOnTheBeginningOfTheParagraph) {
            if (referenceNode.nextSibling) {
                referenceNode = referenceNode.nextSibling;
            } else {
                const emptyLastLine = document.createElement('p');
                referenceNode.parentNode.append(emptyLastLine);
                referenceNode = referenceNode.nextSibling;
            }
        }
    } else {
        referenceNode = selection.focusNode.nodeName === '#text'
            ? selection.focusNode
            : selection.focusNode.childNodes[selection.focusOffset];
        if (!isDescendant(editor.el, referenceNode)) {
            referenceNode = editor.el.childNodes[0]; // select first child of the html in the editor
        }
    }

    // Create and fill placeholder node
    let placeholderNode = null;
    let placeholderNodeFilling = null;
    if (editorType === SmartTextareaEditorType.RICHTEXT) {
        placeholderNode = document.createElement('p');
        // placeholderNodeFilling = document.createTextNode(LOADING);
        placeholderNodeFilling = document.createElement('div');
        ReactDOM.render((
            <LoadingPlaceholder width={'100%'} height={336}>
                <div style={{fontSize: 14, margin: '20px 12px'}}>{LOADING}</div>
            </LoadingPlaceholder>
        ), placeholderNodeFilling);
        placeholderNode.append(placeholderNodeFilling);
        referenceNode.parentNode.insertBefore(placeholderNode, referenceNode);
    } else {
        const brNode1 = document.createElement('br');
        referenceNode.parentNode.insertBefore(brNode1, referenceNode);
        placeholderNode = referenceNode.parentNode;
        placeholderNodeFilling = document.createTextNode(LOADING);
        placeholderNode.insertBefore(placeholderNodeFilling, referenceNode);
        const brNode2 = document.createElement('br');
        referenceNode.parentNode.insertBefore(brNode2, referenceNode);
        const brNode3 = document.createElement('br');
        referenceNode.parentNode.insertBefore(brNode3, referenceNode);
    }

    // Waiting for finishing photo upload(s)
    let counterPhotoUploadSuccess = 0;
    let counterPhotoUploadFailure = 0;
    while (pendingUploads.length > 0) {
        yield take((stopAction: (PhotoUploadSuccessAction | PhotoUploadFailureAction)) => {
            if (
                (
                    stopAction.type === PHOTO_UPLOAD_SUCCESS ||
                    stopAction.type === PHOTO_UPLOAD_FAILURE
                ) &&
                stopAction.formName === ARTICLE_CREATE_EDIT_FORM_NAME
            ) {
                pendingUploads.pop();
                if (stopAction.type === 'PHOTO_UPLOAD_SUCCESS') {
                    counterPhotoUploadSuccess++;
                } else {
                    counterPhotoUploadFailure++;
                }
                return true;
            }
            return false;
        });
    }

    const currentValuesAfter: ArticleFormData = yield select(getFormValues(ARTICLE_CREATE_EDIT_FORM_NAME));
    const photosAfter = currentValuesAfter.photos;
    const newPhotos = photosAfter.filter((x: any) => (
        !photosBefore.includes(x) && (x.status === undefined || x.status === PhotoUploadStatus.SUCCESS)
    ));

    // If no successfully uploaded photos, no new photos photoPreview, report fail
    if (
        (counterPhotoUploadSuccess < 1 || newPhotos.length < 1) &&
        (counterPhotoUploadFailure > 0 || photoPreviewErrors.length > 0)
    ) {
        placeholderNode.removeChild(placeholderNodeFilling);
        return false;
    } else {
        // If exists some success photos, but also some incorrect photos, notify error
        if (counterPhotoUploadFailure > 0 || photoPreviewErrors.length > 0) {
            yield showErrorToast(ERROR_COULD_NOT_PERFORM_ACTION);
            scrollDocumentTo('bottom');
        }
    }

    // Append successfully uploaded new photos
    for (let i = 0; i < newPhotos.length; i++) {
        const photoEntity: PhotoEntity = yield select(getEntitiesEntity, PhotoSchema.key, (newPhotos[i] as SuccessfulPhotoUpload).photoId);
        if (currentValuesAfter.editorType === SmartTextareaEditorType.RICHTEXT) {
            const imgElement = (
                <span>
                    {constructImage(
                        [photoEntity],
                        photoEntity.code,
                        'center',
                        'full',
                        's1600x1600',
                        '',
                        false,
                        true,
                    )}
                </span>
            );

            ReactDOM.render(prepareHTMLArticleForFroalaJSX(imgElement), placeholderNode);
            if (i < (newPhotos.length - 1)) {
                placeholderNode = document.createElement('p');
                placeholderNodeFilling = document.createTextNode(LOADING);
                placeholderNode.append(placeholderNodeFilling);
                referenceNode.parentNode.insertBefore(placeholderNode, referenceNode);
            }
        } else {
            placeholderNode.removeChild(placeholderNodeFilling.nextSibling);
            placeholderNode.removeChild(placeholderNodeFilling);
            const imgElement = document.createTextNode(`<<pic cd="${photoEntity.code}" title="" caption="">>`);
            referenceNode.parentNode.insertBefore(imgElement, referenceNode);
            const brNode1 = document.createElement('br');
            referenceNode.parentNode.insertBefore(brNode1, referenceNode);
            if (i < (newPhotos.length - 1)) {
                const brNode2 = document.createElement('br');
                referenceNode.parentNode.insertBefore(brNode2, referenceNode);
                placeholderNodeFilling = document.createTextNode(LOADING);
                placeholderNode.insertBefore(placeholderNodeFilling, referenceNode);
                const brNode3 = document.createElement('br');
                referenceNode.parentNode.insertBefore(brNode3, referenceNode);
            }
        }
    }

    // sync Froala Editor's actual content with the form's "body" field (i.e. trigger Froala's contentChanged event)
    // https://github.com/froala/ember-froala-editor/commit/1bd3ca56c6301dc73b815e664154cd0a310616c0
    editor.undo.saveStep();

    return true;
}
