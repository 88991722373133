import articleSagas from 'mk2/apps/blogs/containers/Article/Article.sagas';
import sharedSagas from 'mk2/apps/blogs/containers/BlogsShared/BlogsShared.sagas';
import comments from 'mk2/apps/blogs/containers/Comments/Comments.sagas';
import { all, fork } from 'redux-saga/effects';

export default function* root() {
    yield all([
        fork(sharedSagas),
        fork(comments),
        fork(articleSagas),
    ]);
}
