import { Entity, PhotoEntity, ProVendorEntity, TopicEntity, UserEntity, WikiArticleEntity } from 'mk2/schemas';
import { schema } from 'normalizr';

export const ItemToTagSchema = new schema.Entity('itemsToTag', {
});

export interface SeoForumItemEntity extends Entity {
    title: string;
    firstMessageTime: string;
    firstMessageAuthorUsername: string;
    lastMessageTime: string;
    lastMessageAuthorUsername: string;
    postSlug: string;
    content: string;
    topicSlug: string;
    topicId: number;
    topicModeratorsUsernames: string[];
    isLocked: boolean;
    isBlockedGoogle: boolean;
    isNoAds: boolean;
    seoDone: boolean;
    firstMessagePhotos?: PhotoEntity[];
}

export const SeoForumItemSchema = new schema.Entity('seoForumItems');

export interface ThreadAIEntity extends Entity {
    title: string;
    threadId: number;
    topic: TopicEntity;
}

export const ThreadAISchema = new schema.Entity('aiThreads');

export interface AIPromptEntity extends Entity {
    name: string;
    contentPrompt: string;
    systemPrompt: string;
}

export const AIPromptSchema = new schema.Entity('ai.aiprompt');

export interface WikiArticleEntitledVendorEntity extends Entity {
    vendor: ProVendorEntity;
    vendorUser: UserEntity;
    article: WikiArticleEntity;
}

export const WikiArticleEntitledVendorSchema = new schema.Entity('wiki.wikiarticleentitledvendor', {
});
