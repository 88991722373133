import React, { useEffect, useRef } from 'react';

const InstagramEmbed: React.FC<{ instagramUrl: string }> = ({ instagramUrl }) => {
    const blockquoteRef = useRef<HTMLQuoteElement>(null);

    const onAddedToDOM = () => {
        if ((window as any)?.instgrm) {
            (window as any)?.instgrm?.Embeds?.process();
        } else if (!document.getElementById('instagramEmbedJs')) {
            const instagramEmbedJs: HTMLScriptElement = document.createElement('script');
            instagramEmbedJs.id = 'instagramEmbedJs';
            instagramEmbedJs.type = 'text/javascript';
            instagramEmbedJs.async = true;
            instagramEmbedJs.src = 'https://www.instagram.com/embed.js';
            const firstScript = document.getElementsByTagName('script')[0];
            firstScript?.parentNode?.insertBefore(instagramEmbedJs, firstScript);

            // Wait for the script to load and then process the embeds
            instagramEmbedJs.onload = () => {
                (window as any)?.instgrm?.Embeds?.process();
            };
        }
    };

    useEffect(() => {
        if (blockquoteRef.current) {
            const observer = new MutationObserver(() => {
                if (blockquoteRef.current?.isConnected) {
                    onAddedToDOM();
                    observer.disconnect(); // Stop observing once the function is called
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });
        }
    }, []);

    return (
        <blockquote
            ref={blockquoteRef}
            className="instagram-media"
            data-instgrm-captioned
            data-instgrm-permalink={instagramUrl}
            data-instgrm-version="14"
        />
    );
};


export default InstagramEmbed;
