import { SEO_DESCRIPTION_DEFAULT_FALLBACK, SEO_TITLE_DEFAULT_FALLBACK } from 'mk/autogenerated/translations/Page.a4946595406ffe57be48e4250ce84033'
import { FACEBOOK_APP_ID } from 'mk/settings';
import { BackBtn } from 'mk2/components/BackBtn';
import { ZoneTargeting } from 'mk2/containers/AdSlot/interfaces';
import { AppName } from 'mk2/containers/Application/Application';
import { getUrlOfFirstImageOrFallback } from 'mk2/helpers/structuredData';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { AppState } from 'mk2/reducers';
import { MenuSelection } from 'mk2/schemas';
import { Store } from 'mk2/store/configureStore';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';

export interface BrandingProps {
    zone: string;
    targeting?: ZoneTargeting;
}
export interface AnchorLeaderboardProps {
    zone: string;
    targeting?: ZoneTargeting;
}
export interface HalfpageProps {
    zone: string;
    targeting?: ZoneTargeting;
}

export enum PageLayout {
    // na desktope hlavny stlpec (sirka 624px) a vpravo uzky stlpec (sirka 336px),
    // na mobile hlavny stlpec (sirka 100vw), pod nim uzky sltpec (sirka 100vw)
    MainContentWithRightColumn,
    // na desktope hlavny stlpec (100% sirky okna) a ziadny uzky stlpec vpravo
    // na mobile hlavny stlpec (sirka 100vw), pod nim ziadny uzky sltpec
    MainContentFullWidth,
    // na desktope hlavny stlpec (sirka 980px) a ziadny uzky stlpec vpravo
    // na mobile hlavny stlpec (sirka 100vw), pod nim ziadny uzky sltpec
    MainContent,
    // na desktope hlavny stlpec (sirka 624px) vycentrovany
    // na mobile hlavny stlpec (sirka 100vw)
    MainContentNarrow,
}

export enum RightContentSide {
    LeftSide,
    RightSide,
}

/**
 * TODO: Add function for enter animation
 * TODO: Add function for leave animation
 * TODO: Can we somehow simplyfy:
 *  type Props = TOwnProps & TStateProps & TDispatchProps & RouteComponentProps<TRouteParams> ?
 *
 * WARN: Please, ... please ... consult all changes you want to make in this file
 */
export default abstract class Page<TOwnProps = {}, TStateProps = {}, TDispatchProps = {}, TMergedProps = TOwnProps & TStateProps & TDispatchProps> {

    protected _imported = {};

    /**
     * Return mapStateToProps configuration for react-redux connect decorator
     */
    public abstract mapStateToProps(): MapStateToPropsParam<TStateProps, TOwnProps, AppState>;

    /**
     * Return mapDispatchToProps configuration for react-redux connect decorator
     */
    public abstract mapDispatchToProps(): MapDispatchToPropsObject<TDispatchProps>;

    /**
     * Permission (hociktoru z vrateneho pola), ktoru musi mat requestUser, aby sa mu Page zobrazila
     */
    public getPermissionRequired(props: TMergedProps): string | string[] | boolean {
        return null;
    }

    /**
     * Return SEO page title for <title> and <meta property="og:title">
     */
    public abstract getMetaTitle(props: TMergedProps): string;

    /**
     * Return SEO page description for <meta property="description"> and <meta property="og:description">
     */
    public getMetaDescription(props: TMergedProps): string {
        return null;
    }

    /**
     * Return SEO rich results: FAQPage (FAQ rich snippet)
     */
    public getMetaFaqRichSnippet(props: TMergedProps): string {
        return null;
    }

    /**
     * Return JSON object for Schema.org structured data
     */
    public getStructuredData(props: TMergedProps): object[] {
        return null;
    }

    /**
     * Return JSON object for Facebook Open Graph data
     */
    public getOpenGraphData(props: TMergedProps, baseUrl: string, currentUrl: string): {[key: string]: string | string[]} {
        // https://developers.facebook.com/docs/sharing/opengraph/object-properties#standard
        return {
            'og:type': 'website',
            'og:title': this.getMetaTitle(props) || SEO_TITLE_DEFAULT_FALLBACK,
            'og:description': this.getMetaDescription(props) || SEO_DESCRIPTION_DEFAULT_FALLBACK,
            'og:image': getUrlOfFirstImageOrFallback(undefined),
            'og:url': baseUrl + currentUrl,
            'fb:app_id': FACEBOOK_APP_ID,
        };
    }

    /**
     * Return SEO page description for <meta property="description"> and <meta property="og:description">
     */
    public getMetaRobots(props: TMergedProps): string {
        return 'index,follow';
    }

    /**
     * Return SEO canonical url for <link rel="canonical" href="..." />
     */
    public getCanonicalUrl(baseUrl: string, props: TMergedProps): string {
        return null;
    }

    /**
     * Return layout of the page (how main content and right column are positioned)
     */
    public getPageLayout(app: AppName, props: TMergedProps): PageLayout {
        return (app === 'bo')
            ? PageLayout.MainContentFullWidth
            : (app === 'mail' || app === 'bazaar' || app === 'adminclub' || app === 'dash')
                ? PageLayout.MainContent
                : PageLayout.MainContentWithRightColumn;
    }

    /**
     * Return side (right or left) where right column of the page layout is to be placed
     */
    public getRightContentSide(app: AppName, props: TMergedProps): RightContentSide {
        return (app === 'blogs')
            ? RightContentSide.LeftSide
            : RightContentSide.RightSide;
    }

    /**
     * Return props for branding ad
     */
    public getBrandingProps(props: TMergedProps): (BrandingProps | null) {
        return null;
    }

    /**
     * Return props for anchor leaderboard ad
     */
    public getAnchorLeaderboardProps(props: TMergedProps): (AnchorLeaderboardProps | null) {
        return null;
    }

    /**
     * Return props for halfpage ad
     */
    public getHalfpageProps(props: TMergedProps): (HalfpageProps | null) {
        return null;
    }

    /**
     * Render main content of the page
     */
    public abstract render(props: TMergedProps): (JSX.Element | null | false);

    /**
     * Render (optional) right column of the page
     */
    public renderRight(props: TMergedProps): (JSX.Element | null) {
        return null;
    }

    /**
     * Get title for the header
     */
    public getHeaderTitle(props: TMergedProps): (string | null) {
        return null;
    }

    /**
     * Get tabs for the header
     */
    public getHeaderTabs(props: TMergedProps): (JSX.Element[] | null) {
        return null;
    }

    /**
     * Get header left part - by default it is controller by hasHeaderBack() button
     */
    public getHeaderLeft(props: TMergedProps): JSX.Element {
        if (this.hasHeaderBackButton(props)) {
            // FIXME CeMi:
            // na viacerych Pages sa neda rozhodnut co je spravna getDefaultBackPath() kym nie je stranka
            // v stave LoadingState.success
            //
            // Preto tu treba dorobit, ze getDefaultBackPath() sa vola len v LoadingState.success.
            // To bude stacit, lebo na serveri pri inom LoadingState backbutton netreba vykreslit
            // a na klientovi sa da pocas init a loading state da zavolat js back
            const backUrl = this.getDefaultBackPath(props);

            return (
                <BackBtn
                    icon="mkicon-arrow-back@3x.png"
                    link={backUrl}
                    asAnchor={backUrl.startsWith('http')/* back link ide mimo pwa */}
                    data-cy="page-back-button"
                />
            );
        }

        return null;
    }

    /**
     * Get header right part
     */
    public getHeaderRight(props: TMergedProps): React.ReactElement {
        return null;
    }

    /**
     * Get content footer
     */
    public getFooter(props: TMergedProps): React.ReactNode {
        return null;
    }

    /**
     * Should header be sticky?
     *
     * NOTE: Header can be sticky only on mobile
     */
    public isHeaderSticky(props: TMergedProps): boolean {
        return true;
    }

    /**
     * Return true if you want to show MK logo in the center of mobile Header (if theres no title text)
     */
    public hasMobileLogo(props: TMergedProps): boolean {
        return !this.getHeaderRight(props);
    }

    /**
     * Should header have a back button?
     */
    public abstract hasHeaderBackButton(props: TMergedProps): boolean;

    /**
     * Default back path when no history exists
     */
    public getDefaultBackPath(props: TMergedProps): (string | null) {
        if (this.hasHeaderBackButton(props)) {
            throw new Error('Page: You must specify default back path for back button if hasHeaderBackButton() returns true');
        }

        return null;
    }

    /**
     * Return false if you do not want to display blue MK header
     */
    public hasPageHeaderPart1(props: TMergedProps): boolean {
        return true;
    }

    public getHeaderMenuSelection(app: AppName, props: TMergedProps): MenuSelection {
        // Do not always create new object because SiteMenu is then always re-rendered
        return {
            activeSection: app,
            activeItem: undefined,
        };
    }

    /**
     * Return false if you do not want to display blue MK footer
     */
    public hasPageFooter(app: AppName, props: TMergedProps): boolean {
        return app !== 'bo' && app !== 'adminclub';
    }

    /**
     * Use this to register reducers or sagas
     *
     * @param {MKStore<AppState>} store Can be undefined -> used for preloading
     * @returns {Promise<any> | null}
     */
    public abstract registerInStore(store: Store<AppState>): void;
}
