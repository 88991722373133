/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_cooking_baking_ware : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["COOKWARE", "Hrnce, p\u00e1nve, woky"],
        ["BAKEWARE", "Plechy a formy na pe\u010den\u00ed"],
        ["OVENDISH", "Zap\u00e9kac\u00ed misky a pek\u00e1\u010de"],
        ["CHOCOLATE_MOLDS", "Formy "],
        ["MIXING_BOWLS", "Misky na m\u00edch\u00e1n\u00ed"],
        ["KNIVES", "No\u017ee a \u0161krabky"],
        ["CHOPPING_BOARDS", "Desky na kr\u00e1jen\u00ed"],
        ["MEASURING_TOOLS", "Odm\u011brky, v\u00e1hy a teplom\u011bry"],
        ["SPATULAS_PASTRY_BRUSHES", "St\u011brky, karty, ma\u0161lova\u010dky"],
        ["PASTRY_CUTTERS", "Vykrajov\u00e1tka, vykrajova\u010de"],
        ["CAKE_SCULPTING_TOOLS", "Cukr\u00e1\u0159sk\u00e9 pom\u016fcky"],
        ["PASTRY_BAGS", "Cukr\u00e1\u0159sk\u00e9 \u0161pi\u010dky a pytl\u00edky"],
        ["TEXTURES_ROLLING_PINS_SCHEETS", "V\u00e1le\u010dky a podlo\u017eky na pe\u010den\u00ed"],
        ["MODELLING_PASTES", "Cukr\u00e1\u0159sk\u00e9 hmoty"],
        ["CAKE_DECORATIONS", "Ozdoby a dekorace"],
        ["EDIBLE_COLORS_GLUE", "Barvy a lepidla"],
        ["PAPER_BAKING_PRODUCTS", "Ko\u0161\u00ed\u010dky a pap\u00edrov\u00e9 zbo\u017e\u00ed"],
        ["GRATER", "Struhadlo, ml\u00fdnek"],
        ["LADLE_WOODEN_SPOON", "Nab\u011bra\u010dka, va\u0159e\u010dka"],
        ["OTHER_COOKING_BAKING_WARE", "Jin\u00e9"],
    ],
};
