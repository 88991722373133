import cx from 'classnames';
import memoize from 'lodash-es/memoize';
import { Emoji } from 'mk2/components/Emoji';
import { supportsEmoji } from 'mk2/helpers/detects';
import { toShort } from 'mk2/helpers/emojis';
import React from 'react';
import styles from './Emojis.mscss';

const EMOJIS = [
    '🙂', '😉', '☹️', '😒', '😠', '😎', '😖', '😲', '😢', '😀', '😝', '😔', '😅', '😕', '🙄',
    '😨', '🤐', '😵', '😂', '😍', '😘', '😇', '🤒', '😴', '😈', '👽', '❤️', '👏', '👍',
    '👎', '🙏', '👶', '👦', '👧', '💃', '🤰', '🐶', '🐱', '🐭', '🐻', '🐷', '🐙', '🐧',
    '🐝', '🐘', '🙈', '🙉', '🙊', '🌳', '🌴', '🍀', '🍂', '🌹', '🌷', '☃️', '🌜', '🌞',
    '🌧', '🔥', '❄️', '🍏', '🍉', '🍒', '🥕', '🥑', '🍕', '🍣', '🥗', '🎂', '🍼', '☕',
    '🍹', '🚗', '🚕', '🚌', '🚓', '🚑', '🚒', '🏍', '🚲', '🚂', '⛵', '✈️', '🚀', '⛰',
    '🏕', '🏖', '🏙', '🏡', '📱', '💻', '📷', '💵', '💎', '🔨', '💣', '💉', '💊', '🔑',
    '🎈', '🎁', '🎉', '📨', '📦', '📓', '🛒', '🇸🇰', '🇨🇿',
];

interface OwnProps {
    className?: string;
    force?: boolean; // Force emojione
    onSelect?(shortcode: string);
}

export class Emojis extends React.Component<OwnProps> {

    public static defaultProps = {
        force: false,
    };

    private onSelect = memoize((shortcode) => () => {
        this.props.onSelect(shortcode);
    });

    public render() {
        const { className, force } = this.props;

        return (
            <div className={cx(styles.Emojis, className)}>
                {EMOJIS.map((emoji) => (
                    <Emoji
                        key={emoji}
                        className={styles.Emojis__item}
                        emoji={emoji}
                        size={24}
                        onClick={this.onSelect(supportsEmoji() ? emoji : toShort(emoji))}
                        force={force}
                    />
                ))}
            </div>
        );
    }
}
