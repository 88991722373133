import cx from 'classnames';
import { Img } from 'mk2/components/Img';
import { supportsEmoji } from 'mk2/helpers/detects';
import React from 'react';
import styles from './Emoji.mscss';

const EMOJIS_PATH = 'https://cdn.jsdelivr.net/emojione/assets/png/';
const CACHE_BUST_PARAM = '?v=2.2.7';

interface OwnProps {
    className?: string;
    emoji: string;
    size: number;
    force?: boolean; // Force emojione
    onClick?();
}

export class Emoji extends React.Component<OwnProps> {

    public static defaultProps = {
        force: false,
    };

    public render() {
        const { className, emoji, size, force, onClick } = this.props;
        const unicode = convertToCode(emoji);

        if (supportsEmoji() && !force) {
            const sizePx = `${size}px`;
            const style = {
                width: sizePx,
                height: sizePx,
                fontSize: sizePx,
                lineHeight: sizePx,
            };

            return (
                <div className={cx(styles.Emoji, className)} onClick={onClick} style={style}>
                    {emoji}
                </div>
            );
        } else {
            return (
                <Img
                    className={className}
                    alt={emoji}
                    src={EMOJIS_PATH + unicode + '.png' + CACHE_BUST_PARAM}
                    width={size}
                    height={size}
                    onClick={onClick}
                />
            );
        }
    }

}

function convertToCode(str) {
    let highsurrogate = 0;
    let pad;

    let outputs = [];
    for (let  i = 0; i < str.length; i++) {
        const cc = str.charCodeAt(i);
        if (highsurrogate !== 0) { // this is a supp char, and cc contains the low surrogate
            if (0xDC00 <= cc && cc <= 0xDFFF) {
                const suppCP = 0x10000 + ((highsurrogate - 0xD800) << 10) + (cc - 0xDC00); // tslint:disable-line no-bitwise
                pad = suppCP.toString(16);
                outputs.push(pad);
                highsurrogate = 0;
                continue;
            }
        }
        if (0xD800 <= cc && cc <= 0xDBFF) { // start of supplementary character
            highsurrogate = cc;
        } else {
            pad = cc.toString(16);
            outputs.push(pad);
        }
    }

    if (outputs[1] === 'fe0f') {
        outputs = [ outputs[0] ]; // This code is magical for OS X and emojione
    }

    return outputs.join('-');
}
