import { ACTIVE_SERVER_ID } from 'mk/settings';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';

const ABOUT_PAGES: { [pageSlug: string]: string } = {
    'rules-common': {
        201: 'vseobecne-podmienky-pouzivania',
        202: 'vseobecne-podminky-pouzivani',
    }[ACTIVE_SERVER_ID],
    'rules-codex': {
        201: 'kodex-spravania-na-stranke',
        202: 'kodex-chovani-na-strance',
    }[ACTIVE_SERVER_ID],
    'rules-market': {
        201: 'pravidla-bazara',
        202: 'pravidla-bazaru',
    }[ACTIVE_SERVER_ID],
    'dsa': {
        201: 'hlasenie-nezakonneho-obsahu',
        202: 'hlaseni-nezakonneho-obsahu',
    }[ACTIVE_SERVER_ID],
    'contact': {
        201: 'kontakt-a-fakturacne-udaje',
        202: 'kontakt-a-fakturacni-udaje',
    }[ACTIVE_SERVER_ID],
    'data-processor': {
        201: 'podmienky-spracuvania-osobnych-udajov-pre-predajcov',
        202: 'podminky-zpracovani-osobnich-udaju-pro-prodejce',
    }[ACTIVE_SERVER_ID],
    'privacy-policy': {
        201: 'zasady-ochrany-osobnych-udajov',
        202: 'zasady-ochrany-osobnich-udaju',
    }[ACTIVE_SERVER_ID],
    'cookie-policy': {
        201: 'cookie-pravidla',
        202: 'cookie-pravidla',
    }[ACTIVE_SERVER_ID],
    'google-partners-list': {
        201: 'partneri-reklamnej-siete-google',
        202: 'partneri-reklamni-site-google',
    }[ACTIVE_SERVER_ID],
    'privacy-and-cookie-policy': {
        201: 'ochrana-osobnych-udajov-a-cookie-pravidla',
        202: 'ochrana-osobnich-udaju-a-cookie-pravidla',
    }[ACTIVE_SERVER_ID],
};

const ABOUT_PAGES_CATEGORY = {
    201: 'ine',
    202: 'jine',
}[ACTIVE_SERVER_ID];

export const aboutRulesCommonUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['rules-common']);
export const aboutRulesCodexUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['rules-codex']);
export const aboutRulesMarketUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['rules-market']);
export const aboutCookiePolicyUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['cookie-policy']);
export const aboutContactUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES.contact);
export const aboutDSAUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES.dsa);
export const aboutPrivacyPolicyUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['privacy-policy']);
export const aboutDataProcessorUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['data-processor']);
export const aboutGooglePartnersListUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['google-partners-list']);
export const aboutPrivacyAndCookiePolicyUrl = getWikiArticleUrl(ABOUT_PAGES_CATEGORY, ABOUT_PAGES['privacy-and-cookie-policy']);
