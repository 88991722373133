import { faImage, faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'froala-editor/css/froala_editor.pkgd.min.css'; // tslint:disable-line
import 'froala-editor/css/froala_style.min.css'; // tslint:disable-line
import 'froala-editor/css/themes/gray.min.css'; // tslint:disable-line
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { FormControl } from 'mk2/components/forms/FormControl';
import { cacheLast } from 'mk2/helpers/cache';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './SmartTextareaField.mscss';

export enum SmartTextareaEditorType {
    RICHTEXT,
    MARKDOWN,
}
interface SmartTextareaInputPublicProps {
    transparent?: boolean;
}

type SmartTextareaInputProps = SmartTextareaInputPublicProps  & {
    input: WrappedFieldInputProps;
    editorTypeOnInit: SmartTextareaEditorType;
    allowEditorTypeChange: boolean;

    onEditorTypeChange(editorType: SmartTextareaEditorType);
    onInsertImage(editor: any);
};

interface SmartTextareaInputState {
    froalaLoaded: boolean;
    froalaInitialized: boolean;
    editorTypeOnFroalaInit: SmartTextareaEditorType;
}

class SmartTextareaInput extends React.Component<SmartTextareaInputProps, SmartTextareaInputState> {
    private FroalaEditor: any;
    private FroalaEditorComponent: any;
    private FroalaEditorController: any;

    private cachedHandleModelChange = cacheLast(true);
    private cachedGetEditorConfig = cacheLast(true);

    constructor(props, context) {
        super(props, context);

        this.state = {
            froalaLoaded: false,
            froalaInitialized: false,
            editorTypeOnFroalaInit: props.editorTypeOnInit,
        };

        this.handleManualController = this.handleManualController.bind(this);
    }

    public render() {
        const { input } = this.props;
        const { froalaLoaded, editorTypeOnFroalaInit } = this.state;
        return (
            <div className={styles.SmartTextareaInput}>
                {this.state.froalaLoaded && (
                    <this.FroalaEditorComponent
                        tag="textarea"
                        config={this.cachedGetEditorConfig(
                            () => this.getEditorConfig(editorTypeOnFroalaInit),
                            froalaLoaded, editorTypeOnFroalaInit,
                        )}
                        model={this.state.froalaInitialized ? input.value : ''}
                        onModelChange={this.cachedHandleModelChange(() => this.handleModelChange(input), input)}
                        onManualControllerReady={this.handleManualController}
                    />
                )}
            </div>
        );
    }

    public componentDidMount() {
        this.loadFroala();
    }

    private getEditorConfig(editorType: SmartTextareaEditorType) {
        const { allowEditorTypeChange } = this.props;

        const isRichtext = editorType === SmartTextareaEditorType.RICHTEXT;

        const toolbarButtons = isRichtext
                ? allowEditorTypeChange
                    ? ['bold', 'italic', 'paragraphFormat', 'insertMkImageCommand', 'insertVideo', 'insertLink',
                       'formatOL', 'formatUL', 'undo', 'redo', 'markdownEditorCommand']
                    : ['bold', 'italic', 'paragraphFormat', 'insertMkImageCommand', 'insertVideo', 'insertLink',
                       'formatOL', 'formatUL', 'undo', 'redo']
                : allowEditorTypeChange
                    ? ['undo', 'redo', 'insertMkImageCommand', 'richtextEditorCommand']
                    : ['undo', 'redo', 'insertMkImageCommand'];

        const froalaConfig: any = {
            theme: 'gray',
            attribution: false,
            charCounterCount: false,
            toolbarInline: false,
            heightMin: 350,
            placeholderText: '',
            quickInsertTags: [],
            toolbarButtons,
            imageEditButtons: ['imageAlign', 'imageRemove', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', 'imageAlt'],
            enter: isRichtext ? this.FroalaEditor.ENTER_P : this.FroalaEditor.ENTER_BR,
            imageResize: false,
            imageResizeWithPercent: false,
            imageDefaultWidth: isRichtext ? 0 : undefined,
            imageAlt: isRichtext ? true : undefined,
            paragraphFormat: isRichtext ? {
                n: 'Normal',
                h2: 'Heading',
                h3: 'Subheading',
                h4: 'Subsubheading',
            } : undefined,
            language: {201: 'sk', 202: 'cs'}[ACTIVE_SERVER_ID],
            imagePaste: false,
            imageUpload: false,
            htmlAllowedTags: isRichtext
                ? ['br', 'code', 'hr', 'h2', 'h3', 'h4', 'em', 'ol', 'li', 'ul', 'a', 'iframe', 'img', 'p', 'pre',
                    'strong', 'i', 'b', 'table', 'tbody', 'thead', 'tr', 'td', 'th', 'div', 'span']
                : ['br'],
            pastePlain: !isRichtext,
            pasteDeniedAttrs: ['style'],
            pasteDeniedTags: isRichtext
                ? ['img', 'n']
                : [
                    'img', 'b', 'strong', 'i', 'em', 'span', 'a', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5',
                    'h6', 'table', 'tbody', 'thead', 'tr', 'th', 'td', 'iframe',
                ],
            entities: [],
            linkAlwaysNoFollow: isRichtext ? true : undefined,
            key: 'lSA3D-17C1C1F1C2A1G1rXYb1VPUGRHYZNRJd1JVOOb1HAc1zG2B1A2B2D6B1C2C4F1H4==',

            events : {
                initialized: (e) => {
                    this.sleep(10).then(() => {
                        this.setState({froalaInitialized: true});
                    });
                },
                focus: (e) => {
                    // const editor = this.controller.getEditor();
                    // console.log(editor.selection.get());
                },
            },
        };
        if (!isRichtext) {
            froalaConfig.pluginsEnabled = [];
            froalaConfig.shortcutsEnabled = ['undo', 'redo'];
        }
        return froalaConfig;
    }

    private handleManualController(controller) {
        this.FroalaEditorController = controller;
        this.FroalaEditorController.initialize();
    }

    private handleModelChange(input) {
        return (model) => {
            input.onChange(model);
            input.onBlur(model);
        };
    }

    private async loadFroala() {
        const r = await Promise.all([
            import('froala-editor'                              /* webpackChunkName: "components.external.Froala" */), // tslint:disable-line
            import('react-froala-wysiwyg'                       /* webpackChunkName: "components.external.Froala" */), // tslint:disable-line
            import('froala-editor/js/plugins.pkgd.min.js'       /* webpackChunkName: "components.external.Froala" */), // tslint:disable-line
            import('froala-editor/js/languages/sk.js'           /* webpackChunkName: "components.external.Froala" */), // tslint:disable-line
            import('froala-editor/js/languages/cs.js'           /* webpackChunkName: "components.external.Froala" */), // tslint:disable-line
        ]);
        this.FroalaEditor = r[0].default;
        this.FroalaEditorComponent = r[1].default;

        // https://www.froala.com/wysiwyg-editor/docs/concepts/custom/icon
        this.FroalaEditor.DefineIconTemplate('svg_image', ReactDOMServer.renderToString(<FontAwesomeIcon icon={faImage} style={{height: '16px'}} />));
        this.FroalaEditor.DefineIconTemplate('svg_star', ReactDOMServer.renderToString(<FontAwesomeIcon icon={faStar} style={{height: '16px'}} />));

        this.FroalaEditor.DefineIcon('insertMkImageIcon', { template: 'svg_image' });
        this.FroalaEditor.RegisterCommand('insertMkImageCommand', {
            title: 'Insert image ...',
            icon: 'insertMkImageIcon',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: () => {
                // finding reference html node where the selection cursor is
                const editor = this.FroalaEditorController.getEditor();
                this.props.onInsertImage(editor);
            },
        });

        this.FroalaEditor.DefineIcon('richtextEditorIcon', { template: 'svg_star' });
        this.FroalaEditor.RegisterCommand('richtextEditorCommand', {
            title: 'Switch to richtext editor',
            icon: 'richtextEditorIcon',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: () => {
                if (this.props.onEditorTypeChange) {
                    this.FroalaEditorController.destroy();
                    this.setState({editorTypeOnFroalaInit: SmartTextareaEditorType.RICHTEXT});
                    this.FroalaEditorController.initialize();
                    this.sleep(10).then(() => {
                        this.props.onEditorTypeChange(SmartTextareaEditorType.RICHTEXT);
                    });
                }
            },
        });

        this.FroalaEditor.DefineIcon('markdownEditorIcon', { template: 'svg_star' });
        this.FroalaEditor.RegisterCommand('markdownEditorCommand', {
            title: 'Switch to markdown editor',
            icon: 'markdownEditorIcon',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: () => {
                if (this.props.onEditorTypeChange) {
                    this.FroalaEditorController.destroy();
                    this.setState({editorTypeOnFroalaInit: SmartTextareaEditorType.MARKDOWN});
                    this.FroalaEditorController.initialize();
                    this.sleep(10).then(() => {
                        this.props.onEditorTypeChange(SmartTextareaEditorType.MARKDOWN);
                    });
                }
            },
        });

        this.setState({froalaLoaded: true});
    }

    private sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };
}

interface SmartTextareaControlPublicProps {
    label?: string;
    marginLeft?: boolean;
    minimizedLabel?: boolean;
    transparent?: boolean;
    editorTypeOnInit: SmartTextareaEditorType;
    allowEditorTypeChange: boolean;

    onEditorTypeChange(editorType: SmartTextareaEditorType);
    onInsertImage(editor: any);
}

type SmartTextareaControlProps = SmartTextareaInputPublicProps & SmartTextareaControlPublicProps & WrappedFieldProps;

const SmartTextareaControl: React.StatelessComponent<SmartTextareaControlProps> = ({
     input, meta, label, marginLeft, transparent,
     minimizedLabel, ...smartTextareaInputProps
}) => (
    <FormControl
        marginLeft={marginLeft}
        transparent={transparent}
        input={input}
        label={label}
        meta={meta}
        minimizedLabel={minimizedLabel}
    >
        <SmartTextareaInput
            transparent={transparent}
            input={input}
            {...smartTextareaInputProps}
        />
    </FormControl>
);

type SmartTextareaFieldProps = SmartTextareaInputPublicProps & SmartTextareaControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
    editorTypeOnInit: SmartTextareaEditorType;
};

export class SmartTextareaField extends React.Component<SmartTextareaFieldProps> {

    public render() {
        return (
            <Field
                {...this.props}
                component={SmartTextareaControl}
            />
        );
    }
}
