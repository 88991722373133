import Loadable from 'mk2/helpers/Loadable';
import { Portal } from 'react-overlays';

export const PortalLoadable = Loadable<Portal['props'], any>({
    loader: () => import('react-overlays' /* webpackChunkName: "react-overlays" */).then((module) => {
        return module.Portal;
    }),
    modules: ['react-overlays'],
    webpack: () => [ require.resolveWeak('react-overlays') ],
});
