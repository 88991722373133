import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import React from 'react';

type LnkProcessorFunction = (srcLnk: string, visibleText: string) => React.ReactChild;

const WP_LINK_RE = /\[\[\s*(.*?)\s*\|\s*(.*?)\s*]]/;

// process mediawiki format links in the form of [[easygo-virage|EasyGo Virage]]
// using provided function which takes srcLnk and visibleText parameters and
// is expected to return React elements, e.g.:
// <Link to="/kocikopedia/easygo-virage/">EasyGo Virage</Link>
// this filter is used for our INTERNAL links
export const wpLinkize = (text: TextLike, lnkProcessor: LnkProcessorFunction) => reactFilter(text, (s: string): React.ReactChild[] => {
    const ret = [];
    const matcher = new RegExp(WP_LINK_RE, 'g');
    let from = 0;
    while (true) {
        const match = matcher.exec(s);
        if (match) {
            const matchLen = match[0].length;
            const lnk = match[1];
            const visibleText = match[2];

            const to = matcher.lastIndex - matchLen;

            // copy text before this link
            ret.push(s.substring(from, to));

            const finalLnk = lnkProcessor(lnk, visibleText);
            ret.push(finalLnk);

            from = matcher.lastIndex;
        } else {
            // copy text after last link
            ret.push(s.substring(from));
            break;
        }
    }
    return ret;
});
