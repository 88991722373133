import {
    AdSlotRefreshAction,
    AdSlotRenderEndedAction,
    AD_SLOT_REFRESH,
    AD_SLOT_RENDER_ENDED,
} from 'mk2/containers/AdSlot/AdSlot.actions';

export interface AdSlotOffsiteState {
    show: boolean;
}

export const initialAdSlotOffsiteState: AdSlotOffsiteState = {
    show: false,
};

type AdSlotActions = AdSlotRenderEndedAction | AdSlotRefreshAction;

export const adSlotOffsiteReducer = (state: AdSlotOffsiteState = initialAdSlotOffsiteState, action: AdSlotActions) => {
    switch (action.type) {
        case AD_SLOT_REFRESH:
            if (!action.slotId.startsWith('mk-branding')) {
                return state;
            }

            return {
                ...state,
                // this slot has been scheduled for refresh - hide slot
                show: false,
            };
        case AD_SLOT_RENDER_ENDED:
            // We handle only branding
            if (!action.slotId.startsWith('mk-branding')) {
                return state;
            }

            return {
                ...state,
                // Display offsite when branding is empty or branding width is <= 980
                show: action.event.isEmpty || action.event.size[0] <= 980,
            };
        default:
            return state;
    }
};
