const data = [
    ['🙂', ':slight_smile:'],
    ['😉', ':wink:'],
    ['☹️', ':frowning2:'],
    ['😒', ':unamused:'],
    ['😠', ':angry:'],
    ['😎', ':sunglasses:'],
    ['😖', ':confounded:'],
    ['😲', ':astonished:'],
    ['😢', ':cry:'],
    ['😀', ':grinning:'],
    ['😝', ':stuck_out_tongue_closed_eyes:'],
    ['😔', ':pensive:'],
    ['😅', ':sweat_smile:'],
    ['😕', ':confused:'],
    ['🙄', ':rolling_eyes:'],
    ['😨', ':fearful:'],
    ['🤐', ':zipper_mouth:'],
    ['😵', ':dizzy_face:'],
    ['😂', ':joy:'],
    ['😍', ':heart_eyes:'],
    ['😘', ':kissing_heart:'],
    ['😇', ':innocent:'],
    ['🤒', ':thermometer_face:'],
    ['😴', ':sleeping:'],
    ['😈', ':smiling_imp:'],
    ['👽', ':alien:'],
    ['❤️', ':heart:'],
    ['👏', ':clap:'],
    ['👍', ':thumbsup:'],
    ['👎', ':thumbsdown:'],
    ['🙏', ':pray:'],
    ['👶', ':baby:'],
    ['👦', ':boy:'],
    ['👧', ':girl:'],
    ['💃', ':dancer:'],
    ['🤰', ':pregnant_woman:'],
    ['🐶', ':dog:'],
    ['🐱', ':cat:'],
    ['🐭', ':mouse:'],
    ['🐻', ':bear:'],
    ['🐷', ':pig:'],
    ['🐙', ':octopus:'],
    ['🐧', ':penguin:'],
    ['🐝', ':bee:'],
    ['🐘', ':elephant:'],
    ['🙈', ':see_no_evil:'],
    ['🙉', ':hear_no_evil:'],
    ['🙊', ':speak_no_evil:'],
    ['🌳', ':deciduous_tree:'],
    ['🌴', ':palm_tree:'],
    ['🍀', ':four_leaf_clover:'],
    ['🍂', ':fallen_leaf:'],
    ['🌹', ':rose:'],
    ['🌷', ':tulip:'],
    ['☃️', ':snowman2:'],
    ['🌜', ':last_quarter_moon_with_face:'],
    ['🌞', ':sun_with_face:'],
    ['🌧', ':cloud_rain:'],
    ['🔥', ':fire:'],
    ['❄️', ':snowflake:'],
    ['🍏', ':green_apple:'],
    ['🍉', ':watermelon:'],
    ['🍒', ':cherries:'],
    ['🥕', ':carrot:'],
    ['🥑', ':avocado:'],
    ['🍕', ':pizza:'],
    ['🍣', ':sushi:'],
    ['🥗', ':salad:'],
    ['🎂', ':birthday:'],
    ['🍼', ':baby_bottle:'],
    ['☕', ':coffee:'],
    ['🍹', ':tropical_drink:'],
    ['🚗', ':red_car:'],
    ['🚕', ':taxi:'],
    ['🚌', ':bus:'],
    ['🚓', ':police_car:'],
    ['🚑', ':ambulance:'],
    ['🚒', ':fire_engine:'],
    ['🏍', ':motorcycle:'],
    ['🚲', ':bike:'],
    ['🚂', ':steam_locomotive:'],
    ['⛵', ':sailboat:'],
    ['✈️', ':airplane:'],
    ['🚀', ':rocket:'],
    ['⛰', ':mountain:'],
    ['🏕', ':camping:'],
    ['🏖', ':beach:'],
    ['🏙', ':cityscape:'],
    ['🏡', ':house_with_garden:'],
    ['📱', ':iphone:'],
    ['💻', ':computer:'],
    ['📷', ':camera:'],
    ['💵', ':dollar:'],
    ['💎', ':gem:'],
    ['🔨', ':hammer:'],
    ['💣', ':bomb:'],
    ['💉', ':syringe:'],
    ['💊', ':pill:'],
    ['🔑', ':key:'],
    ['🎈', ':balloon:'],
    ['🎁', ':gift:'],
    ['🎉', ':tada:'],
    ['📨', ':envelope:'],
    ['📦', ':package:'],
    ['📓', ':notebook:'],
    ['🛒', ':shopping_cart:'],
    ['🇸🇰', ':flag_sk:'],
    ['🇨🇿', ':flag_cz:'],
];

const unicodeToShortcode = new Map();
const shortcodeToUnicode = new Map();

data.forEach((item) => {
    unicodeToShortcode.set(item[0], item[1]);
    shortcodeToUnicode.set(item[1], item[0]);
});

export function toShort(unicode) {
    return unicodeToShortcode.get(unicode);
}

export function toUnicode(short) {
    return shortcodeToUnicode.get(short);
}
