import memoize from 'lodash-es/memoize';
import { WIKI_CATEGORIES } from 'mk/autogenerated/wikiCategories';
import React from 'react';

export const UNLISTED_WIKI_CATEGORIES = [11, 12, 13, 14]; // 'Ine', 'FAQ', 'Centrum recenzii', 'Kocikopedia '
export const WIKI_REVIEWS_ARTICLE_SLUG = WIKI_CATEGORIES.filter((wc) => wc.id === 13)[0].slug;
export const WIKI_OTHERS_ARTICLE_SLUG = WIKI_CATEGORIES.filter((wc) => wc.id === 11)[0].slug;
export const WIKI_CALCULATORS_ARTICLE_SLUG = WIKI_CATEGORIES.filter((wc) => wc.id === 15)[0].slug;

export const WIKI_EXPERIENCE_MENU_MORE = (id: number) => `wiki-experience-menu-more-${id}`;
export const isFaq = (categorySlug: string) => categorySlug === 'faq';
export const isNoAdsCategory = (categorySlug: string) => isFaq(categorySlug) || categorySlug === WIKI_OTHERS_ARTICLE_SLUG;
export const isArticleOfReviewsCategory = (categorySlug: string) => categorySlug === WIKI_REVIEWS_ARTICLE_SLUG;
export const isReviewHomepageArticle = (categorySlug: string, articleSlug: string) =>
    categorySlug === WIKI_REVIEWS_ARTICLE_SLUG &&
    articleSlug === WIKI_REVIEWS_ARTICLE_SLUG;

export const getPhotoVersionFactory = memoize((versionsWithFallbacks) => {
  return (photo) => {
    for (const version of versionsWithFallbacks) {
      if (photo.versions.includes(version)) {
        return version;
      }
    }
    return versionsWithFallbacks[versionsWithFallbacks.length - 1];
  };
});

