import { connectRouter, RouterState } from 'connected-react-router';
import { AboutAppState } from 'mk2/apps/about/reducers';
import { AdminclubAppState } from 'mk2/apps/adminclub/reducers';
import { BazaarAppState } from 'mk2/apps/bazaar/reducers';
import { blogsReducer, BlogsAppState } from 'mk2/apps/blogs/reducers';
import { BoAppState } from 'mk2/apps/bo/reducers';
import { ContestsState } from 'mk2/apps/contests/reducers';
import { CounsellingsState } from 'mk2/apps/counsellings/reducers';
import { DashAppState } from 'mk2/apps/dash/reducers';
import { FaqAppState } from 'mk2/apps/faq/reducers';
import { forumReducer, ForumAppState } from 'mk2/apps/forum/reducers';
import { GroupsAppState } from 'mk2/apps/groups/reducers';
import { HeartsAppState } from 'mk2/apps/hearts/reducers';
import { HomeAppState } from 'mk2/apps/home/reducers';
import { MailAppState } from 'mk2/apps/mail/reducers';
import { PlanAppState } from 'mk2/apps/plan/reducers';
import { SettingsAppState } from 'mk2/apps/settings/reducers';
import { StrollersAppState } from 'mk2/apps/strollers/reducers';
import { TestingAppState } from 'mk2/apps/testings/reducers';
import { UsersAppState } from 'mk2/apps/users/reducers';
import { WallAppState } from 'mk2/apps/wall/reducers';
import { WikiAppState } from 'mk2/apps/wiki/reducers';
import {
    actionButtonsReducer,
    initialActionButtonState,
    ActionButtonsState,
    ActionButtonState,
} from 'mk2/containers/ActionButton/ActionButton.reducers';
import { adSlotReducer, AdSlotState } from 'mk2/containers/AdSlot/AdSlot.reducers';
import {
    adSlotAnchorLeaderboardReducer,
    AdSlotAnchorLeaderboardState,
} from 'mk2/containers/AdSlotAnchorLeaderboard/AdSlotAnchorLeaderboard.reducers';
import {
    adSlotOffsiteReducer,
    AdSlotOffsiteState,
} from 'mk2/containers/AdSlotOffsite/AdSlotOffsite.reducers';
import { abReducer, ABsState } from 'mk2/containers/AB/AB.reducers';
import { complaintReducer, ComplaintsState } from 'mk2/containers/Complaint/Complaint.reducers';
import { deletionReducer, DeletionsState } from 'mk2/containers/Deletion/Deletion.reducers';
import {
    installPromptReducer,
    InstallPromptState,
} from 'mk2/containers/InstallPrompt/InstallPrompt.reducers';
import { getIsLiked, getLikesCount, LikeableEntitiesState } from 'mk2/containers/Like/Like.reducers';
import { menuReducer, MenuState } from 'mk2/containers/Menu/Menu.reducers';
import {
    pageHeaderReducer,
    PageHeaderState,
} from 'mk2/containers/PageHeader/PageHeader.reducers';
import pregnancyNewsletterReducer, { PregnancyNewsletterState } from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.reducers';
import {
    initialToolbarState,
    toolbarsReducer,
    ToolbarsState,
    ToolbarState,
} from 'mk2/containers/Toolbar/Toolbar.reducers';
import { UserAgentParsed } from 'mk2/helpers/detects';
import { initialMKReduxFormState, mkReduxFormsReducer, MKReduxFormsState, MKReduxFormState } from 'mk2/helpers/form.reducers';
import { debugReducer, DebugState } from 'mk2/reducers/debug';
import { entitiesReducer, EntitiesState } from 'mk2/reducers/entities';
import { releaseReducer, ReleaseState } from 'mk2/reducers/release';
import {
    requestReducer,
    RequestState,
    RequestStateProvidedFromServer,
} from 'mk2/reducers/request';
import { responseReducer, ResponseState } from 'mk2/reducers/response';
import { Entity } from 'mk2/schemas';
import { reducer as toastrReducer } from 'react-redux-toastr';

// pociatocny stav ktory nam poskytne server (node/django)
//
// keep in sync with redux_initial_state() in context.py
export interface AppStateProvidedFromServer {
    request: RequestStateProvidedFromServer;
    containers: {
        global: {
            pageHeader: PageHeaderState;
        },
    };
}

interface ContainersState {
    global: {
        ab: ABsState;
        adSlot: AdSlotState;
        adSlotOffsite: AdSlotOffsiteState;
        adSlotAnchorLeaderboard: AdSlotAnchorLeaderboardState;
        complaint: ComplaintsState;
        deletion: DeletionsState;
        toolbar: ToolbarsState;
        menu: MenuState;
        pageHeader: PageHeaderState;
        actionButtons: ActionButtonsState;
        installPrompt: InstallPromptState;
        pregnancyNewsletter: PregnancyNewsletterState;
    };
    about: AboutAppState;
    adminclub: AdminclubAppState;
    bazaar: BazaarAppState;
    bo: BoAppState;
    dash: DashAppState;
    mail: MailAppState;
    wall: WallAppState;
    home: HomeAppState;
    hearts: HeartsAppState;
    forum: ForumAppState;
    groups: GroupsAppState;
    blogs: BlogsAppState;
    settings: SettingsAppState;
    plan: PlanAppState;
    faq: FaqAppState;
    wiki: WikiAppState;
    strollers: StrollersAppState;
    users: UsersAppState;
    counsellings: CounsellingsState;
    testings: TestingAppState;
    contests: ContestsState;
}

const containersReducer = {
    global: {
        ab: abReducer,
        adSlot: adSlotReducer,
        adSlotOffsite: adSlotOffsiteReducer,
        adSlotAnchorLeaderboard: adSlotAnchorLeaderboardReducer,
        complaint: complaintReducer,
        deletion: deletionReducer,
        toolbar: toolbarsReducer,
        menu: menuReducer,
        pageHeader: pageHeaderReducer,
        actionButtons: actionButtonsReducer,
        installPrompt: installPromptReducer,
        pregnancyNewsletter: pregnancyNewsletterReducer,
    },
    forum: forumReducer,
    blogs: blogsReducer,
};

export interface AppState extends AppStateProvidedFromServer {
    router: RouterState;
    form: MKReduxFormsState;
    toastr: any; // TODO @type: Add react-router-toastr

    request: RequestState;
    response: ResponseState;

    entities: EntitiesState;
    containers: ContainersState;

    debug?: DebugState;
    release?: ReleaseState;
}

const reducers = (history) => ({
    // Libs
    router: connectRouter(history),
    form: mkReduxFormsReducer,
    toastr: toastrReducer,

    // Ours
    request: requestReducer,
    response: responseReducer,

    entities: entitiesReducer,

    containers: containersReducer,

    debug: debugReducer,
    release: releaseReducer,
});


export default reducers;

// Export selectors from request
export const getRequestState = (state: AppState) => state.request;
export const getRequestBaseUrl = (state: AppState) => state.request.baseUrl;
export const getRequestUser = (state: AppState) => state.request.user;
export const getRequestPermissions = (state: AppState) => state.request.permissions;
export const getRequestSettings = (state: AppState) => state.request.settings;
export const getRequestUserAgent = (state: AppState): UserAgentParsed => state.request.userAgent;
export const getRequestDeviceMobile = (state: AppState) => state.request.device.mobile;
export const getRequestDeviceIsIOS = (state: AppState): boolean =>
    state.request.userAgent && state.request.userAgent.os && state.request.userAgent.os.name === 'iOS';
export const getRequestLastKnownUsername = (state: AppState): string => state.request.lastKnownUsername;
export const getNewGroupsEnabled = (state: AppState) => state.request.newGroupsEnabled;
export const getRequestWebpush = (state: AppState) => state.request.webpush;

export const getActionButtonState = (state: AppState, buttonId: string): ActionButtonState =>
    state.containers.global.actionButtons[buttonId] || initialActionButtonState;

// Export selectors from response
export const getResponseRenderedAt = (state: AppState) =>
    state.response.renderedAt;

// Export selectors from entities
export const getEntitiesEntity = (state: AppState, entity: string, id: number | string): Entity =>
    state.entities[entity] && state.entities[entity][id] ? state.entities[entity][id] : undefined;

export const getFormState = (state: AppState, formName: string): MKReduxFormState =>
    state.form[formName] || initialMKReduxFormState;

// Export selectors from like
// Note: We are reading state from entities, because that is where the likeable entity is storred
export const getLikeIsLiked = (state: AppState, entity: string, id: number) =>
    getIsLiked(state.entities.likeable as LikeableEntitiesState, entity, id);
export const getLikeLikesCount = (state: AppState, entity: string, id: number) =>
    getLikesCount(state.entities.likeable as LikeableEntitiesState, entity, id);

// Export selectors from toolbar
export const getToolbarState = (state: AppState, formName: string): ToolbarState =>
    state.containers.global.toolbar[formName] || initialToolbarState;

// Export selectors from menu
export const getMenuShow = (state: AppState, menuId: string) =>
    state.containers.global.menu[menuId] && state.containers.global.menu[menuId].show;

// Forum
export const getPageHeaderState = (state: AppState): PageHeaderState => state.containers.global.pageHeader;
