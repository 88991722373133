import cx from 'classnames';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { constructIframe, RenderSnippetAs } from 'mk2/apps/wiki/utils';
import { Img } from 'mk2/components/Img';
import { PregnancyCalculatorStepTwoAction } from 'mk2/containers/PregnancyCalculator/PregnancyCalculator';
import React from 'react';
import styles from './WikiFormatHelp.mscss';

const skWiki = ACTIVE_SERVER_ID === 201;

interface OwnProps {
    domain?: 'strollers-blog-article';
}

type Props = OwnProps;

const WikiFormatHelp: React.FunctionComponent<Props> = ({domain}) => (
    <table className={styles.WikiFormatHelp}>
        <thead>
            <tr>
                <th />
                <td>{skWiki ? 'Čo napíšeš' : 'Co napíšeš'}</td>
                <td>{skWiki ? 'Ako to vyzerá' : 'Jak to vypadá'}</td>
            </tr>
        </thead>

        <tbody>
            <tr>
                <th>{skWiki ? 'Tučné / Bold' : 'Tučné / Bold'}</th>
                <td className={styles.WikiFormatHelp__source}>**{skWiki ? 'Cisársky rez' : 'Císařský řez'}**</td>
                <td className={styles.WikiFormatHelp__bold}>{skWiki ? 'Cisársky rez' : 'Císařský řez'}</td>
            </tr>

            <tr>
                <th>{skWiki ? 'Kurzíva / Italic' : 'Kurzíva / Italic'}</th>
                <td className={styles.WikiFormatHelp__source}>//{skWiki ? 'Dyslexia' : 'Dyslexie'}//</td>
                <td className={styles.WikiFormatHelp__italic}>{skWiki ? 'Dyslexia' : 'Dyslexie'}</td>
            </tr>

            <tr>
                <th>{skWiki ? 'Nečíslovaný zoznam' : 'Nečíslovaný seznam'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    {skWiki ? 'Typy hnačiek podľa príčiny:' : 'Typy hnaček podle příčiny:'}<br/>
                    - {skWiki ? 'infekčné' : 'infekční'}<br/>
                    -- {skWiki ? 'bakteriálne' : 'bakteriální'}<br/>
                    -- {skWiki ? 'vírusové' : 'virusové'}<br/>
                    -- {skWiki ? 'plesňové' : 'plísňové'}<br/>
                    - {skWiki ? 'neinfekčné' : 'neinfekční'}
                </td>
                <td>
                    {skWiki ? 'Typy hnačiek podľa príčiny:' : 'Typy hnaček podle příčiny:'}
                    <ul className={styles.WikiFormatHelp__unorderedList}>
                        <li>{skWiki ? 'infekčné' : 'infekční'}
                        <ul className={styles.WikiFormatHelp__unorderedList__nestedList}>
                            <li>{skWiki ? 'bakteriálne' : 'bakteriální'}</li>
                            <li>{skWiki ? 'vírusové' : 'virusové'}</li>
                            <li>{skWiki ? 'plesňové' : 'plísňové'}</li>
                        </ul></li>
                        <li>{skWiki ? 'neinfekčné' : 'neinfekční'}</li>
                    </ul>
                </td>
            </tr>

            <tr>
                <th>{skWiki ? 'Číslovaný zoznam' : 'Číslovaný seznam'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    -# {skWiki ? 'jasle' : 'jesle'}<br/>
                    -# {skWiki ? 'materská škola' : 'mateřská škola'}<br/>
                    -## {skWiki ? 'verejná materská škola' : 'veřejná mateřská škola'}<br/>
                    -## {skWiki ? 'súkromná materská škola' : 'soukromá mateřská škola'}<br/>
                    -# {skWiki ? 'základná škola' : 'základní škola'}
                </td>
                <td>
                    <ol className={styles.WikiFormatHelp__orderedList}>
                        <li>{skWiki ? 'jasle' : 'jesle'}</li>
                        <li>{skWiki ? 'materská škola' : 'mateřská škola'}
                            <ol className={styles.WikiFormatHelp__orderedList__nestedList}>
                                <li>{skWiki ? 'verejná materská škola' : 'veřejná mateřská škola'}</li>
                                <li>{skWiki ? 'súkromná materská škola' : 'soukromá mateřská škola'}</li>
                            </ol>
                        </li>
                        <li>{skWiki ? 'základná škola' : 'základní škola'}</li>
                    </ol>
                </td>
            </tr>

            <tr>
                <th>{skWiki ? 'Nadpis' : 'Nadpis'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    == {skWiki ? 'Veľký nadpis, nadpis prvej úrovne' : 'Velký nadpis, nadpis první úrovně'}<br/>
                    === {skWiki ? 'Stredný nadpis, nadpis druhej úrovne' : 'Střední nadpis, nadpis druhé úrovně'}<br/>
                    ==== {skWiki ? 'Malý nadpis, nadpis tretej úrovne' : 'Malý nadpis, nadpis třetí úrovně'}
                </td>
                <td>
                    <h2 className={styles.WikiFormatHelp__headingH2}>{skWiki ? 'Veľký nadpis, nadpis prvej úrovne' : 'Velký nadpis, nadpis první úrovně'}</h2>
                    <h3 className={styles.WikiFormatHelp__headingH3}>{skWiki ? 'Stredný nadpis, nadpis druhej úrovne' : 'Střední nadpis, nadpis druhé úrovně'}</h3>
                    <h4 className={styles.WikiFormatHelp__headingH4}>{skWiki ? 'Malý nadpis, nadpis tretej úrovne' : 'Malý nadpis, nadpis třetí úrovně'}</h4>
                </td>
            </tr>

            <tr>
                <th>{skWiki ? 'Jednoduchá tabuľka' : 'Jednoduchá tabulka'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    |{skWiki ? 'Typ kočíka' : 'Typ kočárku'}|{skWiki ? 'štvorkolka' : 'čtyřkolka'}|<br/>
                    |{skWiki ? 'Hmotnosť' : 'Hmotnost'}|{skWiki ? '10 kg' : '10 kg'}|<br/>
                    |{skWiki ? 'Priemer predného kolesa' : 'Průměr předního kolečka'}| {skWiki ? '18 cm' : '18 cm'}||
                </td>
                <td className={styles.WikiFormatHelp__tableSimple}>
                    <table className={styles.MarkdownTable}>
                        <tbody>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? 'Typ kočíka' : 'Typ kočárku'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? 'štvorkolka' : 'čtyřkolka'}</td>
                            </tr>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? 'Hmotnosť' : 'Hmotnost'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '10 kg' : '10 kg'}</td>
                            </tr>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? 'Priemer predného kolesa' : 'Průměr předního kolečka'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '18 cm' : '18 cm'}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Tabuľka s nadpisom' : 'Tabulka s nadpisem'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    |={skWiki ? 'Vek' : 'Věk'}|={skWiki ? 'Hmotnosť' : 'Hmotnost'}|<br/>
                    |{skWiki ? '2 - 6 mesiacov' : '2 - 6 měsíců'}|{skWiki ? 'menej ako 6 kg' : 'méně jak 6 kg'}|<br/>
                    |{skWiki ? '6 - 12 mesiacov' : '6 - 12 měsíců'}|{skWiki ? '6-10 kg' : '6-10 kg'}|<br/>
                    |{skWiki ? '1 – 2 roky' : '1 - 2 roky'}|{skWiki ? '10-12 kg' : '10-12 kg'}|<br/>
                    |{skWiki ? '2 – 5 rokov' : '2 - 5 roků'}|{skWiki ? '13-19 kg' : '13-19 kg'}|<br/>
                </td>
                <td>
                    <table className={styles.MarkdownTable}>
                        <tbody>
                            <tr>
                                <th className={styles.MarkdownTable__head}>{skWiki ? 'Vek' : 'Věk'}</th>
                                <th className={styles.MarkdownTable__head}>{skWiki ? 'Hmotnosť' : 'Hmotnost'}</th>
                            </tr>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '2 - 6 mesiacov' : '2 - 6 měsíců'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? 'menej ako 6 kg' : 'méně jak 6 kg'}</td>
                            </tr>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '6 - 12 mesiacov' : '6 - 12 měsíců'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '6-10 kg' : '6-10 kg'}</td>
                            </tr>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '1 - 2 roky' : '1 - 2 roky'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '10-12 kg' : '10-12 kg'}</td>
                            </tr>
                            <tr>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '2 - 5 rokov' : '2 - 5 roků'}</td>
                                <td className={styles.MarkdownTable__cell}>{skWiki ? '13-19 kg' : '13-19 kg'}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie obrázkov a fotografií' : 'Vkládání obrázků a fotografií'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    &lt;&lt;pic
                    cd="abcd337"
                    title="{skWiki ? 'Alternatívny popis obrázka' : 'Alternativní popis obrázku'}"
                    caption="{skWiki ? 'Popis pod obrázkom' : 'Popis pod obrázkem'}"
                    link_address="https://modrykonik.sk/"
                    &gt;&gt;
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/DrOklzRyGM3_s738x738.jpg" alt={skWiki ? 'Alternatívny popis obrázka' : 'Alternativní popis obrázku'} width={193} height={156} /></div>
                    <div className={styles.WikiFormatHelp__note}>{skWiki ? 'Popis pod obrázkom' : 'Popis pod obrázkem'}</div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie IFRAME' : 'Vkládání IFRAME'}</th>
                <td className={styles.WikiFormatHelp__source}>&lt;&lt;iframe src="https://example.com/" mobile-height="600" desktop-height="400"&gt;&gt;</td>
                <td>{constructIframe('https://example.com/', 400, RenderSnippetAs.HTML_IFRAME, styles.WikiFormatHelp__iframe)}</td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie Youtube videí' : 'Vkládání Youtube videí'}</th>
                <td className={styles.WikiFormatHelp__source}>&lt;&lt;youtube code="Kwf-Vf89NX0"&gt;&gt;</td>
                <td><iframe width="246" height="146" src="//www.youtube-nocookie.com/embed/Kwf-Vf89NX0?rel=0"/></td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie Vimeo videí' : 'Vkládání Vimeo videí'}</th>
                <td className={styles.WikiFormatHelp__source}>&lt;&lt;vimeo code="122634437"&gt;&gt;</td>
                <td><iframe width="246" height="146" src="https://player.vimeo.com/video/122634437"/></td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie Giphy gifov' : 'Vkládání Giphy gifů'}</th>
                <td className={styles.WikiFormatHelp__source}>&lt;&lt;giphy code="FsD8MWdLDneb6"&gt;&gt;</td>
                <td><iframe width="246" height="146" src="https://giphy.com/embed/mUkXLWyeS8uvC"/></td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie Instagram postov/reels' : 'Vkládání Instagram postov/reels'}</th>
                <td className={styles.WikiFormatHelp__source}>&lt;&lt;instagram url="..."&gt;&gt;</td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/5AaPKrkDzBT_s738x738.jpg" width={170} height={310} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vkladanie TikTok postov' : 'Vkládání TikTok postov'}</th>
                <td className={styles.WikiFormatHelp__source}>&lt;&lt;tiktok url="..."&gt;&gt;</td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/5AaPKrkDzBT_s738x738.jpg" width={170} height={310} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Odkaz/linka na wiki článok' : 'Odkaz/link na wiki článek'}</th>
                <td className={styles.WikiFormatHelp__source}>{skWiki ? '[[/porod/|Pôrod]]' : '[[/porod/|Porod]]'}</td>
                <td><a href="/porod">{skWiki ? 'Pôrod' : 'Porod'}</a></td>
            </tr>
            <tr>
                <th>{skWiki ? 'Odkaz/linka mimo Wiki' : 'Odkaz/link mimo Wiki'}</th>
                <td className={styles.WikiFormatHelp__source}>{skWiki ? '[[https://www.modrykonik.sk/forum/|Fórum]]' : '[[https://www.modrykonik.cz/forum/|Fórum]]'}</td>
                <td><a href="/forum">{skWiki ? 'Fórum' : 'Fórum'}</a></td>
            </tr>
            <tr>
                <th>{skWiki ? 'Odkaz/linka na kočík' : 'Odkaz/link na kočárek'}</th>
                <td className={styles.WikiFormatHelp__source}>{skWiki ? '[[/kocikopedia/4baby-rapid]]' : '[[/kocarkopedie/4baby-rapid]]'}</td>
                <td><a href={skWiki ? '/kocikopedia/4baby-rapid' : '/kocarkopedie/4baby-rapid'}>{skWiki ? '4baby Rapid' : '4baby Rapid'}</a></td>
            </tr>
            <tr>
                <th>{skWiki ? 'Vodorovná čiara' : 'Vodorovná čára'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    {skWiki ? 'Som tehotná' : 'Jsem těhotná'}<br />
                    <br />
                    ---<br />
                    <br />
                    {skWiki ? 'Starám sa o dieťa' : 'Starám se o dítě'}<br />
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__hr}>
                        <p>{skWiki ? 'Som tehotná' : 'Jsem těhotná'}</p>
                        <hr />
                        <p>{skWiki ? 'Starám sa o dieťa' : 'Starám se o dítě'}</p>
                    </div>
                </td>
            </tr>
            <tr>
                <th>{'Hashtag'}</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__hashtag)}>
                    <p>{'#modrykonik'}</p>
                    {domain === 'strollers-blog-article' && (
                        <>
                            <hr />
                            <p>{skWiki ? 'Hashtagy článkov kočíkopédie:' : 'Hashtagy článků kočárkopedie:'}</p>
                            <ul>
                                <li>#{skWiki ? 'redakcna_recenzia' : 'redakcni_recenze'}</li>
                                <li>#{skWiki ? 'novinky' : 'novinky'}</li>
                                <li>#{skWiki ? 'buying_guides' : 'buying_guides'}</li>
                                <li>#{skWiki ? 'prislusenstvo' : 'prislusenstvi'}</li>
                                <li>#{skWiki ? 'akonato' : 'jaknato'}</li>
                                <li>#{skWiki ? 'top_guides' : 'top_guides'}</li>
                                <li>#{skWiki ? 'tipy_poradkyne' : 'tipy_poradkyne'}</li>
                            </ul>
                        </>
                    )}
                </td>
                <td className={styles.WikiFormatHelp__hashtag}>
                    <p><a href="/blogs/?query=%23modrykonik">{'#modrykonik'}</a></p>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Poznámka' : 'Poznámka'}</th>
                <td className={styles.WikiFormatHelp__source}>:::: {skWiki ? 'Myslím, teda som.' : 'Myslím, tedy jsem.'}</td>
                <td>
                    <div className={styles.WikiFormatHelp__note}>{skWiki ? 'Myslím, teda som.' : 'Myslím, tedy jsem.'}</div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Kód' : 'Kód'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    {skWiki
                        ? 'Keď napíšes {{{//text s kurzívou//}}} dostaneš //text s kurzívou//.'
                        : 'Když napíšeš {{{//text s kurzívou//}}} dostaneš //text s kurzívou//.'
                    }
                </td>
                <td>
                    {skWiki
                        ? <>Keď napíšes markdown <code className={styles.WikiFormatHelp__code_inline}>//text s kurzívou//</code> dostaneš výsledok <i>text s kurzívou</i>.</>
                        : <>Když napíšeš markdown <code className={styles.WikiFormatHelp__code_inline}>//text s kurzívou//</code> dostaneš výsledek <i>text s kurzívou</i>.</>
                    }
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Viac riadkový kód' : 'Víceřádkový kód'}</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)}>
                    <pre>{
'{{{\n' +
'<mk:category name="Šport pre deti | Cyklistika">\n' +
'    <mk:color>Žltá</mk:color>\n' +
'    <mk:type>Cykloodrážadlá</mk:type>\n' +
'    <mk:sex>Pre chlapcov</mk:sex>\n' +
'</mk:category>\n' +
'}}}\n'
                    }</pre>
                </td>
                <td>
                    <pre className={styles.WikiFormatHelp__code}>{
'<mk:category name="Šport pre deti | Cyklistika">\n' +
'    <mk:color>Žltá</mk:color>\n' +
'    <mk:type>Cykloodrážadlá</mk:type>\n' +
'    <mk:sex>Pre chlapcov</mk:sex>\n' +
'</mk:category>\n'
                    }</pre>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Označenie zdrojov' : 'Označení zdrojů'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    ++++<br />
                    == Nadpis<br />
                    - [[URL|Link A]]<br />
                    - [[URL|Link B]]<br />
                    - [[URL|Link C]]<br />
                    ++++
                </td>
                <td className={styles.WikiFormatHelp__italic}>
                    {skWiki ? 'Označenie zdrojov zlepšuje SEO stránky a spracovanie článku.' : 'Označení zdrojů zlepšuje SEO stránky a zpracování článku.'}
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Rebríček kočíkov' : 'Žebříček kočárků'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    &lt;&lt;strollers_chart title="Populárne golfové kočíky" filter="type=golf&amp;type=3kombi"&gt;&gt;
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/MHJ8h2eSQMm_s460xn.jpg" width={250} height={347} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Pricemania widget' : 'Pricemania widget'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    &lt;&lt;pricemania id="61927d54069a975de552a225"&gt;&gt;<br />
                    &lt;&lt;pricemania id="61927d54069a975de552a225" height="300"&gt;&gt;<br />
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/KJPAUltu5Gc_s560x560.jpg" width={250} height={381} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Podrobné recenzie' : 'Podrobné recenze'}</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)}>
                    &lt;&lt;testing hashtag="plasmakosmetika" urls="<br />
                    https://www.modrykonik.sk/blog/willi/article/moje-dvoj-tyzdnove-testovanie-kozmetiky-6zjcf3/<br />
                    https://www.modrykonik.sk/blog/adazdca/message/test-plasmakosmetika-po-2-tyzdnoch-som-nw3fxu/<br />
                    "&gt;&gt;
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/6LE4TVb5Tt7_s460xn.jpg" width={250} height={168} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Náhľady (ps / kočíky / články)' : 'Náhledy (ps / kočárky / články)'}</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)}>
                    &lt;&lt;previews urls="<br />
                    https://www.modrykonik.sk/recenzie/peeling-mark-scrub/<br />
                    https://www.modrykonik.sk/recenzie/pracie-prostriedky-bupi-baby/<br />
                    ...<br />
                    https://www.modrykonik.sk/kocikopedia/easywalker-rudey/<br />
                    https://www.modrykonik.sk/kocikopedia/easywalker-harvey2/<br />
                    ...<br />
                    https://www.modrykonik.sk/blog/kocikopedia/article/recenzia-kocika-cybex-balios-s-5ex9k9/<br />
                    https://www.modrykonik.sk/blog/kocikopedia/article/ake-top-novinky-v-roku-2022-ponukne-cybe-gks8tk/<br />
                    "&gt;&gt;<br />
                    <br />
                    alebo s nadpisom<br />
                    <br />
                    &lt;&lt;previews title="Pozri aj" urls="<br />
                    https://www.modrykonik.sk/blog/kocikopedia/article/recenzia-kocika-cybex-balios-s-5ex9k9/<br />
                    "&gt;&gt;
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/9MTXASqH1yz_s492x492.jpg" width={250} height={148} /></div>
                </td>
            </tr>
            <tr>
                <th>Produktový box</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)}>
                    &lt;&lt;product_box url="https://www.modrykonik.sk/kocikopedia/valco-baby-snap-4/"&gt;&gt;<br /><br />
                    alebo<br /><br />
                    &lt;&lt;product_box url="https://www.modrykonik.sk/recenzie/plienky-fred-flo-premium/" market="eyJjYXRzIjpbIm5hcHBpZXMiXX0="&gt;&gt;
                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/x6MBHEqA0jz_s460xn.jpg" width={250} height={115} /></div>
                </td>
            </tr>
            <tr>
                <th>Tehotenský newsletter</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)} colSpan={2}>
                    <br />
                    &lt;&lt;pregnancy_newsletter&gt;&gt;<br />
                    <br />
                    alebo<br />
                    <br />
                    &lt;&lt;pregnancy_newsletter initial_week="6"&gt;&gt;<br />
                    <br />
                    alebo<br />
                    <br />
                    &lt;&lt;pregnancy_newsletter signupconf="ci=var01&get_param=value"&gt;&gt;<br />
                    <br />
                    alebo<br />
                    <br />
                    &lt;&lt;pregnancy_newsletter ci="var02"&gt;&gt;<br />
                    <br />
                    alebo<br />
                    <br />
                    &lt;&lt;pregnancy_newsletter ci="var" title="Nadpis na stranke registracie" text="Text na stranke registracie."&gt;&gt;<br />
                    <br />
                    (<b>Parameter <i>ci</i> je nepovinný</b>, ak ho nezadáš, použije sa základný <i>ci="def"</i> a keď nastavíš <i>title</i> aj <i>text</i> použije sa <i>ci="var"</i>)<br />
                    <br />
                    <b>V <i>text/title</i> môžeš použiť premenné:</b><br />
                    <i>%group_name%</i><br />
                    <i>%group_active_members_count%</i><br />
                    <i>%pregnancy_week%</i><br />
                    <br />
                </td>
            </tr>
            <tr>
                <th>Tehotenská kalkulačka</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)} colSpan={2}>
                    <br />
                    &lt;&lt;pregnancy_calculator&gt;&gt;<br />
                    <br />
                    alebo<br />
                    <br />
                    &lt;&lt;pregnancy_calculator action="{PregnancyCalculatorStepTwoAction.BUTTON_TO_SIGNUP_WITH_SAME_DATE_GROUP_INVITATION}"&gt;&gt;<br />
                    <br />
                    Hodnoty action:<br />
                    {PregnancyCalculatorStepTwoAction.PREGNANCY_NEWSLETTER}:  widget tehotenského newslettra (default)<br />
                    {PregnancyCalculatorStepTwoAction.BUTTON_TO_SIGNUP_WITH_SAME_DATE_GROUP_INVITATION}: tlačidlo na signup s pozvaním do termínovej skupiny<br />
                    <br />
                    <br />
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Kde kúpim ...?' : 'Kde koupim ...?'}</th>
                <td className={cx(styles.WikiFormatHelp__source, styles.WikiFormatHelp__nowrap)}>
                    <br />
                    &lt;&lt;market title="{skWiki ? 'Kde kúpim vysávač?' : 'Kde koupim vysavač?'}" manual-items="<br />
                    my-concept.sk;https://www.my-concept.sk/vr3210-roboticky-vysavac-2-v-1-real-force-laser_d27094.html;539<br />
                    Alza.sk;https://www.alza.sk/concept-vr3210-3v1-real-force-laser-uvc-d6220293.htm;538,90<br />
                    "&gt;&gt;

                </td>
                <td>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/Y4edeprteUB_s460xn.jpg" width={250} height={286} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Eshop widget' : 'Eshop widget'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    {skWiki ? 'ak namiesto produktov z kolekcie(colls) chceš zobraziť produkty z kategórie(cats) použi hodnotu cats namiesto colls' : 'pokud místo produktů z kolekce(colls) chceš zobrazit produkty z kategorie(cats) použij hodnotu cats místo colls'}
                    <br/><br/>
                    {skWiki ? 'veľký widget s hlavičkou' : 'velký widget s hlavičkou'}
                    <br/><br/>
                    &lt;&lt;eshop colls="collection1 collection2 collection3" header="true"&gt;&gt;<br />
                    <br/>
                    {skWiki ? 'veľký widget' : 'velký widget'}
                     <br/><br/>
                    &lt;&lt;eshop colls="collection1 collection2 collection3"&gt;&gt;<br />
                    <br/>
                    {skWiki ? 'malý widget s hlavičkou' : 'malý widget s hlavičkou'}
                    <br/><br/>
                    &lt;&lt;eshop colls="collection1 collection2 collection3" small="true" header="true"&gt;&gt;<br />
                    <br/>
                    {skWiki ? 'malý widget' : 'malý widget'}
                     <br/><br/>
                    &lt;&lt;eshop colls="collection1 collection2 collection3" small="true"&gt;&gt;<br />
                    <br/>
                </td>
                <td>

                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/HyL5QN7zaHU_s1600x1600.jpg" width={250} height={152} /></div>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/lA8UAfNLwn3_s1600x1600.jpg" width={250} height={133} /></div>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/UlwYzr6LfT6_s1600x1600.jpg" width={250} height={74} /></div>
                    <div className={styles.WikiFormatHelp__image}><Img src="https://mkskimgmodrykonik.vshcdn.net/1GXYLhPHoma_s1600x1600.jpg" width={250} height={57} /></div>
                </td>
            </tr>
            <tr>
                <th>{skWiki ? 'Nastavenie obrázkov / youtube ako cover' : 'Nastavení obrázků / youtube jako cover'}</th>
                <td className={styles.WikiFormatHelp__source}>
                    &lt;&lt;pic cd="abcd337" title=..." ... caption="..." <b>cover</b>&gt;&gt;<br />
                    <br />
                    &lt;&lt;youtube code="Kwf-Vf89NX0" <b>cover</b>&gt;&gt;
                </td>
                <td className={styles.WikiFormatHelp__italic}>
                    Kód <code>cover</code> {skWiki ? 'zabezpečí, aby bol obrázok / youtube video zobrazený nad nadpisom.' : 'zajistí, aby byl obrázek / youtube video zobrazený nad nadpisem.'}
                </td>
            </tr>
        </tbody>
    </table>
);

WikiFormatHelp.displayName = 'WikiFormatHelp';

export default React.memo<Props>(WikiFormatHelp);
