import { EnhancedTextarea, PublicProps as EnhancedTextareaProps } from 'mk2/components/forms/EnhancedTextarea';
import { FormControl } from 'mk2/components/forms/FormControl';
import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';

// public properties exposed via forms/TextareaField
interface TextareaFormControlPublicProps {
    // CeMi: nepridavajte className do FormFieldov. Rozne varianty UI nech sa
    // menia cez props. Tak su zname vsetky varianty ktore treba testovat a
    // podporovat
    //
    // Opakovane sposobuje bugy, ze ked sa css-ko prebije zhora a potom sa urobi
    // pimpup formfieldov, ostanu niektore formy rozbite. Napr. poposuvana pozicia
    // labelov, ikoniek, atd.
    // className?: string;
    label?: string;
    bottomBorder?: boolean;
    marginLeft?: boolean;
    minimizedLabel?: boolean;
    paddingRight?: boolean;
    fillRemainingSpace?: boolean;  // expand to fill remaining horizontal space in form
    scrollDownOnGrow?: boolean;
    isAdminUI?: boolean;
    value?: any;
    onChange?: any;
}

type TextareaControlProps =  EnhancedTextareaProps & TextareaFormControlPublicProps & WrappedFieldProps;

const TextareaFormControl: React.StatelessComponent<TextareaControlProps> = ({
    // WrappedFieldProps
    input,
    meta,
    label,

    // TextareaFormControlPublicProps
    bottomBorder,
    marginLeft,
    paddingRight,
    fillRemainingSpace,
    isAdminUI,
    placeholder,
    minimizedLabel = false,

    // EnhancedTextareaProps
    ...etaInputProps
}) => (
    <FormControl
        input={input}
        meta={meta}
        label={label}
        bottomBorder={bottomBorder}
        marginLeft={marginLeft}
        paddingRight={paddingRight}
        minimizedLabel={minimizedLabel}
        fillRemainingSpace={fillRemainingSpace}
        isAdminUI={isAdminUI}
        disabled={etaInputProps.disabled}
        placeholder={placeholder}
    >
        <EnhancedTextarea
            input={input}
            hasLabel={!!label}
            placeholder={placeholder}
            {...etaInputProps}
        />
    </FormControl>
);

type TextareaFieldProps = EnhancedTextareaProps & TextareaFormControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
};

export const TextareaField: React.StatelessComponent<TextareaFieldProps> = (props) => (
    <Field {...props} component={TextareaFormControl} />
);
