import cx from 'classnames';
import { count_CONTRIBUTORS_IN_LAST_WEEK } from 'mk/autogenerated/translations/utils.e93b75131ad0bfc03eb213315cba3cd1'
import { photoUrl } from 'mk/photo/photoUrl';
import InstagramEmbed from 'mk2/apps/wiki/containers/InstagramEmbed/InstagramEmbed';
import { TiktokEmbed } from 'mk2/apps/wiki/containers/TiktokEmbed/TiktokEmbed';
import { WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { Img } from 'mk2/components/Img';
import { ImgResponsive } from 'mk2/components/ImgResponsive';
import { ArticleLinkDataEntity, PhotoEntity, WikiArticleEntity, WikiCategoryEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';


export const wikiArticleUrl = (article: WikiArticleEntity | ArticleLinkDataEntity, hash?: string): string =>
    getWikiArticleUrl(
        (article as ArticleLinkDataEntity).categorySlug || (article as WikiArticleEntity).category.slug,
        (article as ArticleLinkDataEntity).articleSlug || (article as WikiArticleEntity).slug,
        {},
        hash,
    );

export const wikiCategoryUrl = (category: WikiCategoryEntity): string =>
    getWikiArticleUrl(category.slug, category.categoryArticleSlug);

export const isCategoryArticle = (article: WikiArticleEntity): boolean =>
    article && article.id === article.category.categoryArticleId;

export const wikiProductReviewUrl = (articleSlug: string, experienceId: number): string =>
    getWikiArticleUrl(WIKI_REVIEWS_ARTICLE_SLUG, articleSlug, {}, `experience-${experienceId}`);

const OutsideLink = ({ link, visibleText, count, showContributorsCount, opts }) => {
    const {nofollow = true, target = true, noopener = true} = opts || {};
    return (
        <a
            href={link}
            rel={`${noopener ? 'noopener ' : ''}${nofollow ? 'nofollow ' : ''}`.trim()}
            target={target === true ? '_blank' : (target ? target : null)}
        >
            {visibleText}{showContributorsCount && '\u00A0'}{showContributorsCount && (count > 0 ? (<span>•&nbsp;{interpolate(count_CONTRIBUTORS_IN_LAST_WEEK, {count})}</span>) : (<span>•&nbsp;{interpolate(count_CONTRIBUTORS_IN_LAST_WEEK, {count: 0})}</span>))}
        </a>
    );
};

export interface LinkProcessorOpts {
    nofollow?: boolean;
    target?: string | boolean;
}

export const linkProcessor = (lnk, visibleText, countTopicContributors, opts?: LinkProcessorOpts, showContributorsCount: boolean = true): React.ReactChild => {
    const FORUM_TOPIC_SLUG_RE = /^https?:\/\/www.modrykonik.(?:sk|cz)\/forum\/category\/(.*?)\/?$/;
    const forumLinkMatcher = new RegExp(FORUM_TOPIC_SLUG_RE, 'gm');

    const topicSlug = forumLinkMatcher.exec(lnk);
    return (
        <OutsideLink
            link={lnk}
            visibleText={visibleText}
            count={countTopicContributors && topicSlug && countTopicContributors[topicSlug[1]]}
            showContributorsCount={showContributorsCount && !!topicSlug}
            opts={opts || {}}
        />
    );
};


export enum RenderSnippetAs {
    HTML_IFRAME,
    HTML_LINK,
    PLAIN_LINK,
    MARKDOWN,
}

export const constructGiphy = (code: string, width: number, height: number, output: RenderSnippetAs = RenderSnippetAs.HTML_IFRAME, className?: string) => {
    if (output === RenderSnippetAs.PLAIN_LINK) {
        return `https://giphy.com/gifs/${code}`;

    } else if (output === RenderSnippetAs.HTML_LINK) {
        return (
            <a
                href={`https://giphy.com/gifs/${code}`}
                rel="noopener nofollow"
                target="_blank"
            >
                {`https://giphy.com/gifs/${code}`}
            </a>
        );

    } else if (output === RenderSnippetAs.HTML_IFRAME) {
        const src = `https://giphy.com/embed/${code}`;
        return (
            <iframe
                src={src}
                width={width}
                height={height}
                frameBorder="0"
                className={cx(className, 'giphy-embed')}
                allowFullScreen
            />
        );

    } else {
        return null;
    }
};

export const constructInstagram = (
    code?: string,
    url?: string,
    output: RenderSnippetAs = RenderSnippetAs.HTML_IFRAME,
) => {
    const finalUrl = url || `https://www.instagram.com/p/${code}/`;
    if (output === RenderSnippetAs.PLAIN_LINK) {
        return finalUrl;

    } else if (output === RenderSnippetAs.HTML_LINK) {
        return (
            <a
                href={finalUrl}
                rel="noopener nofollow"
                target="_blank"
            >
                {finalUrl}
            </a>
        );

    } else if (output === RenderSnippetAs.HTML_IFRAME) {
        return (
            <InstagramEmbed instagramUrl={finalUrl} />
        );
    } else {
        return null;
    }
};

export const constructTiktok = (
    url?: string,
    output: RenderSnippetAs = RenderSnippetAs.HTML_IFRAME,
) => {
    if (output === RenderSnippetAs.PLAIN_LINK) {
        return url;

    } else if (output === RenderSnippetAs.HTML_LINK) {
        return (
            <a
                href={url}
                rel="noopener nofollow"
                target="_blank"
            >
                {url}
            </a>
        );

    } else if (output === RenderSnippetAs.HTML_IFRAME) {
        return (
            <TiktokEmbed url={url} />
        );
    } else {
        return null;
    }
};

export const constructVimeo = (code: string, width: number, height: number, output: RenderSnippetAs = RenderSnippetAs.HTML_IFRAME, className?: string) => {
    if (output === RenderSnippetAs.PLAIN_LINK) {
        return `https://vimeo.com/${code}`;

    } else if (output === RenderSnippetAs.HTML_LINK) {
        return (
            <a
                href={`https://vimeo.com/${code}`}
                rel="noopener nofollow"
                target="_blank"
            >
                {`https://vimeo.com/${code}`}
            </a>
        );

    } else if (output === RenderSnippetAs.HTML_IFRAME) {
        const src = `https://player.vimeo.com/video/${code}`;
        return (
            <iframe
                frameBorder="0"
                height={height}
                src={src}
                width={width}
                className={className}
            />
        );

    } else {
        return null;
    }
};

export const constructIframe = (src: string, height: number | null, output: RenderSnippetAs = RenderSnippetAs.HTML_IFRAME, className?: string) => {
    if (output === RenderSnippetAs.PLAIN_LINK) {
        return src;

    } else if (output === RenderSnippetAs.HTML_LINK) {
        return (
            <a
                href={src}
                rel="noopener nofollow"
                target="_blank"
            >
                {src}
            </a>
        );

    } else if (output === RenderSnippetAs.HTML_IFRAME) {
        return (
            <iframe
                style={{ height: height || 'inherit' }}
                className={className}
                frameBorder="0"
                height={height}
                src={src}
            />
        );

    } else {
        return null;
    }
};

export const constructYoutube = (code: string, width: number, height: number, output: RenderSnippetAs = RenderSnippetAs.HTML_IFRAME, className?: string) => {
    if (output === RenderSnippetAs.PLAIN_LINK) {
        return `https://www.youtube.com/watch?v=${code}`;

    } else if (output === RenderSnippetAs.HTML_LINK) {
        return (
            <a
                href={`https://www.youtube.com/watch?v=${code}`}
                rel="noopener nofollow"
                target="_blank"
            >
                {`https://www.youtube.com/watch?v=${code}`}
            </a>
        );

    } else if (output === RenderSnippetAs.HTML_IFRAME) {
        const src = `https://www.youtube.com/embed/${code}`;
        return (
            <iframe
                width={width}
                height={height}
                src={src}
                frameBorder="0"
                allowFullScreen
                className={className}
            />
        );
    } else {
        return null;
    }
};

export const constructImage = (
    photos: PhotoEntity[], cd: string, align: string, size: string, version: string,
    title: string, avatar: boolean, hasResponsiveImages: boolean, forceEagerImages: boolean = false,
) => {
    const photo = photos.find((p) => p.code === cd);
    if (!photo) {
        return [];
    }

    const vs = size === 'small' ? 's246x246' :
               size === 'medium' ? 's369x369' :
               size === 'large' ? 's492x492' :
               size === 'full' ? 's1600x1600' : (version || 's492x492');

    if (hasResponsiveImages) {
        return avatar ? (
            <span
                style={{float: 'right', display: 'inline', margin: 5}}
            >
                <ImgResponsive
                    photo={photo}
                    photoVersion={vs}
                    alt={title ? title : undefined}
                    lazy={forceEagerImages ? false : undefined}
                />
            </span>
        ) : (
            <ImgResponsive
                photo={photo}
                photoVersion={vs}
                alt={title ? title : undefined}
                lazy={forceEagerImages ? false : undefined}
            />
        );

    } else {
        let style;

        if (align === 'center') {
            style = {
                display: 'block',
                margin: '0 auto',
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
            };
        }

        if (align === 'left') {
            style = {
                float: 'left',
                marginRight: 15,
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
            };
        }

        if (!(align === 'center' || align === 'left') || avatar) {
            style = {
                float: 'right',
                marginLeft: 15,
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
            };
        }

        return (
            <Img
                src={photoUrl(photo, vs)}
                style={style}
                width={photo.width}
                height={photo.height}
                title={title ? title : null}
                alt={title ? title : undefined}
                lazy={forceEagerImages ? false : undefined}
            />
        );
    }
};

export const largestAvailablePhotoVersionOfWikiArticle = (photo: PhotoEntity) =>
    photo.versions.includes('s1600x1600') ? 's1600x1600' :
        photo.versions.includes('s738x738') ? 's738x738' :
            photo.versions.includes('s492x492') ? 's492x492' : photo.versions[0];
