import { ACTION_TO_ADD_COMMENT, ARE_YOU_SURE_YOU_WANT_TO_DELETE_COMMENT } from 'mk/autogenerated/translations/Comments.sagas.64068f48bbe2b0de0ea9ab84ed28be65'
import { isAuthenticatedAs } from 'mk/bazaar/common/userUtils';
import {
    blogs_api_create_comment_url,
    blogs_api_delete_comment_url,
    blogs_api_get_comments_url,
} from 'mk/urls/functions';
import {
    commentsFetchApi,
    commentCreateApi,
    commentDeleteApi,
    commentDeleteCountinueWithReason,
    CommentsFetchNormalizedResponse,
    CommentsFetchTriggerAction,
    CommentCreateNormalizedResponse,
    CommentCreateTriggerAction,
    CommentCreateUnauthenticatedTriggerAction,
    CommentDeleteCountinueWithReasonAction,
    CommentDeleteNormalizedResponse,
    CommentDeleteTriggerAction,
    CommentReplyAction,
    COMMENT_CREATE_TRIGGER,
    COMMENT_CREATE_UNAUTHENTICATED_TRIGGER,
    COMMENT_DELETE_CONTINUE_WITH_REASON,
    COMMENT_DELETE_TRIGGER,
    COMMENT_REPLY,
    COMMENTS_FETCH_TRIGGER,
} from 'mk2/apps/blogs/containers/Comments/Comments.actions';
import { COMMENT_TEXTAREA_FORM_NAME } from 'mk2/apps/blogs/helpers';
import { ComplaintEntity, ComplaintSchema } from 'mk2/containers/Complaint/Complaint.schemas';
import { deletionCleanup, deletionShowForm, deletionStopSubmit } from 'mk2/containers/Deletion/Deletion.actions';
import { DeletionTargetType } from 'mk2/containers/Deletion/Deletion.schemas';
import { DeletionFormData } from 'mk2/containers/Deletion/DeletionForm';
import { toolbarCloseAll } from 'mk2/containers/Toolbar/Toolbar.actions';
import { handleXHRPostErrorSaga, normalizeError, XHRAction, XHRFormError, XHRPostError } from 'mk2/helpers/api';
import { handleXHRFormErrorSaga, handleXHRFormSuccessSaga } from 'mk2/helpers/form';
import { PhotoUploadStatus } from 'mk2/helpers/form.reducers';
import { getLogger } from 'mk2/logger';
import { getRequestUser, AppState } from 'mk2/reducers';
import { checkIfUserIsLoggedIn } from 'mk2/sagas/auth';
import {
    BlogPostEntity,
    BlogPostSchema,
    CommentEntity,
    CommentParent,
    CommentSchema,
} from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import { JamNotificationAction, JAM_NOTIFICATION } from 'mk2/services/jam.actions';
import { normalize } from 'normalizr';
import { change, formValueSelector, startSubmit } from 'redux-form';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const logger = getLogger('blogs/Comments.sagas');

interface ApiResponseComments {
    body: {
        canAdd: boolean;
        comments: CommentEntity[];
        complaints: ComplaintEntity;
        hasHiddenText: boolean;
    };
}

function* fetchComments({ commentParent, xhr }: CommentsFetchTriggerAction & XHRAction) {
    yield fetchShared(commentParent, xhr);
}

function* fetchComments2({ channel, xhr }: JamNotificationAction & XHRAction) {
    const splitted = channel.split(':');
    const commentParent: CommentParent = {model: splitted[1], id: parseInt(splitted[2], 10)};
    yield fetchShared(commentParent, xhr);
}

function* fetchShared(commentParent, xhr) {
    yield put(commentsFetchApi.request(commentParent));

    try {
        const response: ApiResponseComments = yield call(
            () => xhr.get(blogs_api_get_comments_url(commentParent.model, commentParent.id)),
        );
        const normalizedResponse: CommentsFetchNormalizedResponse = normalize(response.body, {
            comments: [CommentSchema],
            complaints: [ComplaintSchema],
        });

        yield put(commentsFetchApi.success(commentParent, normalizedResponse));
    } catch (error) {
        logger.error(error);
        yield put(commentsFetchApi.failure(commentParent, normalizeError(error)));
    }
}

interface DeleteCommentApiResponse {
    body: {
        comment: CommentEntity;
    };
}

function* deleteComment({ commentParent, commentId, xhr }: CommentDeleteTriggerAction & XHRAction) {
    const requestUser = yield select(getRequestUser);
    const comment: CommentEntity = yield select((state: AppState) => getDenormalizedEntity<CommentEntity>(state, CommentSchema, commentId));
    let commentParentAuthor = null;

    const post: BlogPostEntity = yield select((state: AppState) => getDenormalizedEntity<BlogPostEntity>(state, BlogPostSchema, comment.postId));
    commentParentAuthor = post.author;

    const isCommentAuthor = isAuthenticatedAs(requestUser, comment.authorUser);
    const isPostOwner = commentParentAuthor && isAuthenticatedAs(requestUser, commentParentAuthor);

    if (isCommentAuthor || isPostOwner) {
        if (!window.confirm(ARE_YOU_SURE_YOU_WANT_TO_DELETE_COMMENT)) {
            return;
        }
        yield put(commentDeleteCountinueWithReason(commentParent, commentId));
    } else {
        yield put(deletionShowForm(
            DeletionTargetType.PhotoblogComment,
            commentId,
            (deletion: DeletionFormData) =>
                commentDeleteCountinueWithReason(commentParent, commentId, deletion.deletionComment),
        ));
    }
}

function* deleteCommentWithReason({commentParent, commentId, deletionReason, xhr}: CommentDeleteCountinueWithReasonAction & XHRAction) {
    yield put(commentDeleteApi.request(commentParent, commentId));

    try {
        const response: DeleteCommentApiResponse = yield call(
            () => xhr.post(blogs_api_delete_comment_url(), {commentId, deletionReason}),
        );
        const normalizedResponse: CommentDeleteNormalizedResponse = normalize(response.body, {
            comment: CommentSchema,
        });

        yield put(deletionCleanup(DeletionTargetType.PhotoblogComment, commentId));
        yield put(commentDeleteApi.success(commentParent, commentId, normalizedResponse));
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(deletionStopSubmit(DeletionTargetType.PhotoblogComment, commentId));
        yield put(commentDeleteApi.failure(commentParent, commentId, normalizeError(error)));
    }
}

interface ApiResponseCreateComment {
    body: {
        comment: CommentEntity,
        targetPostCommercialDetails: {
            /**
             * keep format in sync with export.py
             */
            relatedToDaysWith: string[],
            relatedToAmbassadorOfBrands: number[],
            relatedToTesting: string[],
        };
    };
}

function* createComment({ commentFd, commentParent, xhr }: CommentCreateTriggerAction & XHRAction) {
    // Start submit
    yield put(startSubmit(COMMENT_TEXTAREA_FORM_NAME(commentParent)));

    // close toolbar, e.g. photos might be opened
    yield put(toolbarCloseAll(COMMENT_TEXTAREA_FORM_NAME(commentParent)));

    yield put(commentCreateApi.request(commentParent));
    try {
        const response: ApiResponseCreateComment = yield call(() => xhr.post(blogs_api_create_comment_url(commentParent.model, commentParent.id), {
            ...commentFd,
            text: commentFd.text,
            photos: commentFd.photos ? commentFd.photos.filter((photo) => photo.status === PhotoUploadStatus.SUCCESS) : [],
            sticker: commentFd.sticker || null,
            asAdmin: commentFd.asAdmin || false,
        }));
        const normalizedResponse: CommentCreateNormalizedResponse = normalize(response.body, {
            comment: CommentSchema,
        });

        yield handleXHRFormSuccessSaga(COMMENT_TEXTAREA_FORM_NAME(commentParent));
        yield put(commentCreateApi.success(commentParent, normalizedResponse));
    } catch (error) {
        yield handleXHRFormErrorSaga(COMMENT_TEXTAREA_FORM_NAME(commentParent), error as XHRFormError, logger);
        yield put(commentCreateApi.failure(commentParent, normalizeError(error)));
    }
}

function* commentReply({ commentParent, username, textareaElem }: CommentReplyAction) {
    const formName = COMMENT_TEXTAREA_FORM_NAME(commentParent);
    const text = (yield select(formValueSelector(formName), 'text')) ?? '';
    const mention = username ? '@' + username + ' ' : '';
    const value = text + (text && mention ? '\n' : '') + mention;
    // Change form
    yield put(change(formName, 'text', value));
    textareaElem.focus();
}

function* createCommentUnauthenticated({ commentParent }: CommentCreateUnauthenticatedTriggerAction) {
    // this saga runs only in case the user is not logged in
    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_ADD_COMMENT))) {
        return;
    }
}

export default function* root() {
    yield all([
        takeLatest(COMMENTS_FETCH_TRIGGER, fetchComments),
        takeEvery(COMMENT_DELETE_TRIGGER, deleteComment),
        takeEvery(COMMENT_DELETE_CONTINUE_WITH_REASON, deleteCommentWithReason),
        takeEvery(COMMENT_CREATE_TRIGGER, createComment),
        takeEvery(COMMENT_CREATE_UNAUTHENTICATED_TRIGGER, createCommentUnauthenticated),
        takeLatest(COMMENT_REPLY, commentReply),
        takeLatest(
            (action: JamNotificationAction) => action.type === JAM_NOTIFICATION && action.channel.startsWith('comment:'),
            fetchComments2,
        ),
    ]);
}
