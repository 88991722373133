// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ALBUM_HAS_BEEN_DELETED = "Album bylo smaz\u00e1no.";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALBUM = "Opravdu chce\u0161 vymazat album?";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_ARTICLE = "Opravdu chce\u0161 vymazat \u010dl\u00e1nek?";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHARE = "Opravdu chce\u0161 smazat sd\u00edlen\u00fd p\u0159\u00edsp\u011bvek?";
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHORT_MESSAGE = "Opravdu chce\u0161 vymazat kr\u00e1tkou zpr\u00e1vu?";
export const ARTICLE_HAS_BEEN_DELETED = "\u010cl\u00e1nek byl vymaz\u00e1n.";
export const BLOGS_IGNORE_POSTS_FROM_USER_username_link_GROWL = "P\u0159\u00edsp\u011bvky od %(username)s se nebudou zobrazovat. %(link)s.";
export const EXPLAIN_POST_DELETION = "Zadej bli\u017e\u0161\u00ed d\u016fvod:";
export const LIST_OF_IGNORED_USERS = "Seznam ignorovan\u00fdch u\u017eivatel\u016f";
export const SHARE_HAS_BEEN_DELETED = "Sd\u00edlen\u00fd p\u0159\u00edsp\u011bvek byl smaz\u00e1n.";
export const SHORT_MESSAGE_HAS_BEEN_DELETED = "Kr\u00e1tk\u00e1 zpr\u00e1va byla smazan\u00e1.";
export const THE_POST_WAS_REMOVED_FROM_FEED = "P\u0159\u00edsp\u011bvek byl odobran\u00fd z feedu.";
export const THE_POST_WAS_RETURNED_TO_FEED = "P\u0159\u00edsp\u011bvek byl vr\u00e1ten\u00fd do feedu.";
