/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

export const common_context_pattern = "/api/common/context/";
export const common_api_pageheaders_pattern = "/api/common/pageheader/";
export const common_api_feed_menu_pattern = "/api/common/feed-menu/";
export const common_api_redirect_to_eshop_pattern = "/api/common/eshop/:slug([a-z-\\d]+)/";
export const common_api_basic_redirect_to_eshop_pattern = "/api/common/basic/eshop/:slug([a-z-\\d]+)/";
export const common_api_prepare_superset_funnel_views_pattern = "/api/common/prepare-superset-funnel-views/";
export const i18n_list_pattern = "/adminclub/i18n/";
export const star_api_star_pattern = "/api/star/star/:object_type([a-z\\.]+)/:object_id([\\d]+)/";
export const logout_pattern = "/logout/";
export const users_must_auth_pattern = "/users/mustauth/:url(.+)/";
export const users_api_search_users_pattern = "/api/users/search_users/";
export const users_api_pregnancy_newsletter_subscribe_pattern = "/api/users/subscribe/pregnancy/";
export const users_api_pregnancy_calculator_load_pattern = "/api/users/pregnancy-calculator/load/";
export const users_api_pregnancy_calculator_submit_pattern = "/api/users/pregnancy-calculator/submit/";
export const users_api_webpush_subscribe_pattern = "/api/users/webpush/subscribe/";
export const users_api_webpush_unsubscribe_pattern = "/api/users/webpush/unsubscribe/";
export const users_api_webpush_disable_pattern = "/api/users/webpush/disable/";
export const users_api_auth_login_pattern = "/api/auth/login/";
export const users_api_auth_login_load_pattern = "/api/auth/login/load/";
export const users_api_auth_signup_load_pattern = "/api/auth/signup/load/";
export const users_api_auth_signup_pattern = "/api/auth/signup/";
export const users_api_auth_validate_inputs_pattern = "/api/auth/validate_registration_inputs/";
export const users_api_email_password_reset_pattern = "/api/password-reset/email/";
export const users_api_password_reset_verify_token_pattern = "/api/password-reset/verify-token/";
export const users_api_password_reset_set_new_pattern = "/api/password-reset/set-new/";
export const admins_api_complaint_pattern = "/api/admins/complaint/:object_type([a-z\\-]+)/:object_id([\\d]+)/";
export const admins_api_complaints_delete_pattern = "/api/admins/complaint/:object_type([a-z\\-]+)/:object_id([\\d]+)/delete/";
export const forum_api_post_get_pattern = "/api/forum/post/get/:post_id(\\d+)/";
export const forum_api_post_create_pattern = "/api/forum/post/new/";
export const forum_api_post_save_pattern = "/api/forum/post/save/";
export const forum_api_delete_post_pattern = "/api/forum/post/delete/:post_id(\\d+)/";
export const forum_api_post_pin_pattern = "/api/forum/post/pin/:post_id(\\d+)/";
export const forum_api_post_unpin_pattern = "/api/forum/post/unpin/:post_id(\\d+)/";
export const forum_api_topic_create_pattern = "/api/forum/topic/new/";
export const forum_api_topic_save_pattern = "/api/forum/topic/save/";
export const forum_api_topic_subscribe_pattern = "/api/forum/topic/subscribe/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_topic_unsubscribe_pattern = "/api/forum/topic/unsubscribe/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_topic_set_as_favorite_pattern = "/api/forum/topic/set-as-favorite/";
export const forum_api_get_topic_info_pattern = "/api/forum/topic/info/";
export const forum_api_delete_topic_pattern = "/api/forum/topic/delete/:topic_slug([a-z-\\d]+)/";
export const forum_api_topic_moderator_invite_pattern = "/api/forum/topic/moderator/invite/";
export const forum_api_topic_moderator_remove_pattern = "/api/forum/topic/moderator/remove/";
export const forum_api_topic_moderator_invitation_pattern = "/api/forum/topic/moderator/invitation/";
export const forum_api_topic_moderator_accept_invitation_pattern = "/api/forum/topic/moderator/invitation/accept/";
export const forum_api_topic_moderator_decline_invitation_pattern = "/api/forum/topic/moderator/invitation/decline/";
export const forum_api_contest_create_pattern = "/api/forum/contest/new/";
export const forum_api_contest_save_pattern = "/api/forum/contest/save/";
export const forum_api_contest_get_pattern = "/api/forum/contest/get/:contest_id(\\d+)/";
export const forum_api_counselling_question_create_pattern = "/api/forum/counselling/new/";
export const forum_api_counselling_question_reject_pattern = "/api/forum/counselling/reject/";
export const forum_api_get_counselling_data_pattern = "/api/forum/counselling/:topic_slug([a-z-\\d]+)/";
export const forum_api_get_min_counselling_data_pattern = "/api/forum/counselling/min/:topic_slug([a-z-\\d]+)/";
export const forum_api_get_categories_pattern = "/api/forum/categories/";
export const forum_api_topics_anonymous_pattern = "/api/forum/topics/:category_slug([a-z-]+)/";
export const forum_api_topics_pattern = "/api/forum/topics/:category_slug([a-z-]+)/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_get_topic_pattern = "/api/forum/category/:topic_slug([a-z-\\d]+)/";
export const forum_api_common_place_topics_pattern = "/api/forum/common-place-topics/";
export const forum_api_list_of_forums_anonymous_pattern = "/api/forum/list-of-forums/";
export const forum_api_list_of_forums_pattern = "/api/forum/list-of-forums/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_feed_pattern = "/api/forum/feed/new-feed/";
export const forum_api_feed_newest_pattern = "/api/forum/feed/newest/";
export const forum_api_feed_updated_pattern = "/api/forum/feed/updated/";
export const forum_api_feed_liked_pattern = "/api/forum/feed/liked/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_feed_commented_pattern = "/api/forum/feed/commented/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_my_activity_feed_pattern = "/api/forum/feed/my_activity/:username([0-9a-z_\\.\\-]+)/";
export const forum_api_feed_reported_pattern = "/api/forum/feed/reported/:topic_slug([a-z-\\d]+)/";
export const forum_api_post_likers_pattern = "/api/forum/likers/:topic_slug([a-z-\\d]+)/:post_slug([a-z-\\d\\._]+)/";
export const forum_api_post_comment_likers_pattern = "/api/forum/likers/:topic_slug([a-z-\\d]+)/:post_slug([a-z-\\d\\._]+)/:comment_id(\\d+)/";
export const forum_api_archive_campaigns_pattern = "/api/forum/archive_campaigns/";
export const forum_api_post_detail_pattern = "/api/forum/detail/:topic_slug([a-z-\\d]+)/:post_slug([a-z-\\d\\._]+)/";
export const forum_api_comment_get_pattern = "/api/forum/comment/get/:comment_id(\\d+)/";
export const forum_api_comment_create_pattern = "/api/forum/comment/new/";
export const forum_api_comment_save_pattern = "/api/forum/comment/save/";
export const forum_api_delete_comment_pattern = "/api/forum/comments/delete/:comment_id(\\d+)/";
export const forum_api_move_up_comment_pattern = "/api/forum/comments/moveup/:comment_id(\\d+)/";
export const forum_api_read_pattern = "/api/forum/read/:feed([a-zA-Z_0-9]+)/";
export const forum_api_search_pattern = "/api/forum/search/";
export const forum_api_feed_untrustworthy_user_pattern = "/api/forum/untrustworthy-user/:username([0-9a-z_\\.\\-]+)/";
export const counsellings_api_home_pattern = "/api/ask-an-expert/home/";
export const testings_api_testing_get_pattern = "/api/testings/detail/:testing_hashtag([a-z\\d#_-]+)/";
export const testings_api_testing_edit_get_pattern = "/api/testings/edit_get/:testing_hashtag([a-z\\d#_-]+)/";
export const testings_api_testing_edit_save_pattern = "/api/testings/edit_save/:testing_id(\\d+)/";
export const contests_api_home_pattern = "/api/contests/home/";
export const contests_api_set_following_pattern = "/api/contests/set_following/";
export const system_api_photo_upload_pattern = "/api/system/photo/upload/";
export const photo_upload_pattern = "/system/upload/:photo_type_name([a-z-_]+)/";
export const mail_api_mailbox_pattern = "/api/mail/:username([0-9a-z_\\.\\-]+)/:folder(inbox|outbox|starred)/";
export const mail_api_message_pattern = "/api/mail/mail/:id([\\d]+)/";
export const mail_api_read_pattern = "/api/mail/mail/:id([\\d]+)/read/";
export const mail_api_delete_pattern = "/api/mail/delete/";
export const mail_api_undelete_pattern = "/api/mail/undelete/";
export const mail_api_send_pattern = "/api/mail/send/";
export const ads_api_v1_mailing_campaign_sendme_pattern = "/api/bo/v1/mailing_campaign/sendme/";
export const ads_generate_pampers_coupon_pattern = "/api/ads/generate-pampers-coupon/";
export const bazaar_api_v2_suggest_category_pattern = "/api/market/v2/products/suggest_category/";
export const bazaar_api_v2_search_suggest_pattern = "/api/market/v2/suggest/";
export const bazaar_api_v3_reviews_pattern = "/api/market/v3/reviews/seller/:username([0-9a-z_\\.\\-]+)/:role(seller|buyer)/";
export const bazaar_api_v3_reviews_read_pattern = "/api/market/v3/reviews/seller/:username([0-9a-z_\\.\\-]+)/:role(seller|buyer)/read/";
export const bazaar_api_v3_reviews_new_pattern = "/api/market/v3/reviews/:buyer_id([\\d]+)/new/";
export const bazaar_api_v3_reviews_delete_pattern = "/api/market/v3/reviews/:id([0-9]+)/delete/";
export const bazaar_api_v3_reviews_reply_add_pattern = "/api/market/v3/reviews/:id([0-9]+)/reply/add/";
export const bazaar_api_v3_reviews_reply_delete_pattern = "/api/market/v3/reviews/:id([0-9]+)/reply/delete/";
export const bazaar_api_v3_sellers_liked_pattern = "/api/market/v3/sellers/liked/";
export const bazaar_api_v3_sellers_clear_last_created_product_pattern = "/api/market/v3/sellers/clear-last-created-product/";
export const bazaar_api_v3_seller_pattern = "/api/market/v3/seller/:username([0-9a-z_\\.\\-]+)/";
export const bazaar_api_v3_seller_products_pattern = "/api/market/v3/seller/:username([0-9a-z_\\.\\-]+)/products/";
export const bazaar_api_v3_seller_terms_save_pattern = "/api/market/v3/seller/:bazaar_id([\\d]+)/terms/";
export const bazaar_api_v3_seller_set_show_phone_pattern = "/api/market/v3/seller/:user_id([0-9]+)/set_show_seller_phone/";
export const bazaar_api_v3_seller_repost_all_products_pattern = "/api/market/v3/seller/:username([0-9a-z_\\.\\-]+)/repost_all_products/";
export const bazaar_api_v3_transactions_pattern = "/api/market/v3/transactions/:username([0-9a-z_\\.\\-]+)/";
export const bazaar_api_v3_transactions_save_notes_pattern = "/api/market/v3/transactions/:username([0-9a-z_\\.\\-]+)/:buyer_id([\\d]+)/notes/";
export const bazaar_api_v3_transactions_messages_pattern = "/api/market/v3/transactions/:username([0-9a-z_\\.\\-]+)/:buyer_id([\\d]+)/messages/";
export const bazaar_api_v3_buyers_remove_pattern = "/api/market/v3/buyers/:buyer_id([\\d]+)/remove/";
export const bazaar_api_v3_buyers_restore_pattern = "/api/market/v3/buyers/:buyer_id([\\d]+)/restore/";
export const bazaar_api_v3_products_pattern = "/api/market/v3/products/";
export const bazaar_api_v3_products_create_pattern = "/api/market/v3/products/create/";
export const bazaar_api_v3_products_delete_pattern = "/api/market/v3/products/delete/";
export const bazaar_api_v3_products_activate_pattern = "/api/market/v3/products/activate/";
export const bazaar_api_v3_products_deactivate_pattern = "/api/market/v3/products/deactivate/";
export const bazaar_api_v3_products_repost_pattern = "/api/market/v3/products/repost/";
export const bazaar_api_v3_product_load_pattern = "/api/market/v3/products/:product_id([\\d]+)/load/";
export const bazaar_api_v3_product_edit_pattern = "/api/market/v3/products/:product_id([\\d]+)/edit/";
export const bazaar_api_v3_product_delete_pattern = "/api/market/v3/products/:product_id([\\d]+)/delete/";
export const bazaar_api_v3_product_activate_pattern = "/api/market/v3/products/:product_id([\\d]+)/activate/";
export const bazaar_api_v3_product_deactivate_pattern = "/api/market/v3/products/:product_id([\\d]+)/deactivate/";
export const bazaar_api_v3_product_ok_pattern = "/api/market/v3/products/:product_id([\\d]+)/ok/";
export const bazaar_api_v3_product_undelete_pattern = "/api/market/v3/products/:product_id([\\d]+)/undelete/";
export const bazaar_api_v3_product_view_pattern = "/api/market/v3/products/:product_id([\\d]+)/view/";
export const bazaar_api_v3_product_related_products_pattern = "/api/market/v3/products/:product_slug([0-9a-z\\d_/\\-]+)/related-products/";
export const bazaar_api_v3_product_checkout_info_pattern = "/api/market/v3/products/:product_slug([0-9a-z\\d_/\\-]+)/checkout/";
export const bazaar_api_v3_product_checkout_fees_pattern = "/api/market/v3/products/:product_slug([0-9a-z\\d_/\\-]+)/checkout/fees/";
export const bazaar_api_v3_product_reserve_pattern = "/api/market/v3/products/:product_slug([0-9a-z\\d_/\\-]+)/checkout/reserve/";
export const bazaar_api_v3_change_status_pattern = "/api/market/v3/products/checkout/change/";
export const bazaar_api_v3_save_problem_pattern = "/api/market/v3/products/save_problem/";
export const bazaar_api_v3_get_bazaar_order_info_pattern = "/api/market/v3/products/get_bazaar_order_info/";
export const bazaar_api_v3_bazaar_order_finish_pattern = "/api/market/v3/products/bazaar_order_finish/";
export const bazaar_api_v3_delete_order_product_pattern = "/api/market/v3/products/checkout/delete/";
export const bazaar_api_v3_get_order_label_pattern = "/api/market/v3/products/checkout/get_label/";
export const bazaar_api_v3_all_pickup_points_pattern = "/api/market/v3/map/pickup_points/";
export const bazaar_api_v3_dropoff_points_only_pattern = "/api/market/v3/map/dropoff_points_only/";
export const bazaar_api_v3_pickup_points_in_bounds_pattern = "/api/market/v3/map/pickup_points/bounds/";
export const bazaar_api_v3_product_detail_pattern = "/api/market/v3/products/:product_slug([0-9a-z\\d_/\\-]+)/";
export const bazaar_api_v3_messages_reply_pattern = "/api/market/v3/messages/:buyer_id([\\d]+)/reply/";
export const bazaar_api_v3_messages_new_message_pattern = "/api/market/v3/messages/:product_id([\\d]+)/message/";
export const bazaar_api_v3_messages_read_pattern = "/api/market/v3/messages/read/";
export const bazaar_api_v3_watchdog_subscribe_pattern = "/api/market/v3/watchdog/subscribe/";
export const bazaar_api_v3_watchdog_unsubscribe_pattern = "/api/market/v3/watchdog/unsubscribe/";
export const bazaar_api_v3_watchdog_list_pattern = "/api/market/v3/watchdog/list/";
export const bazaar_api_v3_watchdog_list_token_pattern = "/api/market/v3/watchdog/list/:token(.+:.+:.+)/";
export const bazaar_api_v3_watchdog_unsubscribe_multi_pattern = "/api/market/v3/watchdog/unsubscribe/multi/";
export const bazaar_api_v3_watchdog_unsubscribe_multi_token_pattern = "/api/market/v3/watchdog/unsubscribe/multi/:token([0-9a-zA-Z-_]+:[0-9a-zA-Z-_]+:[0-9a-zA-Z-_]+)/";
export const bazaar_api_v3_watchdog_subscribe_after_pause_pattern = "/api/market/v3/watchdog/subscribe-after-pause/";
export const bazaar_api_v3_watchdog_subscribe_after_pause_token_pattern = "/api/market/v3/watchdog/subscribe-after-pause/:token([0-9a-zA-Z-_]+:[0-9a-zA-Z-_]+:[0-9a-zA-Z-_]+)/";
export const bazaar_api_v3_wishlist_inactive_pattern = "/api/market/v3/wishlist/inactive/";
export const bazaar_api_v3_shopping_pattern = "/api/market/v3/shopping/:username([0-9a-z_\\.\\-]+)/";
export const bazaar_api_v3_shopping_archive_pattern = "/api/market/v3/shopping/:username([0-9a-z_\\.\\-]+)/archive/";
export const bazaar_api_v3_json_data_for_native_ads_pattern = "/api/market/v3/ad-data/";
export const bazaar_api_v3_hide_current_adding_stroller_review_announcement_pattern = "/api/market/v3/seller/:username([0-9a-z_\\.\\-]+)/hide-current-adding-stroller-review-announcement/";
export const bazaar_redirect_to_watchdog_pattern = "/api/common/watchdog/:watchdog_id([\\d]+)/";
export const bazaar_single_page_wishlist_category_pattern = "/market/wishlist/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/";
export const bazaar_single_page_wishlist_category_filters_pattern = "/market/wishlist/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/";
export const bazaar_single_page_seller_category_pattern = "/market/seller/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/";
export const bazaar_single_page_seller_category_page_pattern = "/market/seller/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:page(strana-\\d+)/";
export const bazaar_single_page_seller_category_filters_pattern = "/market/seller/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/";
export const bazaar_single_page_seller_category_filters_page_pattern = "/market/seller/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/:page(strana-\\d+)/";
export const bazaar_single_page_modify_product_pattern = "/market/edit/:category_slug([a-z\\d_\\-]+)/:hash([0-9a-z]+)/:title([0-9a-z\\d_\\-]+)/";
export const bazaar_single_page_my_bazaar_pattern = "/market/my_bazaar/:username([0-9a-z_\\.\\-]+)/";
export const bazaar_single_page_my_bazaar_widget_pattern = "/market/my_bazaar/:username([0-9a-z_\\.\\-]+)/widget/";
export const bazaar_single_page_my_bazaar_category_pattern = "/market/my_bazaar/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/";
export const bazaar_single_page_my_bazaar_category_page_pattern = "/market/my_bazaar/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:page(strana-\\d+)/";
export const bazaar_single_page_my_bazaar_category_filters_pattern = "/market/my_bazaar/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/";
export const bazaar_single_page_my_bazaar_category_filters_page_pattern = "/market/my_bazaar/:username([0-9a-z_\\.\\-]+)/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/:page(strana-\\d+)/";
export const bazaar_single_page_product_grid_category_pattern = "/market/:category_slug([a-z\\d_\\-]+)/";
export const bazaar_single_page_product_grid_category_page_pattern = "/market/:category_slug([a-z\\d_\\-]+)/:page(strana-\\d+)/";
export const bazaar_single_page_product_grid_category_filters_pattern = "/market/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/";
export const bazaar_single_page_product_grid_category_filters_page_pattern = "/market/:category_slug([a-z\\d_\\-]+)/:filters([\\w-]+)/:page(strana-\\d+)/";
export const bazaar_single_page_item_detail_pattern = "/market/:category_slug([a-z\\d_\\-]+)/:hash([0-9a-z]+)/:title([0-9a-z\\d_\\-]+)/";
export const bazaar_product_history_pattern = "/market2/product/:product_id([\\d]+)/history/";
export const bazaar_redirect_to_eshop_pattern = "/r/market/eshop/:product_id([\\d]+)/";
export const shop_reviews_widget_pattern = "/shops-sys/reviews-widget/:username([0-9a-z_\\.\\-]+)/";
export const market_admin_pattern = "/adminclub/market/";
export const photoblog_api_v1_flagged_counts_pattern = "/api/blogs/v1/flagged_counts/";
export const blogs_api_profile_detail_pattern = "/api/blogs/profile/";
export const blogs_api_profile_feed_get_pattern = "/api/blogs/profile/feed/get/";
export const blogs_api_profile_set_like_pattern = "/api/blogs/profile/set-like/";
export const blogs_api_profile_fetch_related_users_pattern = "/api/blogs/profile/fetch-related-users/";
export const blogs_api_profile_fetch_users_groups_pattern = "/api/blogs/profile/fetch-users-groups/";
export const blogs_api_profile_delete_avatar_pattern = "/api/blogs/profile/delete-avatar/";
export const blogs_api_profile_fetch_vendor_data_pattern = "/api/blogs/profile/fetch-vendor-data/";
export const blogs_api_profile_set_vendor_data_pattern = "/api/blogs/profile/set-vendor-data/:username([0-9a-z_\\.\\-]+)/";
export const blogs_api_create_blog_post_pattern = "/api/blogs/profile/add-post/";
export const blogs_api_feed_get_pattern = "/api/blogs/feed/:category([a-z\\-]+)/get/";
export const blogs_api_friends_feed_all_get_pattern = "/api/blogs/friends/feed/all/get/";
export const blogs_api_popular_feed_all_get_pattern = "/api/blogs/popular/feed/all/get/";
export const blogs_api_post_min_data_from_id_pattern = "/api/blogs/post/get/";
export const blogs_api_article_get_pattern = "/api/blogs/article/get/";
export const blogs_api_article_parse_pattern = "/api/blogs/article/parse/";
export const blogs_api_article_create_pattern = "/api/blogs/article/create/";
export const blogs_api_article_drafts_get_pattern = "/api/blogs/article/drafts/";
export const blogs_api_article_edit_pattern = "/api/blogs/article/save/";
export const blogs_api_article_opened_pattern = "/api/blogs/article/pb-article-opened/";
export const blogs_api_blog_entity_likers_pattern = "/api/blogs/likers/:entity_type([a-z\\-]+)/:entity_id(\\d+)/";
export const blogs_api_short_message_detail_pattern = "/api/blogs/short-message/detail/";
export const blogs_api_short_message_get_pattern = "/api/blogs/short-message/get/";
export const blogs_api_short_message_save_pattern = "/api/blogs/short-message/save/";
export const blogs_api_photo_detail_pattern = "/api/blogs/photo/detail/";
export const blogs_api_album_detail_pattern = "/api/blogs/album/detail/";
export const blogs_api_album_get_pattern = "/api/blogs/album/get/";
export const blogs_api_album_reorder_pattern = "/api/blogs/album/reorder/";
export const blogs_api_album_edit_pattern = "/api/blogs/album/edit/";
export const blogs_api_album_create_pattern = "/api/blogs/album/create/";
export const blogs_api_groups_pattern = "/api/blogs/groups/";
export const blogs_api_group_memberships_pattern = "/api/blogs/groups/memberships/";
export const blogs_api_fetch_new_groups_pattern = "/api/blogs/groups/fetch-new/";
export const blogs_api_group_create_pattern = "/api/blogs/groups/create/";
export const blogs_api_delete_image_pattern = "/api/blogs/groups/image/:image_id(\\d+)/delete/";
export const blogs_api_rotate_image_pattern = "/api/blogs/groups/image/:image_id(\\d+)/rotate/";
export const blogs_api_save_image_title_pattern = "/api/blogs/groups/image/:image_id(\\d+)/save-title/";
export const blogs_api_group_invitation_pattern = "/api/blogs/groups/invitations/:invitation_id(\\d+)/";
export const blogs_api_group_accept_invitation_pattern = "/api/blogs/groups/invitations/:invitation_id(\\d+)/accept/";
export const blogs_api_group_decline_invitation_pattern = "/api/blogs/groups/invitations/:invitation_id(\\d+)/decline/";
export const blogs_api_group_entity_likers_pattern = "/api/blogs/groups/likers/:entity_type([a-z\\-]+)/:entity_id(\\d+)/";
export const blogs_api_group_post_delete_pattern = "/api/blogs/groups/post/:post_id(\\d+)/delete/";
export const blogs_api_group_post_get_pattern = "/api/blogs/groups/post/:post_id(\\d+)/get/";
export const blogs_api_group_post_pin_pattern = "/api/blogs/groups/post/:post_id(\\d+)/pin/";
export const blogs_api_group_post_save_pattern = "/api/blogs/groups/post/:post_id(\\d+)/save/";
export const blogs_api_group_post_unpin_pattern = "/api/blogs/groups/post/:post_id(\\d+)/unpin/";
export const blogs_api_get_group_subscribed_feed_pattern = "/api/blogs/groups/subscribed/";
export const blogs_api_get_group_my_activity_feed_pattern = "/api/blogs/groups/my-activity/";
export const blogs_api_get_group_feed_pattern = "/api/blogs/groups/:group_id(\\d+)/";
export const blogs_api_guide_send_heart_pattern = "/api/blogs/groups/guide/send/heart/";
export const blogs_api_group_info_pattern = "/api/blogs/groups/:group_id(\\d+)/info/";
export const blogs_api_group_edit_get_pattern = "/api/blogs/groups/:group_id(\\d+)/edit-get/";
export const blogs_api_group_edit_save_pattern = "/api/blogs/groups/:group_id(\\d+)/edit-save/";
export const blogs_api_group_set_is_listed_private_pattern = "/api/blogs/groups/:group_id(\\d+)/set-is-listed-private/";
export const blogs_api_group_set_show_deleted_posts_pattern = "/api/blogs/groups/:group_id(\\d+)/set-show-deleted-posts/";
export const blogs_api_group_set_hide_posts_from_feed_pattern = "/api/blogs/groups/:group_id(\\d+)/set-hide-from-feed/";
export const blogs_api_group_ask_to_join_pattern = "/api/blogs/groups/:group_id(\\d+)/ask-to-join/";
export const blogs_api_group_join_pattern = "/api/blogs/groups/:group_id(\\d+)/join/";
export const blogs_api_group_leave_pattern = "/api/blogs/groups/:group_id(\\d+)/leave/";
export const blogs_api_group_block_pattern = "/api/blogs/groups/:group_id(\\d+)/block/";
export const blogs_api_group_set_as_favorite_pattern = "/api/blogs/groups/:group_id(\\d+)/set_as_favorite/";
export const blogs_api_group_invite_moderator_pattern = "/api/blogs/groups/:group_id(\\d+)/invite-moderator/";
export const blogs_api_group_remove_moderator_pattern = "/api/blogs/groups/:group_id(\\d+)/remove-moderator/:member_id(\\d+)/";
export const blogs_api_group_members_pattern = "/api/blogs/groups/:group_id(\\d+)/members/";
export const blogs_api_group_membership_requests_pattern = "/api/blogs/groups/:group_id(\\d+)/membership-requests/";
export const blogs_api_group_invited_members_pattern = "/api/blogs/groups/:group_id(\\d+)/invited-members/";
export const blogs_api_group_blocked_members_pattern = "/api/blogs/groups/:group_id(\\d+)/blocked-members/";
export const blogs_api_group_unblock_member_pattern = "/api/blogs/groups/:group_id(\\d+)/unblock-member/";
export const blogs_api_group_photos_pattern = "/api/blogs/groups/:group_id(\\d+)/photos/";
export const blogs_api_get_group_post_detail_pattern = "/api/blogs/groups/:group_id(\\d+)/post/:post_slug([a-z\\._\\d#-]+)/";
export const blogs_api_get_group_photo_detail_pattern = "/api/blogs/groups/:group_id(\\d+)/photo/:post_slug([a-z\\._\\d#-]+)/:image_id(\\d+)/";
export const blogs_api_group_create_post_pattern = "/api/blogs/groups/:group_id(\\d+)/add-post/";
export const blogs_api_group_invite_users_pattern = "/api/blogs/groups/:group_id(\\d+)/invite-users/";
export const blogs_api_get_user_default_post_visibility_pattern = "/api/blogs/post/get-user-default-post-visibility/";
export const blogs_api_set_displaying_at_feed_pattern = "/api/blogs/post/:post_id(\\d+)/set-displaying-at-feed/";
export const blogs_api_set_post_visibility_pattern = "/api/blogs/post/:post_id(\\d+)/set-visibility/";
export const blogs_api_get_shareable_post_pattern = "/api/blogs/post/:post_id(\\d+)/get-shareable-post/";
export const blogs_api_share_post_pattern = "/api/blogs/post/:post_id(\\d+)/share/";
export const blogs_api_ignore_user_pattern = "/api/blogs/user/:username([0-9a-z_\\.\\-]+)/ignore/";
export const blogs_api_get_comments_pattern = "/api/blogs/:model([a-z-\\d_]+)/:object_id([a-z-\\d]+)/comments/";
export const blogs_api_get_comments_by_ids_pattern = "/api/blogs/comments_by_ids/";
export const blogs_api_delete_comment_pattern = "/api/blogs/delete/comment/";
export const blogs_api_create_comment_pattern = "/api/blogs/create/comment/:model([a-z-\\d_]+)/:object_id([a-z-\\d]+)/";
export const blogs_api_edit_comment_pattern = "/api/blogs/edit/comment/:comment_id(\\d+)/";
export const photoblog_all_ignored_pattern = "/blogs-all-ignored/";
export const homepage_api_pattern = "/api/homepage/";
export const homepage_api_posts_pattern = "/api/homepage/posts/:page(\\d+)/";
export const adminclub_api_get_concepts_pattern = "/api/adminclub/concept/get_concepts/";
export const adminclub_api_get_all_concepts_pattern = "/api/adminclub/concept/concepts_all/";
export const adminclub_api_add_concept_pattern = "/api/adminclub/concepts/add_concept/";
export const adminclub_api_add_no_tag_pattern = "/api/adminclub/concepts/add_no_tag/";
export const adminclub_api_remove_concept_pattern = "/api/adminclub/concepts/remove_concept/";
export const adminclub_api_update_concept_position_pattern = "/api/adminclub/concepts/update_concept_position/";
export const adminclub_api_seo_forum_item_toggle_meta_actions_pattern = "/api/adminclub/forum/seo/lock/";
export const adminclub_api_seo_forum_item_save_pattern = "/api/adminclub/forum/seo/save/";
export const adminclub_api_seo_forum_get_items_pattern = "/api/adminclub/forum/seo/:purpose([a-z\\._\\d#-]+)/";
export const adminclub_api_seo_new_thread_ai_title_pattern = "/api/adminclub/forum/seo/new/ai_title/";
export const adminclub_api_seo_new_thread_ai_topic_pattern = "/api/adminclub/forum/seo/new/ai_topic/";
export const adminclub_api_wiki_fetch_article_vendor_relations_pattern = "/api/adminclub/wiki/article_vendor_relations/fetch/";
export const adminclub_api_wiki_add_article_vendor_relations_pattern = "/api/adminclub/wiki/article_vendor_relations/add/";
export const adminclub_api_wiki_remove_article_vendor_relations_pattern = "/api/adminclub/wiki/article_vendor_relations/remove/";
export const adminclub_api_wiki_fetch_review_category_for_create_pattern = "/api/adminclub/wiki/review-category/create/fetch/";
export const adminclub_api_wiki_fetch_review_category_for_edit_pattern = "/api/adminclub/wiki/review-category/edit/:article_id(\\d+)/fetch/";
export const adminclub_api_wiki_save_review_category_pattern = "/api/adminclub/wiki/review-category/create/save/";
export const adminclub_api_wiki_remove_review_category_pattern = "/api/adminclub/wiki/review-category/edit/:article_id(\\d+)/remove/";
export const adminclub_api_product_catalog_import_run_pattern = "/api/adminclub/product-catalogs/import-run/";
export const adminclub_api_product_catalog_imports_get_pattern = "/api/adminclub/product-catalog/imports-get/";
export const adminclub_api_xml_import_feeds_pattern = "/api/adminclub/xml-import/feeds/";
export const adminclub_api_xml_import_feeds_create_pattern = "/api/adminclub/xml-import/feeds/create/";
export const adminclub_api_xml_import_feeds_feed_pattern = "/api/adminclub/xml-import/feeds/:feed_id(\\d+)/";
export const adminclub_api_xml_import_feeds_edit_pattern = "/api/adminclub/xml-import/feeds/:feed_id(\\d+)/edit/";
export const adminclub_api_xml_import_feeds_feed_history_pattern = "/api/adminclub/xml-import/feeds/:feed_id(\\d+)/history/";
export const adminclub_api_xml_import_feeds_export_pattern = "/api/adminclub/xml-import/feeds/:feed_id(\\d+)/export/";
export const adminclub_api_xml_import_stats_pattern = "/api/adminclub/xml-import/stats/";
export const adminclub_api_xml_import_items_pattern = "/api/adminclub/xml-import/items/";
export const adminclub_api_xml_import_items_item_suggestions_pattern = "/api/adminclub/xml-import/items/:item_id(\\d+)/suggestions/";
export const adminclub_api_xml_import_items_item_ignore_pattern = "/api/adminclub/xml-import/items/:item_id(\\d+)/ignore/";
export const adminclub_api_xml_import_items_item_set_variant_pattern = "/api/adminclub/xml-import/items/:item_id(\\d+)/set-variant/";
export const adminclub_api_help_ticket_get_pattern = "/api/adminclub/help/ticket/:token([a-zA-Z\\d]+)/get/";
export const adminclub_api_help_ticket_add_message_pattern = "/api/adminclub/help/ticket/:token([a-zA-Z\\d]+)/";
export const adminclub_ips_pattern = "/adminclub/ips/";
export const adminclub_computers_pattern = "/adminclub/computers/";
export const adminclub_commenters_pattern = "/adminclub/commenters/";
export const adminclub_threads_pattern = "/adminclub/forum/";
export const adminclub_topics_pattern = "/adminclub/forum/topics/";
export const adminclub_user_notes_pattern = "/adminclub/user_notes/";
export const adminclub_users_pattern = "/adminclub/users/";
export const adminclub_bazaar_pattern = "/adminclub/bazaar/";
export const adminclub_bazaar_buyers_pattern = "/adminclub/bazaar-buyers/";
export const adminclub_bazaar_moderators_pattern = "/adminclub/bazaar-moderators/";
export const adminclub_bazaar_moderators_life_pattern = "/adminclub/bazaar-moderators-life/";
export const adminclub_notes_pattern = "/adminclub/notes/";
export const adminclub_permissions_pattern = "/adminclub/permissions/";
export const adminclub_keywords_pattern = "/adminclub/kwds/";
export const adminclub_admin_log_pattern = "/adminclub/log/";
export const adminclub_cms_pattern = "/adminclub/cms/";
export const adminclub_go_pattern = "/adminclub/go/";
export const adminclub_groups_pattern = "/adminclub/groups/";
export const adminclub_group_pattern = "/adminclub/group/:id([0-9]+)/";
export const adminclub_hacker_pattern = "/adminclub/hacker/";
export const adminclub_police_pattern = "/adminclub/police/";
export const adminclub_images_pattern = "/adminclub/images/";
export const adminclub_ip_communication_pattern = "/adminclub/ip-communication/";
export const adminclub_strollers_questions_pattern = "/adminclub/strollers_questions/";
export const adminclub_strollers_reviews_pattern = "/adminclub/strollers_reviews/";
export const adminclub_tickets_notes_pattern = "/adminclub/tickets/notes/";
export const adminclub_tickets_pattern = "/adminclub/tickets/";
export const adminclub_ticket_create_pattern = "/adminclub/ticket/create/";
export const adminclub_seorenaming_pattern = "/adminclub/seorenaming/";
export const adminclub_rookies_pattern = "/adminclub/rookies/";
export const adminclub_forum_topic_pattern = "/adminclub/forum-topic/:id([0-9]+)/";
export const adminclub_forum_post_pattern = "/adminclub/forum-post/:id([0-9]+)/";
export const adminclub_bazaar_product_pattern = "/adminclub/bazaar/product/:id([0-9]+)/";
export const adminclub_wiki_article_pattern = "/adminclub/wiki/article/:id([0-9]+)/";
export const adminclub_wiki_experiences_pattern = "/adminclub/wiki_experiences/";
export const adminclub_wiki_experiences_detail_pattern = "/adminclub/wiki_experiences/:id([0-9]+)/";
export const adminclub_counselling_questions_pattern = "/adminclub/counselling_questions/";
export const adminclub_homepage_feed_posts_pattern = "/adminclub/homepage_feed_posts/";
export const adminclub_homepage_feed_posts_generate_page_token_pattern = "/adminclub/homepage_feed_posts/generate_page_token/";
export const adminclub_homepage_post_remove_pattern = "/adminclub/ajax_homepage_post_remove/";
export const adminclub_blogs_post_pattern = "/adminclub/blogs/post/:id([0-9]+)/";
export const wall_api_mentions_pattern = "/api/wall/mentions/:username([0-9a-z_\\.\\-]+)/";
export const wall_api_set_follow_pattern = "/api/wall/follow/:username([0-9a-z_\\.\\-]+)/";
export const wall_api_thank_for_heart_pattern = "/api/wall/thank-for-heart/:username([0-9a-z_\\.\\-]+)/";
export const wall_api_read_heart_pattern = "/api/wall/read-heart/:username([0-9a-z_\\.\\-]+)/";
export const wall_api_mark_all_as_read_pattern = "/api/wall/mark-all-as-read/:username([0-9a-z_\\.\\-]+)/";
export const wall_read_pattern = "/r/mention/:mention_id([\\d]+)/";
export const dash_api_seo_performance_data_pattern = "/api/dash/seo-performance-data/";
export const dash_new_vs_churn_pattern = "/dash/new-vs-churn/";
export const dash_revenue_pattern = "/dash/revenue/";
export const dash_group_categories_pattern = "/dash/group-categories/";
export const dash_traffic_pattern = "/dash/traffic/";
export const dash_traffic_users_pattern = "/dash/traffic/users/";
export const dash_emails_pattern = "/dash/emails/";
export const dash_user_stats_pattern = "/dash/user-stats/";
export const dash_server_stats_pattern = "/dash/server-stats/";
export const dash_server_stats_data_pattern = "/dash/server-stats-data/";
export const dash_amb_reports_pattern = "/dash/amb-reports/";
export const dash_last_active_pattern = "/dash/lastactive/";
export const dash_calendar_pattern = "/dash/calendar/";
export const dash_dev_backups_pattern = "/dash/dev/backups/";
export const bo_api_v1_orders_get_orders_pattern = "/api/bo/v1/orders_get_orders/";
export const bo_api_v1_orders_graph_data_pattern = "/api/bo/v1/orders_graph_data/";
export const bo_api_v1_orders_signed_data_pattern = "/api/bo/v1/orders_signed_data/";
export const bo_api_v1_orders_agency_bonuses_pattern = "/api/bo/v1/orders_agency_bonuses/";
export const bo_api_orders_invoicing_pattern = "/api/bo/v1/orders_invoicing/";
export const bo_api_order_create_pattern = "/api/bo/order/new/";
export const bo_api_order_create_init_pattern = "/api/bo/order/new/init/";
export const bo_api_order_create_checkurl_pattern = "/api/bo/order/new/check_url/";
export const bo_api_order_edit_pattern = "/api/bo/order/edit/";
export const bo_api_order_get_pattern = "/api/bo/order/get/:order_id(\\d+)/";
export const bo_api_order_get_history_pattern = "/api/bo/order/get_history/:order_id(\\d+)/";
export const bo_api_order_delete_pattern = "/api/bo/order/delete/:order_id(\\d+)/";
export const bo_api_order_add_attachments_pattern = "/api/bo/order/add_attachments/";
export const bo_api_order_remove_attachment_pattern = "/api/bo/order/remove_attachment/";
export const bo_api_order_remove_invoice_pattern = "/api/bo/order/remove_invoice/";
export const bo_api_order_refresh_stats_pattern = "/api/bo/order/refresh_stats/";
export const bo_api_order_approve_plan_pattern = "/api/bo/order/approve_plan/";
export const bo_api_order_get_by_item_pattern = "/api/bo/order/get_by_item/:order_item_id(\\d+)/";
export const bo_api_order_create_lineitem_pattern = "/api/bo/order/create_lineitem/";
export const bo_api_order_save_pb_article_ref_pattern = "/api/bo/order/save_pb_article_ref/";
export const bo_api_order_save_wk_article_ref_pattern = "/api/bo/order/save_wk_article_ref/";
export const bo_api_order_save_wk_review_refs_pattern = "/api/bo/order/save_wk_review_refs/";
export const bo_api_order_save_article_report_pattern = "/api/bo/order/save_article_report/";
export const bo_api_order_save_tracked_hashtag_pattern = "/api/bo/order/save_hashtag/";
export const bo_api_testing_get_pattern = "/api/bo/testing/get/:testing_id(\\d+)/";
export const bo_api_testing_save_pattern = "/api/bo/testing/save/:testing_id(\\d+)/";
export const bo_api_testing_create_pattern = "/api/bo/testing/new/";
export const bo_api_v1_reports_get_report_pattern = "/api/bo/v1/reports_get_report/";
export const bo_api_v1_reports_get_ambassador_report_pattern = "/api/bo/v1/reports_get_ambassador_report/";
export const bo_api_v1_reports_get_ask_an_expert_report_pattern = "/api/bo/v1/reports_get_ask_an_expert_report/";
export const bo_api_v1_reports_get_testing_report_pattern = "/api/bo/v1/reports_get_testing_report/";
export const bo_api_v1_payments_card_pattern = "/api/bo/v1/payments/card/";
export const bo_api_v1_payments_sms_pattern = "/api/bo/v1/payment/sms/";
export const bo_api_v1_payments_status_pattern = "/api/bo/v1/payment/status/";
export const bo_api_v1_payment_success_pattern = "/api/bo/v1/payment/success/";
export const bo_api_v1_payment_failure_pattern = "/api/bo/v1/payment/failure/";
export const bo_sms_payments_pattern = "/bo/sms-payments/";
export const bo_invoices_pattern = "/bo/invoices/";
export const bo_bank_payments_pattern = "/bo/bank-payments/";
export const bo_card_payments_pattern = "/bo/card-payments/";
export const bo_customers_pattern = "/bo/customers/";
export const bo_agencies_pattern = "/bo/agencies/";
export const bo_counsellings_pattern = "/bo/counsellings/";
export const bo_generate_gtad_pattern = "/bo/generate/gtad/";
export const bo_mailing_campaigns_pattern = "/bo/mailing-campaigns/";
export const bo_invoice_create_pattern = "/bo/invoice/create/";
export const bo_invoice_file_for_user_pattern = "/bo/invoice/:invoice_number([\\d]+)/";
export const bo_proforma_invoice_file_for_user_pattern = "/bo/invoice/proforma/";
export const bo_tracked_hashtags_pattern = "/bo/tracked-hashtags/";
export const bo_traffic_pattern = "/bo/traffic/";
export const bo_contests_pattern = "/bo/contests/";
export const bo_testings_pattern = "/bo/testings/";
export const bo_ambassadors_pattern = "/bo/ambassadors/";
export const like_api_likers_pattern = "/api/like/likers/";
export const like_api_like_pattern = "/api/like/like/:object_type([a-z\\.-]+)/:object_id([\\d]+)/";
export const settings_api_profile_pattern = "/api/settings/profile/:username([0-9a-z_\\.\\-]+)/";
export const settings_api_profile_save_pattern = "/api/settings/profile/:username([0-9a-z_\\.\\-]+)/save/";
export const settings_api_kids_pattern = "/api/settings/kids/:username([0-9a-z_\\.\\-]+)/";
export const settings_api_account_pattern = "/api/settings/account/:username([0-9a-z_\\.\\-]+)/";
export const settings_api_account_save_pattern = "/api/settings/account/:username([0-9a-z_\\.\\-]+)/save/";
export const settings_api_cancel_account_pattern = "/api/settings/account/:username([0-9a-z_\\.\\-]+)/cancellation/";
export const settings_api_account_change_password_pattern = "/api/settings/account/:username([0-9a-z_\\.\\-]+)/change-password/";
export const settings_api_location_pattern = "/api/settings/location/:username([0-9a-z_\\.\\-]+)/";
export const settings_api_location_save_pattern = "/api/settings/location/:username([0-9a-z_\\.\\-]+)/save/";
export const settings_api_kid_get_pattern = "/api/settings/kid/:username([0-9a-z_\\.\\-]+)/get/";
export const settings_api_kid_create_pattern = "/api/settings/kid/:username([0-9a-z_\\.\\-]+)/new/";
export const settings_api_kid_save_pattern = "/api/settings/kid/:username([0-9a-z_\\.\\-]+)/save/";
export const settings_api_kid_delete_pattern = "/api/settings/kid/:username([0-9a-z_\\.\\-]+)/delete/";
export const settings_api_mail_notifications_save_pattern = "/api/settings/mail-notifications-get/:token([0-9a-z_\\.\\-]+)/";
export const settings_api_mail_notifications_pattern = "/api/settings/mail-notifications/";
export const hearts_api_messages_pattern = "/api/hearts/messages/";
export const hearts_api_send_pattern = "/api/hearts/send/";
export const cms_api_v1_get_items_pattern = "/api/cms/v1/items/";
export const faq_api_index_pattern = "/api/faq/index/";
export const faq_api_fetch_ask_question_pattern = "/api/faq/ask-question/fetch/";
export const faq_api_send_ask_question_pattern = "/api/faq/ask-question/send/";
export const wiki_api_add_experience_pattern = "/api/wiki/article/:slug([-\\w]+)/add-experience/submit/";
export const wiki_api_add_experience_with_undef_article_pattern = "/api/wiki/add-experience/submit/";
export const wiki_api_v2_get_article_pattern = "/api/wiki/v2/article/";
export const wiki_api_v2_load_article_previews_pattern = "/api/wiki/v2/article/load-article-previews/";
export const wiki_api_v2_load_recent_blog_articles_pattern = "/api/wiki/v2/article/load-recent-blog-articles/";
export const wiki_api_v2_load_more_of_review_category_pattern = "/api/wiki/v2/article/load-more-of-review-category/";
export const wiki_api_v2_save_article_fetch_pattern = "/api/wiki/v2/article/edit/:article_id([0-9]+)/fetch/";
export const wiki_api_v2_save_article_pattern = "/api/wiki/v2/article/edit/:article_id([0-9]+)/";
export const wiki_api_v2_create_article_fetch_pattern = "/api/wiki/v2/article/new/fetch/";
export const wiki_api_v2_create_article_pattern = "/api/wiki/v2/article/new/";
export const wiki_api_v2_search_pattern = "/api/wiki/v2/article/search/";
export const wiki_api_v2_article_opened_pattern = "/api/wiki/v2/article/wiki-article-opened/";
export const wiki_api_v2_save_venor_reply_on_experience_pattern = "/api/wiki/v2/article/reply_on_experience/:experience_id([0-9]+)/save/";
export const wiki_api_v2_delete_venor_reply_on_experience_pattern = "/api/wiki/v2/article/reply_on_experience/:experience_id([0-9]+)/delete/";
export const wiki_api_v2_set_ambassador_brand_pattern = "/api/wiki/v2/article/set-ambassador-brand/:article_id([0-9]+)/";
export const mailing_list_pattern = "/adminclub/emails/";
export const anltx_api_store_browser_event_pattern = "/api/anltx/store-event/";
export const anltx_api_ab_pattern = "/api/anltx/ab/";
export const plan_invoices_pattern = "/plan/invoices/:username([0-9a-z_\\.\\-]+)/";
export const plan_api_get_plus_data_pattern = "/api/plan/get-plus/";
export const plan_api_get_pro_data_pattern = "/api/plan/get-pro/";
export const plan_api_plan_for_load_data_pattern = "/api/plan/plan-for/:username([0-9a-z_\\.\\-]+)/";
export const plan_api_plan_for_tamper_plan_pattern = "/api/plan/plan-for/:username([0-9a-z_\\.\\-]+)/save/";
export const plan_api_plan_for_payment_cancel_pattern = "/api/plan/plan-for/:username([0-9a-z_\\.\\-]+)/payment-cancel/";
export const phone_api_verification_send_token_pattern = "/api/phone/verification/send_token/";
export const phone_api_verification_validate_token_pattern = "/api/phone/verification/validate_token/";
export const phone_api_verification_send_token_for_email_change_pattern = "/api/phone/verification/send_token_for_email_change/:username([0-9a-z_\\.\\-]+)/";
export const phone_api_verification_validate_token_for_email_change_pattern = "/api/phone/verification/validate_token_for_email_change/:username([0-9a-z_\\.\\-]+)/";
export const strollers_api_stroller_question_add_pattern = "/api/strollers/question/add/";
export const strollers_api_suggest_strollers2_pattern = "/api/strollers/suggest2/";
export const strollers_api_pattern_get_stroller_patterns_pattern = "/api/strollers/pattern/get_stroller_patterns/";
export const strollers_api_pattern_get_stroller_pattern_pattern = "/api/strollers/pattern/get_stroller_pattern/";
export const strollers_api_pattern_save_stroller_pattern_pattern = "/api/strollers/pattern/save_stroller_pattern/";
export const strollers_api_pattern_edit_stroller_pattern_pattern = "/api/strollers/pattern/edit_stroller_pattern/";
export const strollers_api_pattern_delete_stroller_pattern_pattern = "/api/strollers/pattern/delete_stroller_pattern/";
export const strollers_api_v2_comparison_load_pattern = "/api/strollers/v2/comparison/load/";
export const strollers_api_v2_comparison_toggle_pattern = "/api/strollers/v2/comparison/toggle/";
export const strollers_api_v2_comparison_remove_all_pattern = "/api/strollers/v2/comparison/remove-all/";
export const strollers_api_v2_comparison_store_ordering_pattern = "/api/strollers/v2/comparison/store-ordering/";
export const strollers_api_v2_home_pattern = "/api/strollers/v2/home/";
export const strollers_api_v2_get_brands_pattern = "/api/strollers/v2/brands/";
export const strollers_api_v2_chart_pattern = "/api/strollers/v2/chart/";
export const strollers_api_v2_get_strollers_pattern = "/api/strollers/v2/strollers/:slug([-\\w]+)/";
export const strollers_api_v2_merge_info_pattern = "/api/strollers/v2/merge/get/:stroller_id([\\d]+)/";
export const strollers_api_v2_merge_save_pattern = "/api/strollers/v2/merge/save/";
export const strollers_api_v2_stroller_detail_pattern = "/api/strollers/v2/stroller/get/:slug([-\\w]+)/";
export const strollers_api_v2_stroller_detail_variant_pattern = "/api/strollers/v2/stroller/get/:slug([-\\w]+)/:variant_id([\\d]+)/";
export const strollers_api_v2_stroller_load_more_similars_pattern = "/api/strollers/v2/stroller/get/:slug([-\\w]+)/more-similars/";
export const strollers_api_v2_stroller_delete_pattern = "/api/strollers/v2/stroller/delete/";
export const strollers_api_v2_get_stroller_rename_info_pattern = "/api/strollers/v2/stroller/rename/:stroller_id([\\d]+)/get/";
export const strollers_api_v2_stroller_rename_save_pattern = "/api/strollers/v2/stroller/rename/:stroller_id([\\d]+)/save/";
export const strollers_api_v2_get_stroller_product_photos_pattern = "/api/strollers/v2/stroller/product-photos/get/";
export const strollers_api_v2_search_strollers_pattern = "/api/strollers/v2/search/";
export const strollers_api_v2_search_strollers_facets_pattern = "/api/strollers/v2/search/facets/";
export const strollers_api_v2_user_strollers_get_user_stroller_pattern = "/api/strollers/v2/user/strollers/";
export const strollers_api_v2_user_strollers_add_user_stroller_pattern = "/api/strollers/v2/user/strollers/add/";
export const strollers_api_v2_user_strollers_remove_user_stroller_pattern = "/api/strollers/v2/user/strollers/remove/";
export const strollers_api_v2_reviews_get_pattern = "/api/strollers/v2/reviews/get/";
export const strollers_api_v2_example_reviews_get_pattern = "/api/strollers/v2/reviews/get_example/";
export const strollers_api_v2_reviews_add_review_find_product_pattern = "/api/strollers/v2/reviews/add-review/find-product/";
export const strollers_api_v2_reviews_add_review_create_pattern = "/api/strollers/v2/reviews/add-review/create/";
export const strollers_api_v2_reviews_photos_get_pattern = "/api/strollers/v2/reviews-photos/get/";
export const strollers_api_v2_photo_editor_get_pattern = "/api/strollers/v2/photo_editor/get/";
export const strollers_api_v2_photo_editor_set_pattern = "/api/strollers/v2/photo_editor/set/";
export const strollers_api_v2_photo_editor_delete_pattern = "/api/strollers/v2/photo_editor/delete/";
export const strollers_api_v2_photos_edit_get_pattern = "/api/strollers/v2/edit_photos/get/";
export const strollers_api_v2_photos_edit_save_pattern = "/api/strollers/v2/edit_photos/save/";
export const strollers_api_v2_photos_edit_delete_pattern = "/api/strollers/v2/edit_photos/delete/";
export const strollers_api_v2_upload_stroller_photo_pattern = "/api/strollers/v2/upload_stroller_photo/";
export const strollers_api_v2_get_blog_articles_pattern = "/api/strollers/v2/articles/:hashtag([-\\w]+)/";
export const strollers_api_v2_eshop_exit_pattern = "/api/strollers/v2/eshop-exit/:stroller_id([\\d]+)/:stroller_eshop_product_id([\\d]+)/";
export const strollers_api_v2_detail_edit_save_pattern = "/api/strollers/v2/detail/edit/save/:stroller_id([\\d]+)/";
export const strollers_api_v2_detail_edit_get_pattern = "/api/strollers/v2/detail/edit/get/:stroller_id([\\d]+)/";
export const ai_api_prompts_get_pattern = "/api/adminclub/prompts/get/";
export const ai_api_prompt_get_pattern = "/api/adminclub/prompt/get/:prompt_id([0-9]+)/";
export const ai_api_prompt_save_pattern = "/api/adminclub/prompt/save/:prompt_id([0-9]+)/";
