import {
    TIKTOK_UNABLE_TO_LOAD_TIKTOK_VIDEO,
    TIKTOK_VIEW_ON_TIKTOK,
} from 'mk/autogenerated/translations/TiktokEmbed.6dd1bf33ab0542bde71b0be0ab7d5e4d'
import { getLogger } from 'mk2/logger';
import React, { useCallback, useEffect, useState } from 'react';

const logger = getLogger('wiki.TiktokEmbed');

const loadTiktok = async (url) => {
    const response = await fetch(`https://www.tiktok.com/oembed?url=${encodeURIComponent(url)}`);
    if (!response.ok) {
        throw new Error(`Failed to fetch TikTok embed: ${response.statusText}`);
    }
    const data = await response.json();
    return data.html;
};


function reloadScript() {
    if (typeof window !== 'undefined') {
        const tiktoksOnPage = Array.from(
          document.querySelectorAll('blockquote.tiktok-embed'),
        );
        const scriptElement = document.getElementById('tiktokEmbedJs');
        const tiktokEmbed = (window as any).tiktokEmbed;

        if (typeof tiktokEmbed === 'undefined') {
            if (scriptElement) {
                scriptElement.remove();
            }
            const tiktokEmbedJs: HTMLScriptElement = document.createElement('script');
            tiktokEmbedJs.id = 'tiktokEmbedJs';
            tiktokEmbedJs.type = 'text/javascript';
            tiktokEmbedJs.async = true;
            tiktokEmbedJs.src = 'https://www.tiktok.com/embed.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tiktokEmbedJs, s);
        } else {
            if (tiktoksOnPage.length) {
                tiktokEmbed.lib.render(tiktoksOnPage);
            }
        }
    }
}

function sanitizeEmbedHtml(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const scriptElements = doc.querySelectorAll('script');
  scriptElements.forEach(script => script.remove());
  const elementsWithEventHandlers = doc.querySelectorAll('*[onclick], *[onload], *[onerror]');
  elementsWithEventHandlers.forEach(element => {
    element.removeAttribute('onclick');
    element.removeAttribute('onload');
  });
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc.body);
}

export const TiktokEmbed = ({ url }) => {
    const [embedHtml, setEmbedHtml] = useState(null);

    useEffect(() => {
        loadTiktok(url)
            .then((result) => {
                setEmbedHtml(sanitizeEmbedHtml(result));
                reloadScript();
            })
            .catch((error) => {
                logger.error(error);
                setEmbedHtml(`${TIKTOK_UNABLE_TO_LOAD_TIKTOK_VIDEO} <a href=${url} target=\"_blank\" rel=\"noopener nofollow\">${TIKTOK_VIEW_ON_TIKTOK}</a>`);
            });
    }, [url]);

    if (!embedHtml) {
        return <p>Loading...</p>;
    }

    return <div dangerouslySetInnerHTML={{ __html: embedHtml }} />;
};
