import cx from 'classnames';
import { autoFocusElement, AutoFocus } from 'mk2/helpers/form';
import React from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import styles from './EnhancedTextarea.mscss';

// public properties exposed via forms/TextareaField
export interface PublicProps {
    rows?: number;
    maxRows?: number;
    autoFocus?: AutoFocus;
    scrollDownOnGrow?: boolean;
    maxLength?: number;
    placeholder?: string;
    isCompact?: boolean; // ak je true, povoli vynimku, ze form control moze byt nizsi ako 55px
    hasLargeFont?: boolean;
    textareaRef?: (r: HTMLTextAreaElement) => void;
    disabled?: boolean;
    onHeightChange?(event, height: number);
}

interface OwnProps extends PublicProps {
    hasLabel: boolean;
}

interface State {
    height: number;
}

type Props = OwnProps & { input: WrappedFieldInputProps };

export class EnhancedTextarea extends React.Component<Props, State> {
    public static defaultProps: Partial<OwnProps> = {
        rows: 1,
    };

    private shadowTextareaElem: HTMLTextAreaElement;
    private textareaElem: HTMLTextAreaElement;
    private dispose: () => void;
    private rowsHeight: number;

    constructor(props) {
        super(props);

        this.state = {
            height: null,
        };

        this.rowsHeight = props.hasLargeFont
            ? 21 // 14px font * 1.5 em line-height
            : 27; // 18px font * 1.5 em line-height
    }

    public UNSAFE_componentWillMount() {
        this.setState({
            height: this.props.rows * this.rowsHeight,
        });
    }

    public componentDidMount() {
        this.syncHeightWithShadow();

        const listener = (event) => {
            this.syncHeightWithShadow(undefined, event);
        };

        window.addEventListener('resize', listener);

        this.dispose = () => {
            window.removeEventListener('resize', listener);
        };

        autoFocusElement(this.textareaElem, this.props.autoFocus, this.props.input.value);
    }

    public shouldComponentUpdate(np: Props, ns: State) {
        const p = this.props;
        const s = this.state;

        return (
            p.hasLargeFont !== np.hasLargeFont ||
            p.isCompact !== np.isCompact ||
            p.rows !== np.rows ||
            p.input.value !== np.input.value ||
            p.input.name !== np.input.name ||
            p.autoFocus !== np.autoFocus ||
            p.maxLength !== np.maxLength ||
            s.height !== ns.height ||
            p.placeholder !== np.placeholder ||
            p.disabled !== np.disabled
        );
        // NOTE: We are ignoring input.on* handlers
    }

    public UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.input.value !== this.props.input.value || nextProps.maxRows !== this.props.maxRows) {
            this.syncHeightWithShadow(nextProps.input.value, null, nextProps);
        }
    }

    public componentWillUnmount() {
        if (this.dispose) {
            this.dispose();
        }
    }

    public render() {
        const {
            rows,
            autoFocus,
            maxLength,
            placeholder,
            input,
            hasLabel,
            isCompact,
            hasLargeFont,
            disabled,
        } = this.props;

        if (isCompact && hasLabel) {
            throw new Error('Compact textarea cannot have label');
        }

        return (
            <div
                className={cx(
                    styles.EnhancedTextarea,
                    hasLargeFont && styles['EnhancedTextarea--hasLargeFont'],
                    isCompact && styles['EnhancedTextarea--isCompact'],
                    !hasLabel && !isCompact && styles['EnhancedTextarea--noLabel'],
                )}
            >
                <textarea
                    className={cx(
                        styles.EnhancedTextarea__shadow,
                        isCompact && styles['EnhancedTextarea__shadow--isCompact'],
                        !hasLabel && !isCompact && styles['EnhancedTextarea__shadow--noLabel'],
                    )}
                    ref={this.handleShadowTextareaElem}
                    tabIndex={-1}
                    rows={rows}
                    readOnly
                    value={input.value}
                    disabled={disabled}
                />
                <textarea
                    id={input.name}
                    className={cx(
                        styles.EnhancedTextarea__input,
                        isCompact && styles['EnhancedTextarea__input--isCompact'],
                        !hasLabel && !isCompact && styles['EnhancedTextarea__input--noLabel'],
                    )}
                    rows={rows}
                    autoFocus={!!autoFocus}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    style={{ height: this.state.height }}
                    {...input}
                    ref={this.handleTextareaRef}
                    onChange={this.handleOnChange}
                    disabled={disabled}
                />
            </div>
        );
    }

    private handleTextareaRef = (ref: HTMLTextAreaElement) => {
        this.textareaElem = ref;

        // propagate to parent
        const { textareaRef } = this.props;
        if (textareaRef) {
            textareaRef(ref);
        }
    };

    private handleShadowTextareaElem = (ref: HTMLTextAreaElement) => {
        this.shadowTextareaElem = ref;
    };

    private handleOnChange = (event) => {
        if (this.props.input.onChange) {
            // if redux-form registered onChange, change event will cause re-render
            // because this.props.input.value changes, so syncHeightWithShadow() will
            // be called from componentWillReceiveProps()
            this.props.input.onChange(event);
        } else {
            this.syncHeightWithShadow(event.target.value);
        }
    };

    private  scrolledAtBottom() {
        return window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
    }

    private syncHeightWithShadow(newValue?, event?, props = this.props) {
        if (!this.shadowTextareaElem) {
            // Not mounted yet
            return;
        }

        if (newValue !== undefined) {
            this.shadowTextareaElem.value = newValue;
        }

        let newHeight = this.shadowTextareaElem.scrollHeight;

        if (props.maxRows >= props.rows) {
            newHeight = Math.min(props.maxRows * this.rowsHeight, newHeight);
        }

        // At least 1 row height
        newHeight = Math.max(newHeight, this.rowsHeight);

        // scroll down/up on grow/shrink
        if (this.state.height && props.scrollDownOnGrow) {
            const heightDifference = (newHeight - this.state.height);
            if (heightDifference > 0) {
                // sroll after syncHeightWithShadow, with timeout 0
                window.setTimeout(() => {
                    window.scrollBy(0, heightDifference);
                    // window.scrollBy({ top: heightDifference, behavior: 'smooth' });
                }, 0);


            } else if (heightDifference < 0 && !this.scrolledAtBottom()) {
                // sroll before syncHeightWithShadow, without timeout 0
                window.scrollBy(0, heightDifference);
                // window.scrollBy({ top: heightDifference, behavior: 'smooth' });
            }
        }

        // const newTopOffset = this.shadowTextareaElem.getBoundingClientRect().top;

        if (this.state.height !== newHeight) {
            this.setState({
                height: newHeight,
            });
        }

        if (props.onHeightChange) {
            props.onHeightChange(event, newHeight);
        }
    }
}
