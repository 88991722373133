import { Location } from 'history';
import { STROLLER_COUNTRIES } from 'mk/autogenerated/strollersData';
import { wpLinkize } from 'mk/bazaar/common/filters';
import { TextLike } from 'mk/bazaar/common/filters/utils';
import { anyToDate } from 'mk/common/timeUtils';
import { Features } from 'mk2/apps/strollers/schemas';
import { strollersDetailUrl, strollersListUrl } from 'mk2/apps/strollers/urls';
import { Link } from 'mk2/components/Link';
import { TrackClick } from 'mk2/components/TrackClick';
import { url } from 'mk2/helpers/urls';
import { AppState } from 'mk2/reducers';
import { CountryEntity } from 'mk2/schemas';
import { getRoutingLocation } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';

// TBD: hardcoded 'sk-SK' for now

export const niceNumber = (value: number): string => {
    return value.toLocaleString('sk-SK');
};

export function niceSort(arr: string[]): string[] {
    const collator = new Intl.Collator('sk');
    return arr.sort(collator.compare);
}

export const terseDate = (dateThing: string | Date): string => {
    const date = anyToDate(dateThing);

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};

export function findCountryById(id: number): CountryEntity {
    return STROLLER_COUNTRIES.find((c) => c.id === id);
}

interface DetailLinkOwnProps {
    slug: string;
    eshopUrl?: string;
    visibleText: string;
}

interface DetailLinkStateProps {
    location: Location;
}

const DetailLinkNotConnected: React.FunctionComponent<DetailLinkOwnProps & DetailLinkStateProps> = ({ slug, eshopUrl, visibleText, location }) => {
    const detailUrl = eshopUrl ? eshopUrl : url(strollersDetailUrl, { slug });

    return (
        <TrackClick
            name="ast_stroller_detail_link_click"
            props={{
                slug,
                text: visibleText,
                source: `${location.pathname}${location.search}${location.hash}`,
                pathname: location.pathname,
                search: location.search,
                hash: location.hash,
            }}
        >
            <Link to={detailUrl}>
                {visibleText}
            </Link>
        </TrackClick>
    );
};

DetailLinkNotConnected.displayName = 'DetailLinkNotConnected';

export const DetailLink = connect<DetailLinkStateProps, null, DetailLinkOwnProps>((state: AppState, ownProps: DetailLinkOwnProps): DetailLinkStateProps => {
    return {
        location: getRoutingLocation(state),
    };
})(DetailLinkNotConnected);

export const BrandLink = ({ slug, visibleText }) => {
    return (
        <Link to={url(strollersListUrl, { slug })}>
            {visibleText}
        </Link>
    );
};

const LINK_RE = /(?:\s*([-\w]+)\s*:)?\s*(.+)/;

// link types:
// 'slug' -> stroller's detail
// 'brand:slug' -> brand's list

const lnkProcessor = (lnk, visibleText): React.ReactChild => {
    const match = LINK_RE.exec(lnk);
    if (!match) {
        return null;
    }
    if (match[1] && match[1].toLowerCase() === 'brand') {
        const slug = match[2];
        return (
            <BrandLink slug={slug} visibleText={visibleText} />
        );
    } else {
        return (
            <DetailLink slug={lnk} visibleText={visibleText} />
        );
    }
};

export const linkize = (text: TextLike) => wpLinkize(text, lnkProcessor);

const delnkProcessor = (lnk, visibleText): React.ReactChild => visibleText;

export const delinkize = (text: string) => wpLinkize(text, delnkProcessor);

const SIBLING_TYPES = {
    'siblings': true,
    'siblings (double)': true,
    'siblings (tandem)': true,
    'siblings (triple)': true,
};

export const isSiblingType = (features: Features): boolean => {
    return features.type.some((type) => type in SIBLING_TYPES);
};
