// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ARTICLE_COMMENTABILITY = "Kdo m\u016f\u017ee p\u0159id\u00e1vat koment\u00e1\u0159e?";
export const ARTICLE_TEXT = "Zn\u011bn\u00ed \u010dl\u00e1nku";
export const ARTICLE_TITLE = "Nadpis \u010dl\u00e1nku";
export const ARTICLE_VISIBILITY = "Pro koho je viditeln\u00fd tento \u010dl\u00e1nek?";
export const AUTHENTICATED_ONLY_POST = "Jen pro registrovan\u00e9";
export const COMMENTABLE_BY_AUTHENTICATED = "Registrovan\u00ed u\u017eivatel\u00e9";
export const COMMENTABLE_BY_NOBODY = "Nikdo nem\u016f\u017ee";
export const HIDE_REPLIES = "Nezobrazuj koment\u00e1\u0159e";
export const PHOTOBLOG_ARTICLE_FORM_AUTHOR = "Uve\u0159ejnit \u010dl\u00e1nek jako jin\u00fd u\u017eivatel";
export const PHOTOBLOG_ARTICLE_SCRIPTS = "Marketingov\u00e9 a jin\u00e9 k\u00f3dy (script / pixel / tag)";
export const PUBLIC_POST = "Pro v\u0161echny (i Google)";
export const PUBLISH_TIME_LEAVE_EMPTY_FOR_NOW = "\u010cas zve\u0159ejn\u011bn\u00ed - (vypl\u0148, pokud chce\u0161 datum v budoucnosti a ulo\u017e \u010dl\u00e1nek jako rozepsan\u00fd)";
export const SHOW_IN_CONTESTS = "Zobrazuj v \"Sout\u011b\u017ee\"";
export const YOU_DID_NOT_ENTER_ANY_ARTICLE_TEXT = "Nezadala jsi zn\u011bn\u00ed \u010dl\u00e1nku";
export const YOU_DID_NOT_ENTER_ANY_ARTICLE_TITLE = "Nezadala jsi titulek \u010dl\u00e1nku";
