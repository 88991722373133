import {
    adminclubArticlesAndVendors,
    adminclubAIPrompts,
    adminclubAIPromptEdit,
    adminclubConceptsHierarchyUrl,
    adminclubProductCatalogsUrl,
    adminclubProductCatalogImportStrollersUrl,
    adminclubProductCatalogImportUrl,
    adminclubReviewCategoryCreate,
    adminclubReviewCategoryEdit,
    adminclubSeoForumUrl,
    adminclubStrollersInsertPatterns,
    adminclubStrollersPatterns,
    adminclubStrollerEditPattern,
    adminclubTaggingForumUrl,
    adminclubXMLImportEditFeedUrl,
    adminclubXMLImportFeedsUrl,
    adminclubXMLImportItemsUrl,
    adminclubXMLImportNewFeedUrl,
    adminclubXMLImportStatsUrl,
} from 'mk2/apps/adminclub/urls';
import { Route } from 'mk2/containers/Application/Application';
import RedirectPage from 'mk2/pages/RedirectPage';

export const routes: Route[] = [{
    app: 'adminclub',
    exact: true,
    path: adminclubConceptsHierarchyUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/ConceptsHierarchy/ConceptsHierarchyPage' /* webpackChunkName: "adminclub.ConceptsHierarchy" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/ConceptsHierarchy/ConceptsHierarchyPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubTaggingForumUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/SeoForum/SeoForumPage' /* webpackChunkName: "adminclub.SeoForum" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/SeoForum/SeoForumPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubSeoForumUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/SeoForum/SeoForumPage' /* webpackChunkName: "adminclub.SeoForum" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/SeoForum/SeoForumPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubArticlesAndVendors,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/WikiArticlesWhereVendorsEntitledToReply/WikiArticlesWhereVendorsEntitledToReplyPage' /* webpackChunkName: "adminclub.WikiArticlesWhereVendorsEntitledToReply" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/WikiArticlesWhereVendorsEntitledToReply/WikiArticlesWhereVendorsEntitledToReplyPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubReviewCategoryCreate,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/ReviewCategoryCreateEdit/ReviewCategoryCreateEditPage' /* webpackChunkName: "adminclub.ReviewCategoryCreateEdit" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/ReviewCategoryCreateEdit/ReviewCategoryCreateEditPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubReviewCategoryEdit,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/ReviewCategoryCreateEdit/ReviewCategoryCreateEditPage' /* webpackChunkName: "adminclub.ReviewCategoryCreateEdit" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/ReviewCategoryCreateEdit/ReviewCategoryCreateEditPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubProductCatalogsUrl,
    page: new RedirectPage({
        to: adminclubProductCatalogImportStrollersUrl,
        permanent: true,
        authOnly: true,
    }),
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubProductCatalogImportUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/ProductCatalogImport/ProductCatalogImportPage' /* webpackChunkName: "adminclub.ProductCatalogImport" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/ProductCatalogImport/ProductCatalogImportPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubXMLImportItemsUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/XMLImportItems/XMLImportItemsPage' /* webpackChunkName: "adminclub.XMLImportItems" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/XMLImportItems/XMLImportItemsPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubStrollersPatterns,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/StrollerPatterns/StrollerPatternsPage' /* webpackChunkName: "adminclub.StrollerPatterns" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/StrollerPatterns/StrollerPatternsPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubStrollersInsertPatterns,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/StrollerInsertPattern/StrollerInsertPatternPage' /* webpackChunkName: "adminclub.StrollerInsertPattern" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/StrollerInsertPattern/StrollerInsertPatternPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubStrollerEditPattern,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/StrollerEditPattern/StrollerEditPatternPage' /* webpackChunkName: "adminclub.StrollerEditPattern" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/StrollerEditPattern/StrollerEditPatternPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubXMLImportFeedsUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/XMLImportFeeds/XMLImportFeedsPage' /* webpackChunkName: "adminclub.XMLImportFeeds" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/XMLImportFeeds/XMLImportFeedsPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubXMLImportStatsUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/XMLImportStats/XMLImportStatsPage' /* webpackChunkName: "adminclub.XMLImportStats" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/XMLImportStats/XMLImportStatsPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubXMLImportNewFeedUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/XMLImportNewFeed/XMLImportNewFeedPage' /* webpackChunkName: "adminclub.XMLImportNewFeed" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/XMLImportNewFeed/XMLImportNewFeedPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubXMLImportEditFeedUrl,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/XMLImportEditFeed/XMLImportEditFeedPage' /* webpackChunkName: "adminclub.XMLImportEditFeed" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/XMLImportEditFeed/XMLImportEditFeedPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubAIPromptEdit,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/AIPromptEdit/AIPromptEditPage' /* webpackChunkName: "adminclub.AIPromptEdit" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/AIPromptEdit/AIPromptEditPage' ],
}, {
    app: 'adminclub',
    exact: true,
    path: adminclubAIPrompts,
    getPage: () => {
        return import('mk2/apps/adminclub/containers/AIPromptAll/AIPromptAllPage' /* webpackChunkName: "adminclub.AIPromptAll" */);
    },
    modules: [ 'mk2/apps/adminclub/containers/AIPromptAll/AIPromptAllPage' ],
}];
