import {
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ARTICLE,
    ARTICLE_HAS_BEEN_DELETED,
    EXPLAIN_POST_DELETION,
} from 'mk/autogenerated/translations/Article.sagas.e848593502a009639329e9461e854ab9'
import { isAuthenticatedAs } from 'mk/bazaar/common/userUtils';
import {
    blogs_api_article_get_url,
    blogs_api_article_opened_url,
    blogs_api_group_post_delete_url,
} from 'mk/urls/functions';
import {
    articleDeleteApi,
    articleFetchApi,
    articleFetchTrigger,
    ArticleDeleteNormalizedResponse,
    ArticleDeleteTriggerAction,
    ArticleFetchNormalizedResponse,
    ArticleFetchTriggerAction,
    ArticlePageViewTriggerAction,
    ARTICLE_DELETE_TRIGGER,
    ARTICLE_FETCH_TRIGGER,
    ARTICLE_PAGE_VIEW,
} from 'mk2/apps/blogs/containers/Article/Article.actions';
import { revertReplacedTagsOfArticleScripts } from 'mk2/apps/blogs/containers/ArticleEdit/ArticleEdit.helpers';
import { commentsFetchApi, CommentsFetchNormalizedResponse } from 'mk2/apps/blogs/containers/Comments/Comments.actions';
import { blogsArticleUrl } from 'mk2/apps/blogs/urls';
import {
    handleXHRGetErrorSaga,
    handleXHRPostErrorSaga,
    normalizeError,
    XHRAction,
    XHRGetError,
    XHRPostError,
} from 'mk2/helpers/api';
import { mergeDenormalized } from 'mk2/helpers/denormalized';
import { showSuccessToast } from 'mk2/helpers/toasts';
import { makeAbsoluteUrl, removeEmpty, serializeGetParams, url, GetParams } from 'mk2/helpers/urls';
import { getLogger } from 'mk2/logger';
import { getRequestUser, AppState } from 'mk2/reducers';
import { BlogPostEntity, BlogPostSchema, CommentEntity, CommentSchema, PhotoEntity, PhotoSchema } from 'mk2/schemas';
import { getDenormalizedEntity, getRoutingLocation } from 'mk2/selectors';
import { redirectInPWASaga } from 'mk2/services/browserHistory';
import { normalize, schema } from 'normalizr';
import { parse } from 'query-string';
import React from 'react';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const logger = getLogger('blogs/Article.sagas');

interface ApiResponseArticleGet {
    body: {
        entities: ArticleFetchNormalizedResponse['entities'],
        result: {
            post: number;
            complaints: number[];
        };
        comments: CommentEntity[];
        suggestedArticles: BlogPostEntity[];
        suggestedArticlesHomepagePhotos: {[articleId: number]: PhotoEntity};
        hasHiddenText: boolean;
        canAddComments: boolean;
    };
}

function* fetchArticle({ username, articleSlug, articleOpened, query, accessKey, xhr }: ArticleFetchTriggerAction & XHRAction) {
    yield put(articleFetchApi.request(username, articleSlug, accessKey));

    try {
        const response: ApiResponseArticleGet = yield call(
            () => xhr.get(blogs_api_article_get_url(), removeEmpty({
                username,
                articleSlug,
                articleOpened,
                articleEdited: false,
                query: serializeGetParams(query as unknown as GetParams),
                key: accessKey,
            })),
        );

        // TODO: Denormalize remaining entities on server
        const normalizedResponse: ArticleFetchNormalizedResponse = normalize(response.body, {
            comments: [CommentSchema],
            suggestedArticles: [BlogPostSchema],
            suggestedArticlesHomepagePhotos: new schema.Values(PhotoSchema),
        });

        // TODO: Remove when all entities will be normalized on server
        const mergedResponse = mergeDenormalized(normalizedResponse, {
            entities: response.body.entities,
            result: response.body.result,
        });

        // in the case of client side rendering fix scripts here
        // in the case of server side rendering fix scripts in the js2/helpers/init.tsx (where the INITIAL_STATE is loaded)
        if (typeof window !== 'undefined') {
            const post = mergedResponse.entities[BlogPostSchema.key][mergedResponse.result.post];
            post.scripts = post.scripts
                ? revertReplacedTagsOfArticleScripts(post.scripts)
                : post.scripts;
        }

        // integrate loaded comments into Comments container
        const commentsNormalizedResponse: CommentsFetchNormalizedResponse = {
            result: {
                comments: normalizedResponse.result.comments,
                canAdd: response.body.canAddComments,
                hasHiddenText: response.body.hasHiddenText,
            },
            entities: normalizedResponse.entities,
        };

        yield put(commentsFetchApi.success({model: 'article', id: response.body.result.post}, commentsNormalizedResponse));

        yield put(articleFetchApi.success(username, articleSlug, accessKey, mergedResponse));
    } catch (error) {
        if (!accessKey && error.status === 403 && error.response.header['x-redirect-url']) {
            const redirUrl = error.response.header['x-redirect-url'];
            const qs = parse(redirUrl);
            const tryWithAccessKey = qs.key as string;
            if (tryWithAccessKey) {
                const location = yield select(getRoutingLocation);
                if (location.pathname === url(blogsArticleUrl, {username, articleSlug})) {
                    const goto = url(blogsArticleUrl, {username, articleSlug},  {key: tryWithAccessKey});
                    yield put(articleFetchApi.failure(username, articleSlug, accessKey, normalizeError(error)));
                    yield redirectInPWASaga(goto);
                    yield put(articleFetchTrigger(username, articleSlug, articleOpened, query, tryWithAccessKey));
                    return;
                }
            }
        }

        // else, general error handling
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(articleFetchApi.failure(username, articleSlug, accessKey, normalizeError(error)));
    }
}

interface ApiResponseArticleDelete {
    body: {
        message: string;
        post: BlogPostEntity;
    };
}
function* deleteArticle({ articleId, redirectUrl, xhr }: ArticleDeleteTriggerAction & XHRAction) {
    const requestUser = yield select(getRequestUser);
    const post: BlogPostEntity = yield select((state: AppState) => getDenormalizedEntity<BlogPostEntity>(state, BlogPostSchema, articleId));
    const isAuthor = isAuthenticatedAs(requestUser, post.author);
    let reason;

    if (isAuthor) {
        if (!window.confirm(ARE_YOU_SURE_YOU_WANT_TO_DELETE_ARTICLE)) {
            return;
        }
    } else {
        reason = window.prompt(EXPLAIN_POST_DELETION);
        if (reason === null) {
            return;
        }
    }

    yield put(articleDeleteApi.request(articleId));

    try {
        const response: ApiResponseArticleDelete = yield call(
            () => xhr.post(blogs_api_group_post_delete_url(articleId), isAuthor ? {} : { comment: reason }),
        );
        const normalizedResponse: ArticleDeleteNormalizedResponse = normalize({
            post: response.body.post,
        }, {
            post: BlogPostSchema,
        });
        yield put(articleDeleteApi.success(articleId, normalizedResponse));
        yield showSuccessToast(ARTICLE_HAS_BEEN_DELETED);
        if (redirectUrl) {
            yield redirectInPWASaga(makeAbsoluteUrl(redirectUrl));
        }
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(articleDeleteApi.failure(articleId, normalizeError(error)));
    }
}


function* pageView({ username, articleSlug, xhr }: ArticlePageViewTriggerAction & XHRAction) {
    // TODO merge pageview reporting of wiki/blog articles to an isolated new component
    try {
        // making call only in browsers, cases without defined window are disallowed
        if (typeof window !== 'undefined') {
            yield call(() => xhr.post(blogs_api_article_opened_url(), {
                username,
                articleSlug,
            }));
        }
    } catch (error) {
        logger.error(error);
    }
}

export default function* root() {
    yield all([
        takeEvery(ARTICLE_FETCH_TRIGGER, fetchArticle),
        takeEvery(ARTICLE_DELETE_TRIGGER, deleteArticle),
        takeLatest(ARTICLE_PAGE_VIEW, pageView),
    ]);
}
