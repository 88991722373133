import cx from 'classnames';
import { Location } from 'history';
import {
    CLOSE_STICKY_RECTANGLE,
} from 'mk/autogenerated/translations/AdSlotAnchorLeaderboard.1adf669c1fb74f9a9453089f6dcee513'
import { hasAds } from 'mk/bazaar/common/userUtils';
import { fromB64 } from 'mk2/apps/users/utils';
import { AdSlot } from 'mk2/containers/AdSlot/AdSlot';
import { getAdSlotAnchorLeaderboardState } from 'mk2/containers/AdSlotAnchorLeaderboard/AdSlotAnchorLeaderboard.selectors';
import { AnchorLeaderboardProps } from 'mk2/pages/Page';
import { AppState } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './AdSlotAnchorLeaderboard.mscss';

export const PREFIX_AD_SLOT_ANCHOR_LEADERBOARD = 'mk-stickyrectangle';

export const SHOW_DELAY = 2000;  // 2 seconds delay

export interface OwnProps extends AnchorLeaderboardProps {
    requestUser: UserEntity;
    location: Location;

    placement?: 'left' | 'right';
    format?: 'halfpage' | 'rectangle';
    shadow?: boolean;

    // signals that change to this prop might cause reflow (resize)
    // of bounding box. This component has to adapt by recomputing
    // its sticky class with updatePosition()
    reflowTrigger?();
}


interface StateProps {
    // render elements of AdSlotAnchorLeaderboard if adSlotRenderEnded is true
    adSlotRenderEnded: boolean;
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps;

const AdSlotAnchorLeaderboard: React.FC<Props> = ({
    adSlotRenderEnded,
    zone,
    targeting,
    location,
    requestUser,
}) => {
    const [isClosedByUser, setIsClosedByUser] = useState(false);
    const [timeoutExpired, setTimeoutExpired] = useState(false);

    useEffect(() => {
        setIsClosedByUser(false);
        setTimeoutExpired(false);

        const timer = setTimeout(() => {
            setTimeoutExpired(true);
        }, SHOW_DELAY);

        return () => clearTimeout(timer);
    }, [zone, location?.key]);

    const handleClose = () => {
        setIsClosedByUser(true);
    };

    if (isClosedByUser || !timeoutExpired || !hasAds(requestUser)) {
        return null;
    }

    return (
            <div className={cx(
                styles.AdSlotAnchorLeaderboard,
                adSlotRenderEnded && styles['AdSlotAnchorLeaderboard--rendered'],
            )}>
            {adSlotRenderEnded && (
                <div className={styles.AdSlotAnchorLeaderboard__tabs}>
                    <div
                        className={styles.AdSlotAnchorLeaderboard__tabs__tab}
                        onClick={handleClose}
                    >
                        {CLOSE_STICKY_RECTANGLE}
                    </div>
                </div>
            )}
            <div className={cx(
                styles.AdSlotAnchorLeaderboard__ad,
                adSlotRenderEnded && styles['AdSlotAnchorLeaderboard__ad--rendered'],
            )}>
                <AdSlot
                    zone={zone}
                    prefix={PREFIX_AD_SLOT_ANCHOR_LEADERBOARD}
                    targeting={targeting}
                    location={location}
                />
            </div>
        </div>
    );
};

function mapStateToProps(state: AppState, _ownProps: OwnProps): StateProps {
    const substate = getAdSlotAnchorLeaderboardState(state);
    return {
        adSlotRenderEnded: substate.adSlotRenderEnded,
    };
}

export default connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    null,
)(AdSlotAnchorLeaderboard);

interface PlaceholderProps {
}

export const AdSlotAnchorLeaderboardPlaceholder: React.FunctionComponent<PlaceholderProps> = ({}) => (
    <div className={styles.AdSlotAnchorLeaderboardPlaceholder} />
);

AdSlotAnchorLeaderboardPlaceholder.displayName = 'AdSlotAnchorLeaderboardPlaceholder';
