import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import { isMqMobile } from 'mk/common/responsive';
import rootSagas from 'mk2/apps/blogs/sagas';
import {
    getIgnoreOldBrowserWarningCookie,
    getLastKnownUsernameCookie,
    getNewGroupsCookie,
    getPregnancyNewsletterSubscribedCookie,
} from 'mk2/apps/users/utils';
import { revertReplacedScriptTagOfASafeMKScript } from 'mk2/client/init';
import { deprecatedParseUserAgent } from 'mk2/helpers/deprecated';
import { UserAgentParsed } from 'mk2/helpers/detects';
import { getLogger } from 'mk2/logger';
import { AppState } from 'mk2/reducers';
import { initialSettings } from 'mk2/reducers/request';
import { initJam } from 'mk2/services/jam';
import { initStore } from 'mk2/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';

declare const MK: any;

let store;
let history;

let alerted = false;

const logger = getLogger('initialize');

/**
 * NOTE: This is TEMPORARY solution for pages which are not react/redux only (e.g. photoblogs)
 * to render redux components - Album, Comments
 */
export function initialize(element: HTMLElement, Component: React.ComponentType<{ store: Store<AppState> }>, hydrate = false): Store<AppState> {

    if (process.env.NODE_ENV === 'development' && !alerted) {
        logger.warn(
            `Using TEMPORARY solution to initialize React/Redux on page which is rendered by Django. Please rewrite whole page to by React only. If you see this warning in %c2020%c something is wrong or I'm dead.`,
            'font-weight: bold', 'font-weight: normal',
        );
        alerted = true;
    }

    if (!history) {
        // Read basename from <base href="" />
        const base = document.querySelector('base');
        const baseHref = base ? base.getAttribute('href') : '/';

        history = createHistory({
            basename: baseHref.replace(/\/$/, ''),
        });
    }

    if (!store) {
        const initialState: Partial<AppState> = typeof window !== 'undefined' ? (window as any).INITIAL_STATE : {};
        // revert eventual replaced /<\/?script>/ within the INITIAL_STATE (eg. blog article's scripts field)
        revertReplacedScriptTagOfASafeMKScript(initialState);

        const mobile = isMqMobile();
        const ua: UserAgentParsed = deprecatedParseUserAgent(window.navigator.userAgent);

        // Detect mobile / desktop
        initialState.request = {
            baseUrl: '',
            user: MK.requestUser,
            permissions: MK.requestUserPermissions,
            settings: initialSettings,
            userAgent: ua,
            device: {
                ...(initialState.request?.device),
                mobile,
            },
            lastKnownUsername: getLastKnownUsernameCookie(),
            webpush: initialState.request?.webpush,
            pregnancyNewsletterSubscribed: getPregnancyNewsletterSubscribedCookie() || initialState.request?.pregnancyNewsletterSubscribed,
            newGroupsEnabled: getNewGroupsCookie(),
            hasIgnoredOldBrowserWarning: getIgnoreOldBrowserWarningCookie(),
        };

        // Set cookie, so that we can use it during server side rendering
        document.cookie = `ssr_device=${mobile ? '10' : '01'}; path=/`;

        store = initStore(history, rootSagas, initialState);

        initJam(store.dispatch);
    }

    ReactDOM[hydrate ? 'hydrate' : 'render'](
        <Provider store={store}>
            {/* provider needs the unbound version of i18next (see exports in i18next/ws/index.js) */}
            <ConnectedRouter history={history}>
                <Component store={store} />
            </ConnectedRouter>
        </Provider>,
        element,
    );

    return store;
}
