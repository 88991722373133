import {
    ALBUM_HAS_BEEN_DELETED,
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALBUM,
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_ARTICLE,
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHARE,
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHORT_MESSAGE,
    ARTICLE_HAS_BEEN_DELETED,
    BLOGS_IGNORE_POSTS_FROM_USER_username_link_GROWL,
    EXPLAIN_POST_DELETION,
    LIST_OF_IGNORED_USERS,
    SHARE_HAS_BEEN_DELETED,
    SHORT_MESSAGE_HAS_BEEN_DELETED,
    THE_POST_WAS_REMOVED_FROM_FEED,
    THE_POST_WAS_RETURNED_TO_FEED,
} from 'mk/autogenerated/translations/BlogsShared.sagas.910795dafa7d1554c53420ee0bfc9b96'
import { canManageBlogs, canManageForum, isAuthenticatedAs } from 'mk/bazaar/common/userUtils';
import {
    blogs_api_group_memberships_url,
    blogs_api_group_post_delete_url,
    blogs_api_ignore_user_url,
    blogs_api_set_displaying_at_feed_url,
    blogs_api_set_post_visibility_url,
    photoblog_all_ignored_url,
} from 'mk/urls/functions';
import {
    blogsDeletePostApi,
    blogsIgnoreUserApi,
    blogsLoadUserGroupMembershipsApi,
    blogsSetDisplayingAtFeedApi,
    blogsSetPostVisibilityApi,
    BlogsDeletePostNormalizedResponse,
    BlogsDeletePostTriggerAction,
    BlogsIgnoreUserTriggerAction,
    BlogsSetDisplayingAtFeedTriggerAction,
    BlogsSetPostVisibilityTriggerAction,
    BLOGS_DELETE_POST_TRIGGER,
    BLOGS_IGNORE_USER_TRIGGER,
    BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_TRIGGER,
    BLOGS_SET_DISPLAYING_AT_FEED_TRIGGER,
    BLOGS_SET_POST_VISIBILITY_TRIGGER,
} from 'mk2/apps/blogs/containers/BlogsShared/BlogsShared.actions';
import { ListOfGroupsLoadAction } from 'mk2/apps/groups/containers/ListOfGroups/ListOfGroups.actions';
import { Interpolate } from 'mk2/components/Interpolate';
import {
    handleXHRGetErrorSaga,
    handleXHRPostErrorSaga,
    normalizeError,
    XHRAction,
    XHRGetError,
    XHRPostError,
} from 'mk2/helpers/api';
import { showSuccessToast } from 'mk2/helpers/toasts';
import { makeAbsoluteUrl } from 'mk2/helpers/urls';
import { getLogger } from 'mk2/logger';
import { getRequestPermissions, getRequestUser, AppState } from 'mk2/reducers';
import {
    BlogPostEntity,
    BlogPostSchema,
    BlogPostType,
} from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import { redirectInPWASaga } from 'mk2/services/browserHistory';
import { normalize } from 'normalizr';
import React from 'react';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const logger = getLogger('blogs/BlogsShared.sagas');

interface ApiResponseBlogsSetPostVisibility {
    body: {
        post: BlogPostEntity;
    };
}

function* setPostVisibility({ postId, visibility, xhr }: BlogsSetPostVisibilityTriggerAction & XHRAction) {
    yield put(blogsSetPostVisibilityApi.request(postId, visibility));

    try {
        const response: ApiResponseBlogsSetPostVisibility = yield call(
            () => xhr.post(blogs_api_set_post_visibility_url(postId), {
                visibility,
            }),
        );

        const normalizedResponse = normalize({
            post: response.body.post,
        }, {
            post: BlogPostSchema,
        });

        yield put(blogsSetPostVisibilityApi.success(postId, visibility, normalizedResponse));

    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(blogsSetPostVisibilityApi.failure(postId, visibility, normalizeError(error)));
    }
}

interface ApiResponseBlogsIgnoreUser {
    body: {};
}

function* ignoreUser({ username, xhr }: BlogsIgnoreUserTriggerAction & XHRAction) {
    yield put(blogsIgnoreUserApi.request(username));

    try {
        const response: ApiResponseBlogsIgnoreUser = yield call(
            () => xhr.post(blogs_api_ignore_user_url(username), {}),
        );

        const normalizedResponse = normalize({}, {});

        yield put(blogsIgnoreUserApi.success(username, normalizedResponse));

        yield showSuccessToast(
            <Interpolate
                i18nKey={BLOGS_IGNORE_POSTS_FROM_USER_username_link_GROWL}
                useDangerouslySetInnerHTML
                username={username}
                link={(
                    <a href={photoblog_all_ignored_url()}>
                        {LIST_OF_IGNORED_USERS}
                    </a>
                )}
            />,
        );

    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(blogsIgnoreUserApi.failure(username, normalizeError(error)));
    }
}

interface ApiResponseBlogsSetDisplayingAtFeed {
    body: {
        post: BlogPostEntity;
    };
}

function* setDisplayingAtFeed({ postId, displaying, xhr }: BlogsSetDisplayingAtFeedTriggerAction & XHRAction) {
    yield put(blogsSetDisplayingAtFeedApi.request(postId, displaying));

    try {
        const response: ApiResponseBlogsSetDisplayingAtFeed = yield call(
            () => xhr.post(blogs_api_set_displaying_at_feed_url(postId), {
                displaying,
            }),
        );

        const normalizedResponse = normalize({
            post: response.body.post,
        }, {
            post: BlogPostSchema,
        });

        yield put(blogsSetDisplayingAtFeedApi.success(postId, displaying, normalizedResponse));

        yield showSuccessToast(
            displaying
                ? THE_POST_WAS_RETURNED_TO_FEED
                : THE_POST_WAS_REMOVED_FROM_FEED,
        );

    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(blogsSetDisplayingAtFeedApi.failure(postId, displaying, normalizeError(error)));
    }
}

interface ApiResponseBlogsDeletePost {
    body: {
        message: string;
        post: BlogPostEntity;
    };
}

function* deletePost({ postId, redirectUrl, xhr }: BlogsDeletePostTriggerAction & XHRAction) {
    const requestUser = yield select(getRequestUser);
    const requestUserPermissions: string[] = yield select(getRequestPermissions);
    const post: BlogPostEntity = yield select((state: AppState) => getDenormalizedEntity<BlogPostEntity>(state, BlogPostSchema, postId));
    const isGroupPost = post && 'group' in post && !!post.group;
    const isAdmin = (isGroupPost)
        ? canManageForum(requestUserPermissions) // can_manage_forum empower also in groups
        : canManageBlogs(requestUserPermissions);

    const isAuthor = isAuthenticatedAs(requestUser, post.author);
    let reason;

    const question = post.type === BlogPostType.ALBUM
        ? ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALBUM
        : post.type === BlogPostType.ARTICLE
            ? ARE_YOU_SURE_YOU_WANT_TO_DELETE_ARTICLE
            : post.type === BlogPostType.AUTOMATIC_MESSAGE
                ? ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHORT_MESSAGE
                : post.type === BlogPostType.SHARE
                    ? ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHARE
                    : ARE_YOU_SURE_YOU_WANT_TO_DELETE_SHORT_MESSAGE;

    if (isAuthor) {
        if (!window.confirm(question)) {
            return;
        }
    } else {

        do {
            reason = window.prompt(EXPLAIN_POST_DELETION);
        } while (reason !== null && reason.trim() === '' && isAdmin && !isGroupPost);

        if (reason === null) {
            return;
        }
    }

    yield put(blogsDeletePostApi.request(postId));

    try {
        const response: ApiResponseBlogsDeletePost = yield call(
            () => xhr.post(blogs_api_group_post_delete_url(postId), isAuthor ? {} : { comment: reason }),
        );

        const normalizedResponse: BlogsDeletePostNormalizedResponse = normalize({
            post: response.body.post,
        }, {
            post: BlogPostSchema,
        });

        yield put(blogsDeletePostApi.success(postId, normalizedResponse));

        yield showSuccessToast({
            [BlogPostType.ALBUM]: ALBUM_HAS_BEEN_DELETED,
            [BlogPostType.AUTOMATIC_MESSAGE]: SHORT_MESSAGE_HAS_BEEN_DELETED,
            [BlogPostType.SHARE]: SHARE_HAS_BEEN_DELETED,
            [BlogPostType.SHORT_MESSAGE]: SHORT_MESSAGE_HAS_BEEN_DELETED,
            [BlogPostType.ARTICLE]: ARTICLE_HAS_BEEN_DELETED,
        }[response.body.post.type]);

        if (redirectUrl) {
            yield redirectInPWASaga(makeAbsoluteUrl(redirectUrl));
        }

    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(blogsDeletePostApi.failure(postId, normalizeError(error)));
    }
}

interface LoadGroupMembershipsApiResponse {
    body: {
        memberships: number[],
    };
}

function* loadGroupMemberships({ xhr }: ListOfGroupsLoadAction & XHRAction) {
    yield put(blogsLoadUserGroupMembershipsApi.request());

    try {
        const response: LoadGroupMembershipsApiResponse = yield call(
            () => xhr.get(blogs_api_group_memberships_url({})),
        );

        yield put(blogsLoadUserGroupMembershipsApi.success({}, response.body.memberships));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(blogsLoadUserGroupMembershipsApi.failure(normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeEvery(BLOGS_SET_POST_VISIBILITY_TRIGGER, setPostVisibility),
        takeEvery(BLOGS_IGNORE_USER_TRIGGER, ignoreUser),
        takeEvery(BLOGS_SET_DISPLAYING_AT_FEED_TRIGGER, setDisplayingAtFeed),
        takeEvery(BLOGS_DELETE_POST_TRIGGER, deletePost),
        takeLatest(BLOGS_LOAD_USER_GROUP_MEMBERSHIPS_TRIGGER, loadGroupMemberships),
    ]);
}
